.memorii_recruit_evaluation_back {
  width: 1000px;
}

.memorii_recruit_evaluation {
  width: 100%;
}

.memorii_recruit_evaluation_list {
  width: calc(100% - 40px);
  background-color: white;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  box-shadow: 0px 0px 2px #d1d1d1;
  padding: 15px 20px;
}

.memorii_recruit_evaluation_list > div {
  display: flex;
}

.memorii_recruit_evaluation_list_schedule {
  width: 140px;
  border-right: 1px solid #EBEBEB;
  margin: 5px 20px 5px 0;
}

.memorii_recruit_evaluation_list_schedule > h4 {
  font-size: 13px;
  color: #222222;
  line-height: 20px;
}

.memorii_recruit_evaluation_list_schedule > h5 {
  font-size: 16px;
  color: #222222;
  line-height: 30px;
}

.memorii_recruit_evaluation_list_title {
  width: 200px;
  display: flex;
}

.memorii_recruit_evaluation_list_title > img {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  object-fit: cover;
  margin: 10px;
}

.memorii_recruit_evaluation_list_title > div {
  margin: 5px 0;
}

.memorii_recruit_evaluation_list_title > div > p {
  font-weight: bold;
  font-size: 13px;
  line-height: 20px;
  color: #787878;
}

.memorii_recruit_evaluation_list_title > div > h5 {
  font-size: 13px;
  line-height: 30px;
  color: #222222;
}

.memorii_recruit_evaluation_list_desc {
  display: flex;
}

.memorii_recruit_evaluation_list_desc > p {
  width: 70px;
  font-size: 14px;
  color: #222222;
  font-weight: bold;
  line-height: 60px;
}

.memorii_recruit_evaluation_list_desc > div {
  display: flex;
  margin-right: 20px;
}

.memorii_recruit_evaluation_list_desc > div > img {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  object-fit: cover;
  margin: 10px 0;
}

.memorii_recruit_evaluation_list_post {
  width: 200px;
  margin: 5px 0;
}

.memorii_recruit_evaluation_list_post > h4 {
  font-size: 13px;
  line-height: 20px;
  color: #787878;
}

.memorii_recruit_evaluation_list_post > h5 {
  font-size: 13px;
  line-height: 30px;
  color: #222222;
}

.memorii_recruit_evaluation_list_desc_icon {
  width: 20px;
  height: 20px;
  margin: 17.5px;
}


.memorii_recruit_evaluation_check {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.memorii_recruit_evaluation_check_resume {
  width: 430px;
  height: 620px;
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  overflow-y: scroll;
}

.memorii_recruit_evaluation_check_evaluation  > h3 {
  font-size: 16px;
  color: #AD0200;
  line-height: 40px;
}

.memorii_recruit_evaluation_check_evaluation {
  width: 100%;
  height: 620px;
  background-color: white;
  /* padding: 30px; */
  border-radius: 10px;
  overflow-y: scroll;
}

/* .memorii_recruit_evaluation_check_evaluation > h4 {
  line-height: 30px;
  font-size: 16px;
  color: #222222;
  margin-bottom: 10px;
} */

/* .memorii_recruit_evaluation_check_evaluation > div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.memorii_recruit_evaluation_check_evaluation > div > h5 {
  line-height: 30px;
  font-size: 14px;
  color: #373737;
}

.memorii_recruit_evaluation_check_evaluation > div > input {
  width: 250px;
  height: 40px;
  line-height: 40px;
  border: .5px solid #EBEBEB;
  text-indent: 1em;
}

.memorii_recruit_evaluation_check_evaluation > div > textarea {
  width: 250px;
  height: 80px;
  line-height: 40px;
  border: .5px solid #EBEBEB;
  text-indent: 1em;
} */


.memorii_recruit_evaluation_check {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.memorii_recruit_evaluation_check_evaluation_count {
  width: 20px;
  height: 20px;
  margin: 10px 5px;
}

.memorii_recruit_evaluation_check > input {
  width: calc(100% - 80px);
  line-height: 40px;
  height: 40px;
  border: 1px solid #EBEBEB;
  border-radius: 3px;
  text-indent: 1em;
}

.memorii_recruit_evaluation_check > textarea {
  width: calc(100% - 80px);
  line-height: 40px;
  height: 100px;
  border: 1px solid #EBEBEB;
  border-radius: 3px;
  text-indent: 1em;
  margin-bottom: 30px;
}

.memorii_recruit_evaluation_check > h4 {
  line-height: 40px;
  height: 40px;
  color: #222222;
  font-size: 15px;
}





.memorii_recruit_manage_contents_schedule {
  width: calc(100% - 50px);
  margin: 20px 0 0px;
  padding-bottom: 5px;
  border-bottom: 1px solid #EBEBEB;
  background-color: #FAFAFA;
  padding: 10px 25px;
  border-radius: 5px 5px 0 0;
  display: flex;
  justify-content: space-between;
}

.memorii_recruit_manage_contents_schedule_left {
  display: flex;
  width: calc(100% - 350px);
  border-right: .1px solid #EBEBEB;
}

.memorii_recruit_manage_contents_schedule_left > h4 {
  font-size: 14px;
  line-height: 30px;
  color: #222222;
  margin: 0 20px;
}

.memorii_recruit_manage_contents_schedule_left_icon {
  width: 15px;
  height: 15px;
  color: #222222;
  margin: 7.5px 0;
}

.memorii_recruit_manage_contents_schedule_right {
  display: flex;
  width: 350px;
}

.memorii_recruit_manage_contents_schedule_right > h4 {
  font-size: 14px;
  line-height: 30px;
  color: #222222;
  margin: 0 20px;
}

.memorii_recruit_manage_contents_calendar {
  width: calc(100% - 50px);
  height: 100%;
  border-right: 1px solid #EBEBEB;
  padding: 0 25px;
  display: flex;
  justify-content: space-between;
  background-color: white;
}

.memorii_recruit_manage_contents_calendar_left {
  width: calc(100% - 350px);
  border-right: .1px solid #EBEBEB;
}

.memorii_recruit_manage_contents_calendar_right {
  width: 350px;
}

.memorii_recruit_manage_contents_calendar_right > div > p {
  color: #222222;
  font-weight: bold;
  width: calc(100% - 20px);
  margin: 20px 10px;
  font-size: 14px;
  line-height: 30px;
}

.memorii_recruit_manage_contents_calendar_right > div > h4 {
  color: #222222;
  font-weight: bold;
  width: calc(100% - 34px);
  margin: 20px 10px;
  border-left: 4px solid #D14F60;
  box-shadow: 0px 0px 2px #d1d1d1;
  border-radius: 3px;
  padding: 15px 15px;
  font-size: 14px;
}

.memorii_recruit_manage_contents_calendar_left_title {
  margin: 20px 20px 20px 80px;
  width: calc(100% - 100px);
  display: flex;
  justify-content: space-between;
}

.memorii_recruit_manage_contents_calendar_left_title > h4 {
  width: calc(100% / 7);
  font-size: 14px;
  color: #222222;
  text-align: center;
}

.memorii_recruit_manage_contents_calendar_left_contents_scroll {
  height: 70vh;
  overflow-y: scroll;
}

.memorii_recruit_manage_contents_calendar_left_contents {
  width: 100%;
  display: flex;
}

.memorii_recruit_manage_contents_calendar_left_contents_left > h4 {
  font-weight: normal;
  line-height: 20px;
  width: 60px;
  font-size: 10px;
  text-align: right;
  margin-bottom: 36px;
}

.memorii_recruit_manage_contents_calendar_left_contents_right {
  width: calc(100% - 100px);
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
}

/* .memorii_recruit_manage_contents_calendar_left_contents_right > div {
  overflow-y: scroll;
  height: 50vh;
} */

.memorii_recruit_manage_contents_calendar_left_contents_right_hr {
  width: 1px;
  height: 100%;
  background-color: #EBEBEB;
}

.memorii_recruit_manage_contents_calendar_left_contents_right_box {
  position: relative;
  width: 100%;
  border-radius: 3px;
  padding: 0 10px;
}

.memorii_recruit_manage_contents_calendar_left_contents_right_box > div {
  position: absolute;
  width: calc(100% - 20px);
  /* background-color: #D14F60; */
  border: 1px solid white;
}

.memorii_recruit_calendar_1 {
  width: 100px;
  height: 100%;
  display: grid;
  grid-template-rows: 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px 14px;
}

.memorii_recruit_calendar_1_1 {
  font-size: 12px;
  border-top: 1.5px solid white;
  border-left: 1.5px solid white;
  border-right: 1.5px solid white;
  border-bottom: 1.5px solid white;
  padding-left: 5px;
  line-height: 20px;
  background-color: #D14F60;
  color: white;
  text-overflow: ellipsis;
}

.memorii_recruit_calendar_1_2 {
  grid-column: 2 / 4;
  grid-row: 1 / 2;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  border-bottom: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
  background-color: #D14F60;
}

