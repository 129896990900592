.works_landing_while {
  width: 1000px;
  background-color: #192651;
  padding: 80px calc((100% - 1000px) / 2);
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1000px) and (min-width: 800px) {
  .works_landing_while {
    width: 800px;
    padding: 80px calc((100% - 800px) / 2);
  }
}

@media (max-width: 800px) and (min-width: 500px) {
  .works_landing_while {
    width: 400px;
    padding: 50px calc((100% - 400px) / 2) 0px;
    display: block;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .works_landing_while {
    width: 90%;
    padding: 50px 5% 0px;
    display: block;
  }
}

.works_landing_while_left {
  display: flex;
  width: 450px;
  justify-content: space-between;
}

@media (max-width: 1000px) and (min-width: 500px) {
  .works_landing_while_left {
    width: 400px;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .works_landing_while_left {
    width: 100%;
  }
}

.works_landing_while_left > div {
  width: 200px;
  height: 250px;
  background-color: white;
}

@media (max-width: 1000px) and (min-width: 0px) {
  .works_landing_while_left > div {
    width: 180px;
  }  
}

@media (max-width: 500px) and (min-width: 0px) {
  .works_landing_while_left > div {
    width: 45%;
  }
}

.works_landing_while_right {
  width: 450px;
  height: 200px;
  margin: 25px 0;
}

@media (max-width: 1000px) and (min-width: 800px) {
  .works_landing_while_right {
    width: 380px;
  } 
}

@media (max-width: 800px) and (min-width: 500px) {
  .works_landing_while_right {
    width: 400px;
  } 
}

@media (max-width: 500px) and (min-width: 0px) {
  .works_landing_while_right {
    width: 100%;
  } 
}

.works_landing_while_right > h4 {
  font-size: 33px;
  color: white;
}

@media (max-width: 1000px) and (min-width: 800px) {
  .works_landing_while_right > h4 {
    font-size: 28px;
    color: white;
  }
}

@media (max-width: 800px) and (min-width: 500px) {
  .works_landing_while_right > h4 {
    text-align: center;
    font-size: 28px;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .works_landing_while_right > h4 {
    text-align: center;
    font-size: 26px;
  }
}

.works_landing_while_right > div {
  width: 450px;
  display: flex;
  margin: 30px 0 0;
}

@media (max-width: 1000px) and (min-width: 800px) {
  .works_landing_while_right > div {
    width: 400px;
  }
}

@media (max-width: 800px) and (min-width: 500px) {
  .works_landing_while_right > div {
    width: 400px;
    margin: 20px calc((100% - 400px) / 2) 0;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .works_landing_while_right > div {
    width: 100%;
    margin: 20px 0 0;
  }
}

.works_landing_while_right > div > button:nth-child(1) {
  border: none;
  width: 180px;
  background-color: #AD0200;
  border-radius: 3px;
  color: white;
  line-height: 40px;
  height: 40px;
  font-weight: bold;
  margin-right: 20px;
}

.works_landing_while_right > div > button:nth-child(2) {
  border: none;
  width: 180px;
  background-color: white;
  border-radius: 3px;
  color: #192651;
  line-height: 40px;
  height: 40px;
  font-weight: bold;
}
