
.input_title {
  font-size: 13px;
  color: #222222;
  text-align: left;
  margin-top: 20px;
  font-weight: bold;
}

.input_input {
  position: relative;
  width: 100%;
  height: 45px;
  margin: 10px 0;
}

.input_input > input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 45px;
  line-height: 45px;
  text-indent: 1em;
  border: 1px solid #EBEBEB;
  background-color: #fafafa;
  margin:  0;
}

.input_icon {
  position: absolute;
  width: 15px;
  height: 15px;
  color: #222222;
  right: 10px;
  top: 15px;
}

.input_error {
  width: 100%;
  padding: 0 0%;
  text-align: left;
  font-size: 12px;
  line-height: 20px;
  color: #AD0200;
}
