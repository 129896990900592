.works_service_info_score {
  width: 100%;
  display: flex;
  padding: 0 0 35px;
}

.works_service_info_score_icon {
  width: 25px;
  height: 25px;
  margin: 5px 0;
  color: #AD0200;
}

.works_service_info_score > h4 {
  line-height: 35px;
  font-size: 16px;
  color: #222222;
  padding-left: 15px;
}

.works_service_info_score > p {
  line-height: 35px;
  font-size: 16px;
  color: #222222;
  padding-left: 15px;
  font-weight: bold;
}

.works_service_info_post_title {
  width: 100%;
  line-height: 35px;
  font-size: 14px;
  color: #222222;
  margin-bottom: 10px;
}

.works_service_info_post {
  width:  100px;
  height: 100px;
  margin: 0 20px 0 0;
  object-fit: cover;
}