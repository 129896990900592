
.person {
  width: calc(100% - 60px);
  padding: 30px 30px 15px;
  background-color: white;
  margin-bottom: 30px;
  box-shadow: 0px 0px 4px #dfdfdf;
  border-radius: 5px;
}

@media (max-width: 700px) and (min-width: 0px) {
  .person {
    width: calc(100% - 40px);
    padding: 20px 20px 15px;
  }
}

.person > h5 {
  font-size: 18px;
  color: #222222;
  margin: 0 0 30px;
  text-align: center;
  background-color: #F5DDDD;
  padding: 5px 0;
  border-radius: 3px;
}

/* mbti */

.person_mbti {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 0 40px;
}

@media (max-width: 700px) and (min-width: 0px) {
  .person_mbti {
    flex-flow: column;
  }
}

.person_mbti > img {
  width: 160px;
  height: 160px;
  object-fit: contain;
}

@media (max-width: 700px) and (min-width: 0px) {
  .person_mbti > img {
    width: 100%;
    height: 160px;
    object-fit: contain;
  }
}

.person_mbti > div {
  width: calc(100% - 200px);
}

@media (max-width: 700px) and (min-width: 0px) {
  .person_mbti > div {
    width: 100%;
    margin: 10px 0 0;
  }
}

.person_mbti > div > h4 {
  color: #56A278;
  font-size: 14px;
  line-height: 30px;
}

.person_mbti > div > h5 {
  font-size: 16px;
  color: #222222;
  line-height: 30px;
}

.person_mbti > div > p {
  font-size: 14px;
  color: #787878;
  line-height: 30px;
}

/* box */

.person_bar {
  width: calc(100% - 40px);
  padding: 20px 20px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  margin: 0 0 20px 0;
  background-color: #e8f9f5;
}

@media (max-width: 700px) and (min-width: 0px) {
  .person_bar {
    padding: 10px 20px 20px;
    flex-flow: column;
  }
}

.person_bar > h4 {
  width: 130px;
  font-size: 14px;
  color: #222222;
  line-height: 45px;
}

.person_bar > div {
  width: calc(100% - 150px);
}

@media (max-width: 700px) and (min-width: 0px) {
  .person_bar > h4 {
    width: 100%;
  }

  .person_bar > div {
    width: 100%;
  }
}

.bar_status_title {
  display: flex;
  justify-content: space-between;
}

.bar_status_title > h5 {
  font-size: 12px;
  color: #222222;
  line-height: 25px;
}

.bar_status_bar {
  display: flex;
  justify-content: space-between;
}

.bar_status_bar > div {
  width: calc(100% / 5 - 15px);
  background-color: #b9e6db;
  border-radius: 100px;
  height: 10px;
  margin: 5px 0 0;
}
