.box {
  width: 900px;
  min-height: 100vh;
  padding: 100px calc((100% - 900px)/ 2) 50px;
  background-color: #F9FBFF;
  display: flex;
  justify-content: space-between;
}

.box_inline {
  width: 550px;
  margin: 0 auto;
}


.contents {
  width: calc(100% - 100px);
  padding: 50px 50px 20px;
  background-color: white;
  margin: 0px 0 30px;
  border-radius: 5px; 
  box-shadow: 0px 0px 2px #c7c7c7;
}

.title {
  width: calc(100% - 5px);
  background-color: #FAFAFA;
  border-left: 5px solid #AD0200;
  line-height: 40px;
  font-size: 15px;
  text-indent: 1em;
  margin-bottom: 30px;
}

.desc {
  font-size: 12px;
  line-height: 25px;
  color: #222222;
  margin-bottom: 10px;
}





.radio_btn {
  width: 400px;
  display: flex;
  justify-content: space-between;
}

.radio_btn > div {
  width: 180px;
  display: flex;
}

.radio_btn > div > input {
  line-height: 40px;
}

.radio_btn > div > p {
  line-height: 40px;
  font-size: 14px;
  margin-left: 10px;
}

.select {
  height: 40px;
  width: 100%;
  border: none;
  border: 1px solid #d1d1d1;
  font-size: 14px;
  line-height: 35px;
  text-indent: 1em;
  border-radius: 2px;
  color: #222222;
  background-color: white;
}


.save_box {
  width: 500px;
  margin: 50px auto 0;
  display: flex;
  justify-content: space-evenly;
}

.save_box_now {
  border: none;
  width: 150px;
  height: 45px;
  line-height: 45px;
  border: .5px solid #AD0200;
  border-radius: 3px;
  font-size: 15px;
  color: #AD0200;
  font-weight: bold;
  background-color: white;
}

.save_box_save {
  border: none;
  width: 150px;
  height: 45px;
  line-height: 45px; 
  background-color: #AD0200;
  border-radius: 3px;
  font-size: 15px;
  color: white;
  font-weight: bold;
  box-shadow: 0px 0px 2px #787878;
}











.memorii_recruit_template {
  width: 700px;
  padding: 150px calc((100vw - 700px) / 2);
  background-color: #F8F6F7;
}

.memorii_recruit_box {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  padding: 50px 0;
  margin-bottom: 50px;
}

.memorii_recruit_box > h5 {
  font-size: 20px;
  color: #222222;
  line-height: 40px;
  text-align: center;
  text-shadow: 0px 0px 2px 2px #f1f1f1;
  width: 100%;
  margin: 50px 0;
}

.memorii_recruit_box_input_area_form_space {
  width: 15px;
  height: 15px;
  margin: 12.5px 0px 12.5px 0;
}

.memorii_recruit_box > div > div > input[type=checkbox] {
  background-color: #AD0200;
  accent-color: #AD0200;
  width: 15px;
  height: 15px;
  margin: 12.5px 20px 12.5px 0;
}

.memorii_recruit_box > div > div > p {
  line-height: 40px;
  padding-left: 10px;
  color: #222222;
  font-size: 15px;
}

.memorii_recruit_box > div > div > p {
  font-size: 16px;
  color: #222222;
}

.memorii_recruit_box_other {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  padding: 50px 0 50px;
  margin-bottom: 50px;
}

.memorii_recruit_box_other > h5 {
  font-size: 20px;
  color: #222222;
  line-height: 40px;
  text-align: center;
  text-shadow: 0px 0px 2px 2px #f1f1f1;
  width: 100%;
  margin: 50px 0;
}

.memorii_recruit_box_other_box {
  width: 100%;
  margin: 0 auto;
}

.memorii_recruit_box_other_btn {
  margin: 50px auto 10px;
  width: 150px;
  height: 40px;
}

.memorii_recruit_box_other_btn > button {
  border: none;
  width: 150px;
  height: 40px;
  color: white;
  background-color: #AD0200;
  font-weight: bold;
  border-radius: 3px;
}

.memorii_recruit_box_table {
  width: 600px;
  border-collapse: collapse;
  font-size: 14px;
  line-height: 32px;
  margin: 0 auto;
}

.memorii_recruit_box_input_area_form {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0px auto;
}

.memorii_recruit_box_input_area_form_checkbox {
  width: 15px;
  height: 15px;
  margin: 12.5px 0 12.5px 0;
  background-color: #AD0200;
  accent-color: #AD0200;
  width: 15px;
  height: 15px;
  margin: 12.5px 0;
}

.memorii_recruit_box_input_area_name {
  width: 70px;
  font-size: 15px;
  color: #0F1213;
  line-height: 40px;
  font-weight: bold;
  margin-left: 10px;
}

.memorii_recruit_box_input_area_form_input {
  height: 40px;
  width: 400px;
  border: none;
  border: 1px solid #EBEBEB;
  font-size: 14px;
  line-height: 35px;
  text-indent: 1em;
  border-radius: 2px;
}



.memorii_recruit_box_top_input_area_form {
  width: 600px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.memorii_recruit_box_top_input_area_flex {
  width: 130px;
  font-size: 15px;
  color: #0F1213;
  line-height: 40px;
  font-weight: bold;
}

.memorii_recruit_box_top_input_area_form > input {
  height: 40px;
  width: 400px;
  border: none;
  border: 1px solid #EBEBEB;
  font-size: 14px;
  line-height: 35px;
  text-indent: 1em;
  border-radius: 2px;
}

.memorii_recruit_box_top_input_area_btn_yes {
  margin: 10px 30px 10px 0;
  width: 34px;
  height: 20px;
  background-color: #AD0200;
  color: white;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  border-radius: 2px;
}



.memorii_recruit_store_prev_resume {
  width: 300px;
  overflow: scroll;
}

.memorii_recruit_store_prev_resume > img {
  width: 300px;
  height: 400px;
}

.memorii_recruit_store_prev_resume > p {
  font-size: 14px;
  color: #0F1213;
}




