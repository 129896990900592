.works_landing_contact {
  display: flex;
  width: 1000px;
  padding: 80px calc((100% - 1000px) / 2);
  background-color: #192651;
  justify-content: space-between;
}

@media (max-width: 1000px) and (min-width: 800px) {
  .works_landing_contact {
    width: 800px;
    padding: 100px calc((100% - 800px) / 2);
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .works_landing_contact {
    width: 600px;
    padding: 100px calc((100% - 600px) / 2);
  }
}

@media (max-width: 600px) and (min-width: 0px) {
  .works_landing_contact {
    width: 100%;
    display: block;

  }
}

.works_landing_contact > div {
  width: 300px;
  height: 300px;
  background-color: white;
  border-radius: 5px;
}

@media (max-width: 1000px) and (min-width: 800px) {
  .works_landing_contact > div {
    width: 250px;
    height: 300px;
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .works_landing_contact > div {
    width: 280px;
  }
  
  .works_landing_contact > div:nth-child(3) {
    display: none;
  }
}

@media (max-width: 600px) and (min-width: 0px) {
  .works_landing_contact > div {
    padding: 10px 0 0;
    margin: 0 calc((100% - 300px) / 2) 30px;
  }
}

.works_landing_contact > div > div {
  width: 80px;
  height: 80px;
  margin: 30px calc((100% - 80px) / 2) 0;
}

.works_landing_contact > div > h4 {
  width: 100%;
  line-height: 30px;
  margin-top: 20px;
  border-radius: 5px;
  text-align: center;
  font-size: 18px;
  color: #222222;
}

.works_landing_contact > div > p {
  width: 100%;
  margin: 15px 0 0;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  color: #222222;
}

@media (max-width: 1000px) and (min-width: 800px) {
  .works_landing_contact > div > p {
    font-size: 12px;
  }
}

.works_landing_contact_icon {
  width: 30px;
  height: 30px;
  margin: 25px;
}

.works_landing_contact > div > button {
  border: none;
  width: 200px;
  margin: 20px calc((100% - 200px) / 2) 0;
  background-color: #AD0200;
  color: white;
  line-height: 40px;
  border-radius: 3px;
  font-weight: bold;
}



