/* エラー */

.box {
  width: calc(100% - 60px);
  padding: 20px 30px;
  background-color: white;
  margin: 30px 0 30px;
  border-radius: 5px;
  display: flex;
}

.box > h4 {
  font-size: 14px;
  color: #AD0200;
  padding-left: 10px;
  line-height: 40px;
}

.box_icon {
  color: #AD0200;
  width: 20px;
  height: 20px;
  margin: 10px 0;
}