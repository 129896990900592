.modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100000000000;
}

.box > div {
  position: relative;
}

.modal_black {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #222222;
  opacity: .3;
}

.modal_contents {
  position: absolute;
  width: 350px;
  padding: 20px 25px;
  height: 180px;
  background-color: white;
  border-radius: 5px;
  margin: calc((100vh - 180px) / 2) calc((100vw - 350px) / 2);
}

.modal_contents_title {
  display: flex;
  justify-content: space-between;
}

.modal_contents_title > h4 {
  font-size: 16px;
  color: #222222;
  line-height: 41px;
  font-weight: bold;
}

.modal_contents_title > button {
  border: none;
  width: 80px;
  height: 35px;
  line-height: 35px;
  background-color: #AD0200;
  border-radius: 3px;
  color: white;
  font-weight: bold;
}