
.box {
  width: calc(100% - 50px);
  display: flex;
  padding: 20px 0 10px;
  margin: 0 25px;
  border-bottom: 1px solid #EBEBEB;
  background-color: white;
  height: 45px;
}

.box_1 {
  display: flex;
  width: 220px;
  height: 40px;
  line-height: 40px;
}

.box_1 > img {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  object-fit: cover;
  border: .5px solid #EBEBEB;
}

.box_1_post > h2 {
  width: 140px;
  color: #222222;
  font-size: 14px;
  line-height: 25px;
  margin-left: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.box_1_post > div {
  display: flex;
  margin-left: 15px;
}

.box_1_post > div > div {
  width: 5px;
  height: 5px;
  background-color: #F1AE54;
  border-radius: 100px;
  margin: 5px 0;
}

.box_1_post > div > p {
  line-height: 15px;
  color: #222222;
  font-size: 12px;
  margin-left: 5px;
}

.box_2 {
  width: 100px;
  line-height: 45px;
  font-size: 14px;
}

.box_3 {
  width: 150px;
  display: flex;
  line-height: 45px;
  font-size: 14px;
}

.box_4 {
  width: 330px;
  font-size: 12px;
  padding: 2.5px 5px;
  line-height: 20px;
  margin-right: auto;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  background-color: #FAFAFA;
}

.box_5 {
  width: 150px;
  line-height: 45px;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.box_6 {
  width: 20px;
  height: 20px;
  margin: 0 30px 0 10px;
  line-height: 40px;
  padding: 10px 0;
  text-align: left;
}

.box_7 {
  width: 20px;
  height: 40px;
  margin: 0 5px;
}

