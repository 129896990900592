.works_landing_strong {
  width: 1000px;
  padding: 50px calc((100% - 1000px) / 2);
  background-color: #F9FBFF;
  background-image: url(./../../../image/landing/strong_back.png);
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 1000px) and (min-width: 800px) {
  .works_landing_strong {
    width: 800px;
    padding: 50px calc((100% - 800px) / 2);
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .works_landing_strong {
    width: 600px;
    padding: 50px calc((100% - 600px) / 2);
  }
}

@media (max-width: 600px) and (min-width: 500px) {
  .works_landing_strong {
    width: 500px;
    padding: 50px calc((100% - 500px) / 2);
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .works_landing_strong {
    width: 95%;
    padding: 50px 2.5%;
  }
}

.works_landing_strong > h5 {
  font-size: 35px;
  color: #222222;
  text-align: center;
}

@media (max-width: 800px) and (min-width: 0px) {
  .works_landing_strong > h5 {
    font-size: 25px;
    color: #222222;
    text-align: center;
  }
}

.works_landing_strong > div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 100px 0 0;
}

@media (max-width: 1000px) and (min-width: 800px) {
  .works_landing_strong > div {
    width: 800px;
  }
}

@media (max-width: 10000px) and (min-width: 800px) {
  .works_landing_strong > div:nth-child(4) {
    display: none;
  }
}

@media (max-width: 800px) and (min-width: 500px) {
  .works_landing_strong > div {
    width: 400px;
    margin: 100px calc((100% - 400px) / 2) 0;
    display: block;
  }

  .works_landing_strong > div:nth-child(3) {
    display: none;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .works_landing_strong > div {
    width: 100%;
    margin: 50px 0 0;
    display: block;
  }

  .works_landing_strong > div:nth-child(3) {
    display: none;
  }
}

.works_landing_strong > div > img {
  width: 500px;
  height: 300px;
  object-fit: cover;
  box-shadow: 0px 0px 3px #cccccc;
  border-radius: 3px;
}

@media (max-width: 1000px) and (min-width: 800px) {
  .works_landing_strong > div > img {
    width: 400px;
    height: 250px;
    margin: 25px 0;
  }
}

@media (max-width: 800px) and (min-width: 500px) {
  .works_landing_strong > div > img {
    width: 400px;
    height: 250px;
    margin: 25px 0;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .works_landing_strong > div > img {
    width: 100%;
    height: 230px;
    margin: 25px 0;
  }
}

.works_landing_strong > div > div {
  width: 400px;
}

@media (max-width: 1000px) and (min-width: 800px) {
  .works_landing_strong > div > div {
    width: 380px;
  }
}

@media (max-width: 800px) and (min-width: 0px) {
  .works_landing_strong > div > div {
    width: 100%;
  }
}

.works_landing_strong > div > div > h4 {
  font-size: 35px;
  text-align: left;
}

.works_landing_strong > div > div > h5 {
  font-size: 22px;
  text-align: left;
  color: #192651;
  line-height: 40px;
}

.works_landing_strong_icon {
  width: 20px;
  height: 20px;
  color: #AD0200;
  margin: 10px 0;
}

.works_landing_strong > div > div > div > div {
  display: flex;
  margin: 10px 0;
}

.works_landing_strong > div > div > div > p {
  font-size: 14px;
  text-align: left;
  color: #222222;
  line-height: 30px;
}

.works_landing_strong > div > div > div > div > p {
  font-size: 14px;
  text-align: left;
  color: #222222;
  line-height: 40px;
  font-weight: bold;
  padding-left: 10px;
}



