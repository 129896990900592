.list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 1350px;
  padding: 15px calc((100% - 1350px) / 2);
  background-color: #fbf6f5;
}

/* @media (max-width: 700px) and (min-width: 0px) {
  .list {
    width: 90%;
    padding: 50px 5% 100px;
    margin: 0 auto 0;
  }
} */

@media (max-width: 1350px) and (min-width: 1050px) {
  .list {
    width: 950px;
    padding: 15px calc((100vw - 950px) / 2);
  }
}

@media (max-width: 1050px) and (min-width: 700px) {
  .list {
    width: 650px;
    padding: 15px calc((100vw - 650px) / 2);
  }
}

@media (max-width: 700px) and (min-width: 500px) {
  .list {
    width: 330px;
    padding: 15px calc((100vw - 330px) / 2);
  }
}

@media (max-width: 500px) and (min-width: 0) {
  .list {
    width: 90%;
    padding: 15px 5%;
  }
}

.list_box {
  width: 300px;
  margin: 0px 5px 30px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 1.5px 1.5px #e4e4e4;
  border: .5px solid #cdcdcd;
}

.list_box > p {
  width: 90px;
  position: absolute;
  margin: -40px 0 0 185px;
  background-color: #D45463;
  color: white;
  padding: 5px 10px;
  border-radius: 100px;
  font-size: 12px;
  text-align: center;
  z-index: 1;
}

@media (max-width: 500px) and (min-width: 0px) {
  .list_box > p {
    margin: -40px 0 0 220px;
  }
}

.list_box > img {
  width: 100%;
  object-fit: cover;
  height: 180px;
  border-radius: 10px 10px 0 0;
}

@media (max-width: 700px) and (min-width: 0px) {
  .list_box {
    width: 100%;
  }

  .list_box > img {
    width: 100%;
  }
}

.list_box > div {
  display: flex;
  padding: 10px 20px 0;
}

.list_box > div > img {
  width: 38px;
  height: 38px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 20px;
}

.list_box > div > h4 {
  font-size: 14px;
  line-height: 38px;
  width: 240px;
  color: #222222;
  font-weight: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.list_box > h3 {
  padding: 5px 20px 15px;
  font-size: 14px;
  line-height: 30px;
  color: #222222;
  font-weight: normal;
}

.footer {
  width: 100vw;
  height: 400px;
  background-color: #AD0200;
  margin-top: 200px;
}

/* 求人 */

.list_none {
  font-size: 14px;
  color: #222222;
}


