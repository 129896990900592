/* 全体 */

.works_landing {
  position: fixed;
  right: 0;
  width: 1200px;
  height: 40px;
  display: flex;
  padding: 15px calc((100% - 1200px) / 2) 15px;
  justify-content: space-between;
  border-bottom: .5px solid #EBEBEB;
  background-color: white;
  z-index: 1;
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .works_landing {
    width: 1000px;
    padding: 15px calc((100% - 1000px) / 2) 15px;
  }
}

@media (max-width: 1000px) and (min-width: 800px) {
  .works_landing {
    width: 800px;
    padding: 15px calc((100% - 800px) / 2) 15px;
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .works_landing {
    width: 600px;
    padding: 15px calc((100% - 600px) / 2) 15px;
  }

  .works_landing_link > div:nth-child(1) {
    display: none;
  }
}

@media (max-width: 600px) and (min-width: 500px) {
  .works_landing {
    width: 500px;
    padding: 15px calc((100% - 500px) / 2) 15px;
  }

  .works_landing_link > div:nth-child(1) {
    display: none;
  }

  .works_landing_link > div:nth-child(2) {
    display: none;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .works_landing {
    width: 95%;
    padding: 15px 2.5% 15px;
  }

  .works_landing_link > div:nth-child(1) {
    display: none;
  }

  .works_landing_link > div:nth-child(2) {
    display: none;
  }

  .works_landing_link > div:nth-child(3) {
    display: none;
  }
}

/* 左側 */

.works_landing_nav {
  display: flex;
}

.works_landing_nav > h4 {
  font-size: 16px;
  color: #192651;
  line-height: 40px;
}

.works_landing_nav > img {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin: 10px 15px 10px 0;
  color: #192651;
}

/* 右側 */

.works_landing_link  {
  display: flex;
}

.works_landing_link > div {
  margin: 0 20px;
}

.works_landing_link > div {
  display: flex;
  justify-content: space-between;
}

.works_landing_link > div > p {
  line-height: 40px;
  font-size: 14px;
  font-weight: bold;
  margin-left: 15px;
}

.works_landing_link_img {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin: 10px 0;
}

.works_landing_link > button {
  border: none;
  width: 130px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  color: white;
  font-weight: bold;
  background-color: #AD0200;
  border-radius: 3px;
  margin-left: 20px;
}




