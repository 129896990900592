/* ホーム：下部：左側 */

.recruit {
  width: 63%;
}

.recruit_title {
  line-height: 35px;
  margin-bottom: 20px;
  color: #222222;
}

.recruit_box_title {
  display: flex;
}

.recruit_box_title > img {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 100px;
  border: .5px solid #EBEBEB;
}

.recruit_box_title > h4 {
  line-height: 45px;
  font-size: 14px;
  padding-left: 20px;
}

.recruit_box_data {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.recruit_box_data_before {
  width: 80%;
  display: flex;
  justify-content: space-between;
}

.recruit_box_data_before > div {
  width: 110px;
  height: 150px;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0px 0px 2px #cccccc;
} 

.recruit_box_data_1 > div > h4 {
  font-size: 12px;
  line-height: 30px;
  text-align: center;
}

.recruit_box_data_1 > div > h3 {
  font-size: 14px;
  line-height: 40px;
  text-align: center;
}

.recruit_box_data_between_icon {
  width: 20px;
  height: 20px;
  margin: calc(130px / 2) 0;
  color: #222222;
}

.recruit_box_data_1_icon {
  width: 28px;
  height: 28px;
  margin: 20px calc(50% - 14px) 0;
  color: #FCDC4A;
}

.recruit_box_data_1 {
  width: 110px;
  height: 150px;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0px 0px 2px #cccccc;
  border-top: 7px solid #FCDC4A;
}

.recruit_box_data_2 {
  width: 110px;
  height: 150px;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0px 0px 2px #cccccc;
  border-top: 7px solid #59C47C;
}

.recruit_box_data_3 {
  width: 110px;
  height: 150px;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0px 0px 2px #cccccc;
  border-top: 7px solid #D14F60;
}

.recruit_box_data_2_icon {
  width: 28px;
  height: 28px;
  margin: 20px calc(50% - 14px) 0;
  color: #59C47C;
}

.recruit_box_data_3_icon {
  width: 28px;
  height: 28px;
  margin: 20px calc(50% - 14px) 0;
  color: #D14F60;
}

.recruit_box_data_1 > h4,
.recruit_box_data_2 > h4,
.recruit_box_data_3 > h4 {
  font-size: 12px;
  line-height: 30px;
  text-align: center;
}

.recruit_box_data_1 > h3,
.recruit_box_data_2 > h3,
.recruit_box_data_3 > h3 {
  font-size: 14px;
  line-height: 40px;
  text-align: center;
}

/* 新規応募者 */

.new_title {
  color: #AD0200;
  line-height: 45px;
  font-size: 14px;
  margin-top: 30px;
}

.new {
  width: 100%;
  background-color: white;
  box-shadow: 0px 0px 2px #ababab;
  border-radius: 5px;
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.new_table_title {
  width: calc(100% - 50px);
  display: flex;
  margin: 20px 0 20px;
  padding-bottom: 5px;
  border-bottom: 1px solid #EBEBEB;
  background-color: #FAFAFA;
  padding: 10px 25px;
  border-radius: 5px 5px 0 0;
}

.new_table_title > h3 {
  color: #222222;
  width: 200px;
  font-size: 12px;
  line-height: 25px;
  padding: 2.5px 0px;
  border-radius: 3px;
  font-weight: 400;
}

.new_table_title > h4 {
  color: #222222;
  width: 110px;
  font-size: 12px;
  line-height: 25px;
  padding: 2.5px 0px;
  border-radius: 3px;
  font-weight: 400;
}

.new_table_title > h5 {
  color: #222222;
  width: 200px;
  font-size: 12px;
  line-height: 25px;
  padding: 2.5px 0px;
  border-radius: 3px;
  font-weight: 400;
}

.new_table_data {
  display: flex;
  margin: 20px 25px 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #EBEBEB;
}

.new_table_data > div {
  display: flex;
}

.new_table_data > div > img {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  border: .5px solid #EBEBEB;
}

.new_table_data > div > h3 {
  width: 140px;
  margin: 0 10px;
  color: #222222;
  font-size: 15px;
  line-height: 40px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.new_table_data > h4 {
  width: 100px;
  margin: 0 10px 0 0;
  color: #222222;
  font-size: 15px;
  line-height: 40px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.new_table_data > h5 {
  width: 140px;
  margin: 0 10px 0 0;
  color: #222222;
  font-size: 15px;
  line-height: 40px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.new_table_data_icon {
  width: 20px;
  margin: 10px 10px 10px auto;
}

