.text {
  width: calc(100% - 60px);
  padding: 30px 30px 15px;
  background-color: white;
  margin-bottom: 30px;
  box-shadow: 0px 0px 4px #dfdfdf;
  border-radius: 5px;
}

@media (max-width: 700px) and (min-width: 0px) {
  .text {
    width: calc(100% - 40px);
    padding: 20px 20px 0px;
  }
}

.text > h3 {
  font-size: 14px;
  color: #222222;
  text-align: center;
  background-color: #F5DDDD;
  padding: 5px 0;
  border-radius: 3px;
  margin-bottom: 20px;
}


/* score */

.score {
  display: flex;
  justify-content: space-between;
}

.score > div {
  width: 25%;
}

.score > div > img {
  width: 250px;
  height: 250px;
  object-fit: cover;
}

.score > div > h4 {
  text-align: center;
  font-size: 30px;
  color: #AD0200;
}


.score > div > h3 {
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 30px;
}

@media (max-width: 500px) and (min-width: 0px) {
  .score > div > h4 {
    font-size: 24px;
  }

  .score > div > h3 {
    font-size: 10px;
  }
}
