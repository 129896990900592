.schedule {
  margin: 5px 0 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: white;
  border: 1px solid #d1d1d1;
  border-radius: 3px;
}

.schedule_date {
  width: 100px;
  height: 40px;
  line-height: 40px;
  /* margin-right: 10px; */
  border: none;
  border-radius: 3px;
  padding: 0 10px;
}

.schedule_time {
  width: 70px;
  height: 40px;
  line-height: 40px;
  border: none;
  border-radius: 3px;
  /* padding: 0 10px; */
}

.schedule > p {
  font-size: 15px;
  line-height: 40px;
}
