
.store_setting {
  width: 150px;
  margin: 30px calc(50% - 75px);
  border: none;
  color: #666666;
  background-color: #F9FBFF;
}


/* box */

.box {
  padding: 70px calc((100% - 600px) / 2) 100px;
  width: 600px;
  min-height: 100vh;
  background-color: #F9FBFF;
}

/* title */

.store_title {
  text-align: center;
  margin: 50px 0 50px;
  color: #222222;
  font-size: 20px;
}

.store_list {
  width: 100%;
  margin-bottom: 20px;
}

.store_list_items {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 30px);
  padding: 20px 20px;
  background-color: white;
  border: 1px solid #EBEBEB;
  border-radius: 5px;
  justify-content: space-between;
  margin-bottom: 15px;
}

.store_list_items > input {
  line-height: 40px;
  accent-color: #AD0200;
}

.store_list_items > img {
  width: 80px;
  height: 40px;
  object-fit: cover;
  border-radius: 3px;
}

.store_list_items > h4 {
  width: calc(100% - 150px);
  line-height: 40px;
}



/* ボタン */

.button {
  width: 350px;
  display: flex;
  justify-content: space-between;
  margin: 70px auto 0;
  padding: 0 0 100px;
}

.button > button:nth-child(1),
.button > button:nth-child(2) {
  width: 150px;
  height: 45px;
  border-radius: 3px;
  border: 1px solid #EBEBEB;
  background-color: white;
}

.button > button:nth-child(3) {
  border: none;
  background-color: #AD0200;
  width: 150px;
  height: 45px;
  border-radius: 3px;
  color: white;
  font-weight: bold;
}


.save_box {
  width: 500px;
  margin: 50px auto 0;
  display: flex;
  justify-content: space-evenly;
}

.save_box_now {
  border: none;
  width: 150px;
  height: 45px;
  line-height: 45px;
  border: .5px solid #AD0200;
  border-radius: 3px;
  font-size: 15px;
  color: #AD0200;
  font-weight: bold;
  background-color: white;
}

.save_box_save {
  border: none;
  width: 150px;
  height: 45px;
  line-height: 45px; 
  background-color: #AD0200;
  border-radius: 3px;
  font-size: 15px;
  color: white;
  font-weight: bold;
  box-shadow: 0px 0px 2px #787878;
}

