.filter {
  height: 60px;
  width: 1350px;
  padding: 15px calc((100% - 1350px) / 2);
  border-bottom: .5px solid #EBEBEB;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1350px) and (min-width: 500px) {
  .filter {
    width: 90%;
    padding: 15px 5%;
  }
}

@media (max-width: 500px) and (min-width: 0) {
  .filter {
    width: calc(100vw - 30px);
    padding: 15px 15px;
  }
}


.filter_items {
  display: flex;
}

.filter_items > div {
  height: 38px;
  display: flex;
  border: 1px solid #cccccc;
  padding: 5px 15px;
  margin: 5px 10px 5px 0;
  border-radius: 10px;
}

.filter_items > div > h4 {
  font-size: 14px;
  color: #222222;
  font-weight: 400;
  line-height: 38px;
  padding-right: 10px;
}

.filter_items_icon {
  width: 15px;
  height: 15px;
  color: #535353;
  margin: 12px 0 12px 12px;
}

.filter_items_line {
  width: .5px;
  background-color: #EBEBEB;
  height: 50px;
  margin: 5px;
}

@media (max-width: 500px) and (min-width: 0) {
  .filter_items_line {
    display: none;
  }
}

.filter_hash {
  width: calc(100% - 350px);
  display: flex;
  overflow-x: scroll;
  height: 60px;
}

@media (max-width: 500px) and (min-width: 0) {
  .filter_hash {
    display: none;
  }
}

.filter_hash::-webkit-scrollbar{
  display: none;
}

.filter_hash > div {
  height: 39px;
  display: flex;
  border: .5px solid #cccccc;
  padding: 5px 15px;
  margin: 5px 10px;
  border-radius: 10px;
}

.filter_hash > div > h4 {
  font-size: 14px;
  color: #222222;
  font-weight: 400;
  line-height: 39px;
  padding-right: 10px;
  white-space: nowrap
}


/* area */

.salon {
  height: 300px;
  max-width: 250px;
  padding: 20px 0 0 20px;
  background-color: white;
  box-shadow: 0 10px 37px rgb(0 0 0/0.15);
  border: .5px solid #EBEBEB;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  z-index: 10;
}

.salon > div {
  width: 210px;
  border: .5px solid #EBEBEB;
  border-radius: 30px;
  padding: 5px 10px;
  font-size: 14px;
  margin: 0 10px 15px 0;
  text-align: center;
  display: flex;
}

.salon > div > img {
  width: 30px;
  height: 30px;
  padding: 10px 0 10px 10px;
}

.salon > div > h4 {
  line-height: 30px;
  padding: 10px 10px;
}

.area {
  max-width: 120px;
  padding: 20px;
  background-color: white;
  box-shadow: 0 10px 37px rgb(0 0 0/0.15);
  border: .5px solid #EBEBEB;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  height: 300px;
  overflow-y: scroll;
  z-index: 10;
}

.area > div {
  width: 98px;
  border: 10px solid #EBEBEB;
  border-radius: 30px;
  padding: 5px 10px;
  font-size: 14px;
  margin: 0  0 10px 0;
  display: flex;
}

.area > div > h4 {
  width: 98px;
  text-align: center;
  line-height: 35px;
}

.salary {
  max-width: 620px;
  padding: 20px;
  background-color: white;
  box-shadow: 0 10px 37px rgb(0 0 0/0.15);
  border: .5px solid #EBEBEB;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  z-index: 10;
}

.salary > div {
  width: 100px;
  border: .5px solid #EBEBEB;
  border-radius: 30px;
  padding: 10px 15px;
  font-size: 14px;
  margin: 0 10px 15px 0;
  text-align: center;
}



.phone_filter {
  width: 48px;
  height: 48px;
  border: 1px solid #EBEBEB;
  border-radius: 100px;
  margin: 5px 0;
}

@media (max-width: 10000px) and (min-width: 501px) {
  .phone_filter {
    display: none;
  }
}

.phone_filter_icon {
  width: 20px;
  height: 20px;
  color: #535353;
  padding: 14px;
}

@media (max-width: 500px) and (min-width: 0) {
  .filter_items > div:nth-child(3) {
    display: none;
  }
}


/* =========== */

.box {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100000000000;
}

.box > div {
  position: relative;
}

.box_black {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #222222;
  opacity: .3;
}

.box_error {
  position: absolute;
  width: 450px;
  height: 230px;
  padding: 25px;
  background-color: white;
  margin: calc((100vh - 280px) / 2) calc((100vw - 500px) / 2);
  border-radius: 5px;
}

@media (max-width: 500px) and (min-width: 0) {
  .box_error {
    position: absolute;
    width: calc(90vw - 30px);
    height: 200px;
    padding: 25px 15px;
    background-color: white;
    margin: calc((100vh - 280px) / 2) 5vw;
    border-radius: 5px;
  }
}

.box_error > h4 {
  width: 100%;
  font-size: 20px;
  color: #222222;
  line-height: 41px;
  font-weight: bold;
  text-align: center;
}

.box_error > p {
  width: 80%;
  margin: 15px auto 0;
  text-align: left;
  font-size: 14px;
  color: #222222;
  line-height: 35px;
}

.box_error > div {
  display: flex;
  justify-content: space-evenly;
  margin: 25px calc((100% - 240px) / 2) 0;
}

.box_error > a > button {
  width: 120px;
  height: 40px;
  line-height: 35px;
  border-radius: 3px;
  border: none;
  font-weight: bold;
  background-color: #222222;
  color: white;
  font-size: 16px;
  margin: 20px calc((100% - 120px) / 2);
  text-align: center;
}



