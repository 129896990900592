
.box {
  width: 900px;
  min-height: 100vh;
  padding: 100px calc((100% - 900px)/ 2) 50px;
  background-color: #F9FBFF;
  display: flex;
  justify-content: space-between;
}

.box_inline {
  width: 550px;
  margin: 0 auto;
}

.contents {
  width: calc(100% - 100px);
  padding: 50px 50px 20px;
  background-color: white;
  margin: 0px 0 30px;
  border-radius: 5px; 
  box-shadow: 0px 0px 2px #c7c7c7;
}

.title {
  width: calc(100% - 5px);
  background-color: #FAFAFA;
  border-left: 5px solid #AD0200;
  line-height: 40px;
  font-size: 15px;
  text-indent: 1em;
  margin-bottom: 30px;
}


.desc {
  font-size: 12px;
  line-height: 25px;
  color: #222222;
  margin-bottom: 10px;
}


.select {
  height: 40px;
  width: 100%;
  border: none;
  border: 1px solid #d1d1d1;
  font-size: 14px;
  line-height: 35px;
  text-indent: 1em;
  border-radius: 2px;
  color: #222222;
  background-color: white;
}



.save_box {
  width: 500px;
  margin: 50px auto 0;
  display: flex;
  justify-content: space-evenly;
}

.save_box_now {
  border: none;
  width: 150px;
  height: 45px;
  line-height: 45px;
  border: .5px solid #AD0200;
  border-radius: 3px;
  font-size: 15px;
  color: #AD0200;
  font-weight: bold;
  background-color: white;
}

.save_box_save {
  border: none;
  width: 150px;
  height: 45px;
  line-height: 45px; 
  background-color: #AD0200;
  border-radius: 3px;
  font-size: 15px;
  color: white;
  font-weight: bold;
  box-shadow: 0px 0px 2px #787878;
}

/* .works_edit_examination {
  width: 550px;
  margin: 0 auto;
}

.memorii_recruit_body_page_company_examination_save_box {
  width: 350px;
  margin: 50px auto 0;
  display: flex;
  justify-content: space-between;
} */
