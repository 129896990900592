.box {
  width: 550px;
  padding: 100px calc((100% - 550px) / 2) 50px;
  background-color: #F9FBFF;
}

.box_inline {
  width: 550px;
  min-height: 100vh;
  padding: 100px calc((100% - 550px)/ 2) 50px;
  background-color: #F9FBFF;
}


.contents {
  width: calc(100% - 100px);
  padding: 50px 50px 20px;
  background-color: white;
  margin: 0px 0 30px;
  border-radius: 5px; 
  box-shadow: 0px 0px 2px #c7c7c7;
}


.title {
  width: calc(100% - 5px);
  background-color: #FAFAFA;
  border-left: 5px solid #AD0200;
  line-height: 40px;
  font-size: 15px;
  text-indent: 1em;
  margin-bottom: 30px;
}

.save {
  border: none;
  width: 100px;
  height: 35px;
  line-height: 35px;
  background-color: #AD0200;
  color: white;
  border-radius: 3px;
  font-weight: bold;
  margin-left: calc(100% - 100px);
}

.member_title {
  width: 100%;
  border-bottom: .5px solid #EBEBEB;
  display: flex;
  justify-content: space-between;
}

.member_title > p {
  width: 60%;
  font-size: 12px;
  line-height: 35px;
}

.member_title > h4 {
  width: 40%;
  font-size: 12px;
  line-height: 35px;
  font-weight: normal;
}

.account {
  display: flex;
  justify-content: space-between;
  border-bottom: .5px solid #EBEBEB;
}

.account_user {
  width: 60%;
  display: flex;
}

.account_user > img {
  width: 45px;
  height: 45px;
  margin: 20px 0 5px;
  border-radius: 100px;
  object-fit: cover;
}

.account_user > h4 {
  margin: 20px 0 5px 10px;
  font-size: 12px;
  line-height: 45px;
  font-weight: normal;
}

.account_notification {
  width: 40%;
  margin: 0px 0 5px 0px;
  font-size: 12px;
  line-height: 45px;
  font-weight: normal;
  display: flex;
  justify-content: space-between;
}

.account_notification > p {
  width: calc(100% - 15px);
  line-height: 60px;
  margin: 10px 0 0 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #222222;
}

.account_authority {
  width: 30%;
  font-size: 12px;
  line-height: 35px;
  font-weight: normal;
  margin: 25px 0 10px 10px;
}

.account_alignment {
  width: 30%;
  font-size: 12px;
  line-height: 35px;
  font-weight: normal;
  margin: 25px 0 10px 10px;
  background-color: #5AC363;
  text-align: center;
  border-radius: 3px;
  color: white;
  font-weight: bold;
}

.account_icon {
  width: 10%;
  height: 15px;
  color: #787878;
  margin: 35px 0 20px 0px;
}

.invite {
  height: 25px;
  display: flex;
  margin-top: 10px;
  padding: 5px 0px;
  border-radius: 3px;
}

.invite_icons {
  width: 15px;
  height: 15px;
  color: #222222;
  margin: 5px 10px 5px 0;
}

.invite > p {
  font-size: 12px;
  line-height: 25px;
  color: #222222;
  font-weight: bold;
}


.invite_btn {
  width: 100%;
  border-bottom: .5px solid #EBEBEB;
  display: flex;
  justify-content: space-between;
}

.invite_btn > p {
  width: 60%;
  font-size: 12px;
  line-height: 35px;
}

.invite_btn > h4 {
  width: 40%;
  font-size: 12px;
  line-height: 35px;
  font-weight: normal;
}

.invite_list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.invite_list > p {
  color: #222222;
  margin-top: 10px;
  font-weight: normal;
  font-size: 12px;
  padding: 5px 15px;
  background-color: #fafafa;
  margin-right: 10px;
}

.works_setting_img {
  position: relative;
  width: 150px;
  height: 150px;
  background-color: #EBEBEB;
  margin: 0 0 20px;
  font-size: 55px;
  text-align: center;
  line-height: 100px;
  font-weight: bold;
  color: #222222;
  border: .5px solid #EBEBEB;
  object-fit: cover;
}

.works_setting_img > input {
  position: absolute;
  width: 150px;
  height: 150px;
  opacity: 0;
  z-index: 1;
  left: 0;
}

.make_image {
  position: relative;
  width: 150px;
  height: 150px;
  background-color: #EBEBEB;
  margin: 0 calc((100% - 151px) / 2) 20px;
  font-size: 55px;
  text-align: center;
  line-height: 100px;
  font-weight: bold;
  color: #222222;
  border: .5px solid #EBEBEB;
  object-fit: cover;
}

.make_image_pen {
  position: absolute;
  width: 15px;
  height: 15px;
  top: 10px;
  right: 10px;
  z-index: 1;
  color: #222222;
}

.make_image > input {
  position: absolute;
  width: 150px;
  height: 150px;
  opacity: 0;
  z-index: 1;
  left: 0;
}

.make_image_file {
  position: absolute;
  width: 150px;
  height: 150px;
  background-color: #EBEBEB;
  font-size: 55px;
  color: #222222;
  border: .5px solid #EBEBEB;
  object-fit: cover;
  left: 0;
}


.banner {
  top: 0;
  left: 0;
  position: fixed;
  width: 200px;
  height: 40px;
  margin: 20px calc((100% - 202px) / 2) 0;
  background-color: white;
  border-radius: 3px;
  z-index: 1000000;
  border: 1px solid #AD0200;
  text-align: center;
  line-height: 40px;
  font-size: 12px;
  color: #AD0200;
  font-weight: bold;
}


.desc_line {
  width: 100%;
  font-size: 12px;
  color: #222222;
  margin-top: 20px;
}

.desc_line > a {
  color: #AD0200;
}

.btn_line {
  width: 200px;
  height: 40px;
  line-height: 40px;
  color: white;
  background-color: #5AC363;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  font-weight: bold;
}
