/* header */

.header {
  width: 100vw;
  padding: 150px 0 200px;
  background-color: #192651;
  text-align: center;
}

.header > h1 {
  font-size: 50px;
  color: white;
}

.header > h2 {
  font-size: 20px;
  color: white;
}

/* contents */

.contents {
  width: 600px;
  padding: 70px 0 70px;
  border: 1px solid #222222;
  margin: -100px auto 100px;
  background-color: white;
}

@media (max-width: 950px) and (min-width: 501px) {
  .contents {
    width: 500px;
  }
}

@media (max-width: 500px) and (min-width: 0) {
  .contents {
    width: 90vw;
    border: none;
    margin: 50px auto 50px;
  }
}

.contents_progress {
  width: 310px;
  margin: 0 auto 50px;
}

.contents_progress > div {
  text-align: center;
  width: 310px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

.contents_progress > div > p {
  font-size: 13px;
  color: #373737;
  margin: 0 8px;
}

.contents_progress > img {
  width: 300px;
  height: 50px;
  object-fit: contain;
  margin: 0 auto;
}

.contents_box {
  width: 400px;
  margin: 0 auto;
  margin-bottom: 30px;
}

@media (max-width: 950px) and (min-width: 500px) {
  .contents_box {
    width: 350px;
  }
}

@media (max-width: 500px) and (min-width: 0) {
  .contents_box {
    width: 90vw;
  }
}

.contents_box > h3 {
  width: 100%;
  text-align: left;
  font-size: 15px;
  color: #222222;
}

.contents_box > input {
  width: 100%;
  border: none;
  border-bottom: .5px solid  #373737;
  height: 25px;
  margin-top: 10px;
  padding-bottom: 10px;
  line-height: 25px;
  font-size: 14px;
  color: #373737;
  border-radius: 0;
}

.contents_box > p {
  width: 100%;
  border: none;
  border-bottom: .5px solid  #373737;
  height: 25px;
  margin-top: 10px;
  padding: 10px 0 10px;
  line-height: 25px;
  font-size: 14px;
  color: #373737;
  border-radius: 0;
  background-color: #fafafa;
  text-indent: 1em;
}

.contents_box > input::placeholder {
  color: #cccccc;
}

.contents_box_area {
  width: 100%;
  border: none;
  margin-top: 10px;
  line-height: 25px;
  font-size: 14px;
  color: #373737;
  resize: none;
  border-radius: 0;
  margin-bottom: 7px;
  border-bottom: .5px solid  #373737;
  padding-bottom: 10px;
}

.contents_box_area::placeholder {
  color: #cccccc;
}

  
.contents_preview > div {
  width: 100%;
  height: .5px;
  background-color: #373737;
}

.contents_preview > p {
  white-space: pre-wrap;
  width: 100%;
  border: none;
  border-bottom: .5px solid  #373737;
  margin-top: 30px;
  padding-bottom: 10px;
  line-height: 25px;
  min-height: 25px;
  font-size: 18px;
  color: #373737;
  text-align: left;
  background-color: #fafafa;
}

.contents_btn {
  width: 200px;
  height: 50px;
  line-height: 50px;
  color: #222222;
  background-color: white;
  border: 1px solid #222222;
  margin: 0 auto;
}

.contents_btn:hover {
  border: none;
  background-color: #222222;
  color: white;
}

@media (max-width: 950px) and (min-width: 0px) {
  .contents_btn {
    width: 250px;
    margin-bottom: 30px;
  }
}

.contents_send_btn {
  display: flex;
  justify-content: space-between;
  width: 450px;
  margin: 0 auto;
}

@media (max-width: 950px) and (min-width: 0px) {
  .contents_send_btn {
    width: 300px;
    display: block;
  }  
}

.contents_alert {
  width: 550px;
  margin: -50px auto 70px;
  color: rgb(255, 61, 61);
  font-size: 13px;
  text-align: left;
}

.contents_third_btn {
  font-size: 13px;
  color: #444444;
  text-align: center;
  line-height: 30px;
  margin-top: 30px;
}





