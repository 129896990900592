.box {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10000000000000;
}

.box > div {
  position: relative;
}

.box_black {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: .2;
  z-index: 1;
}

.box_info {
  position: absolute;
  width: 410px;
  height: 100vh;
  background-color: white;
  z-index: 10000000000000;
  padding: 20px 20px 40px 30px;
  right: 0;
  overflow-y: scroll;
}


.progress {
  width: 100%;
  display: flex;
  border-bottom: .5px solid #EBEBEB;
  margin-bottom: 30px;
}

.progress > h4 {
  line-height: 40px;
  border-bottom: 3px solid #F8FBFF;
  margin-right: 30px;
  color: #CCCCCC;
  font-size: 14px;
}

/* .works_service_info_box {
  position: fixed;
  height: 87vh;
  overflow-y: scroll;
  top: 0;
  padding: 5vh 40px 8vh;
  right: 0;
  -webkit-box-shadow: 0 0 2px 0 #D1D1d1;
  box-shadow: 0 0 2px 0 #D1D1d1;
  background-color: white;
  z-index: 100000000000;
} */

/* .works_service_info {
  padding: 0 0 30px;
  width: 410px;
  height: calc((80vh - 2px) - 40px);
  background-color: white;
  max-height: 650px;
} */

.works_service_info_status {
  width: 100%;
  padding-bottom: 10px;
  margin: 0px auto 10px;
}






/* .memorii_recruit_manage_fied_flex_scroll_status_title {
  display: flex;
  justify-content: space-between;
  margin: 0px 0 20px;
}

.memorii_recruit_manage_fied_flex_scroll_status_title > h4 {
  font-size: 16px;
  color: #AD0200;
  line-height: 35px;
}

.memorii_recruit_manage_fied_flex_scroll_status_title > button {
  border: none;
  width: 80px;
  line-height: 30px;
  font-size: 14px;
  color: white;
  background-color: #AD0200;
  padding: 2.5px 0;
  font-weight: bold;
}


.memorii_recruit_manage_fied_flex_scroll_status_progress {
  display: flex;
  margin: 10px 0 30px;
}

.memorii_recruit_manage_fied_flex_scroll_status_progress > h5 {
  width: 100px;
  font-size: 14px;
  line-height: 40px;
}

.memorii_recruit_manage_fied_flex_scroll_status_progress > select {
  width: calc(100% - 100px);
  height: 40px;
  line-height: 40px;
  border: .5px solid #EBEBEB;
} */

