/* ナビゲーション */

.nav {
  position: fixed;
  width: 1200px;
  display: flex;
  justify-content: space-between;
  height: 70px;
  padding: 10px calc((100% - 1200px) / 2);
  background-color: rgb(255, 252, 252);
  z-index: 10000000;
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .nav {
    width: 1000px;
    padding: 0 calc((100% - 1000px) / 2);
  }
}

@media (max-width: 1000px) and (min-width: 700px) {
  .nav {
    width: 700px;
    padding: 0 calc((100% - 700px) / 2);
  }
}

@media (max-width: 700px) and (min-width: 0px) {
  .nav {
    width: 90%;
    padding: 0 5%;
  }
}

.nav_logo {
  width: 180px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 500px) and (min-width: 0px) {
  .nav_logo {
    width: 160px;
    display: flex;
    justify-content: space-between;
  }
}

.nav_logo > img {
  width: 30px;
  height: 30px;
  margin: 20px 0;
}

.nav_logo > h4 {
  line-height: 40px;
  margin: 15px 0;
  color: #222222;
}

.nav_link {
  width: 120px;
  display: flex;
  justify-content: space-between;
}

.nav_link > h5 {
  line-height: 40px;
  margin: 15px 0;
  color: #222222;
}

@media (max-width: 800px) and (min-width: 500px) {
  .nav_link {
    width: 120px;
  }

  .nav_link > h5:nth-child(1) {
    display: none;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .nav_link {
    width: 120px;
  }

  .nav_link > h5:nth-child(1),
  .nav_link > h5:nth-child(2) {
    display: none;
  }
}

.nav_link > button {
  border: none;
  width: 120px;
  height: 40px;
  color: white;
  background-color: #AD0200;
  margin: 15px 0;
  font-weight: bold;
  border-radius: 3px;
}
