/* 全体 */

.works_service {
  width: 1200px;
  min-height: 100vh;
  padding: 100px calc((100% - 1200px) / 2 - 35px) 0 calc((100% - 1200px) / 2 + 35px);
  background-color: #F9FBFF;
}

@media (max-width: 1400px) and (min-width: 1000px) {
  .works_service {
    width: 1000px;
    padding: 100px calc((100% - 1000px) / 2 - 35px) 0 calc((100% - 1000px) / 2 + 35px);
  }
}


.works_banner {
  top: 0;
  left: 0;
  position: fixed;
  width: 200px;
  height: 40px;
  margin: 20px calc((100% - 202px) / 2) 0;
  background-color: white;
  border-radius: 3px;
  z-index: 1000000;
  border: 1px solid #AD0200;
  text-align: center;
  line-height: 40px;
  font-size: 12px;
  color: #AD0200;
  font-weight: bold;
}