/* wide */

.works_nav_mobile {
  width: 95%;
  top: 50px;
  padding: 10px 2.5% 30px;
  z-index: 50;
  background-color: #F9FBFF;
  display: flex;
  justify-content: space-between;
}

/* ナビゲーション */

.works_nav_mobile_item {
  display: flex;
  height: 40px;
  width: 30%;
  border-radius: 3px;
  border: .5px solid #EBEBEB;
  text-align: center;
}

.works_nav_mobile_item_icon {
  width: 23px;
  height: 23px;
  padding: 8.5px;
  color: #CCCCCC;
}

.works_nav_mobile_item > p {
  text-align: center;
  width: 100%;
  line-height: 40px;
  font-size: 14px;
  color: #CCCCCC;
}
