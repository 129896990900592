/* ホーム：下部：右側 */

.task {
  width: 35%;
}

.task > div {
  border: .5px solid #EBEBEB;
  border-radius: 5px;
}

.task_title_top {
  font-size: 16px;
  line-height: 30px;
  color: #222222;
  margin-bottom: 10px;
}

.task_title {
  width: calc(100% - 30px);
  padding: 10px 15px;
  background-color: #FAFAFA;
  display: flex;
  justify-content: space-between;
  border-radius: 5px 5px 0 0;
}

.task_title > h4 {
  font-size: 14px;
  line-height: 30px;
}

.task_data {
  width: calc(100% - 30px);
  padding: 10px 15px;
  background-color: white;
  border-top: 1px solid #EBEBEB;
  display: flex;
  justify-content: space-between;
}

.task_data > h4 {
  font-size: 14px;
  line-height: 30px;
}

.task_data_red {
  width: calc(100% - 30px);
  padding: 10px 15px;
  background-color: white;
  border-top: 1px solid #EBEBEB;
  display: flex;
  justify-content: space-between;
}

.task_data_red > h4 {
  font-size: 14px;
  line-height: 30px;
  color: #AD0200;
}