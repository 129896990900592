.modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100000000000;
}

.box > div {
  position: relative;
}

.modal_black {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #222222;
  opacity: .3;
}

.modal_contents {
  position: absolute;
  width: 450px;
  height: 160px;
  padding: 25px;
  background-color: white;
  margin: calc((100vh - 160px) / 2) calc((100vw - 500px) / 2);
  border-radius: 5px;
}

.modal_contents > h4 {
  width: 100%;
  font-size: 16px;
  color: #0F1213;
  line-height: 41px;
  font-weight: bold;
  text-align: center;
}

.modal_contents > p {
  width: 350px;
  margin: 20px auto 0;
  text-align: center;
  font-size: 14px;
  color: #0F1213;
  line-height: 35px;
}

.modal_contents > div {
  display: flex;
  margin: 15px calc((100% - 240px) / 2) 0;
}

.modal_contents > div > button {
  border: none;
  width: 100px;
  height: 35px;
  line-height: 35px;
  border-radius: 3px;
  border: 1px solid #AD0200;
  margin: 0 10px;
  font-weight: bold;
}