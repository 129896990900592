
.works_landhing_what {
  width: 1000px;
  background-color: #F9FBFF;
  padding: 50px calc((100% - 1000px) / 2);
}

@media (max-width: 1000px) and (min-width: 700px) {
  .works_landhing_what {
    width: 664px;
    padding: 50px calc((100% - 664px) / 2);
  }
}

@media (max-width: 700px) and (min-width: 0px) {
  .works_landhing_what {
    width: 95%;
    padding: 50px 2.5%;
  }
}

.works_landhing_what > h4 {
  text-align: center;
  font-size: 24px;
  line-height: 40px;
  color: #222222;
}

@media (max-width: 500px) and (min-width: 0px) {
  .works_landhing_what > h4 {
    font-size: 20px;
  }
}

.works_landhing_what > p {
  width: 100%;
  text-align: center;
  font-size: 14px;
  line-height: 35px;
  margin: 30px 0 0;
  color: #222222;
}

@media (max-width: 700px) and (min-width: 500px) {
  .works_landhing_what > p > br {
    display: none;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .works_landhing_what > p > br {
    display: none;
  }

  .works_landhing_what > p {
    font-size: 14px;
    line-height: 25px;
    margin: 30px 0 0;
  }
}


.works_landhing_what > div {
  width: 100%;
  margin: 50px 0 0;
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 700px) and (min-width: 0px) {
  .works_landhing_what > div {
    width: 334px;
    margin: 50px calc((100% - 334px) / 2) 0;
    display: flex;
    flex-wrap: wrap;
  }
}

.works_landhing_what > div > div {
  height: 340px;
  width: 331px;
  background-color: white;
  border: .5px solid #EBEBEB;
}

.works_landhing_what_bar {
  width: 80px;
  height: 5px;
}

.works_landhing_what_title {
  width: 95%;
  margin: 10px 2.5% 0;
  display: flex;
}

.works_landhing_what_icon {
  width: 30px;
  height: 30px;
}

.works_landhing_what_title > h4 {
  font-size: 14px;
  line-height: 30px;
  padding-left: 10px;
  color: #222222;
}

.works_landhing_what > div > div > p {
  width: 90%;
  margin: 15px 5% 0;
  font-size: 14px;
  line-height: 25px;
  color: #222222;
}

.works_landhing_what > div > div > img {
  width: 90%;
  margin: 15px 5% 0;
  object-fit: cover;
  height: 200px;
}



