/* box */

.box {
  padding: 70px calc((100% - 600px) / 2) 0;
  width: 600px;
  min-height: 100vh;
  background-color: #F9FBFF;
}

/* title */

.store_title {
  text-align: center;
  margin: 50px 0 30px;
  color: #222222;
  font-size: 20px;
}

.store_desc {
  text-align: left;
  margin: 10px 0 0px;
  color: #222222;
  font-size: 14px;
}

.store_attention {
  text-align: left;
  margin: 10px 0 40px;
  color: #222222;
  font-size: 12px;
}

.store_min_title {
  width: 100%;
  display: flex;
  margin-bottom: 30px;
}

.store_min_title > img {
  width: 30px;
  height: 30px;
  object-fit: contain;
  margin: 5px 30px 5px 0;
}

.store_min_title > h5 {
  font-size: 14px;
  line-height: 40px;
}

.store_items {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.store_items > div {
  display: flex;
  width: calc(45% - 40px);
  background-color: white;
  border-radius: 5px;
  height: 40px;
  padding: 15px 20px;
  justify-content: space-between;
}

.store_items > div > img {
  width: 30px;
  height: 30px;
  object-fit: contain;
  margin: 5px 0px;
}

.store_items > div > p {
  font-size: 14px;
  line-height: 40px;
  width: 60%;
  font-weight: bold;
}

.store_items_icons {
  width: 20px;
  height: 20px;
  margin: 10px 0px;
  color: #FFFFFF;
}

.store_items_icons_red {
  width: 20px;
  height: 20px;
  margin: 10px 0px;
  color: #222222;
}

.store_items_icons_setting {
  color: #222222;
  width: 30px;
  height: 30px;
  margin: 5px 10px 5px 0;
}

/* .recruit_title {
  text-align: left;
  margin: 70px 0 0px;
  color: #222222;
  font-size: 20px;
} */

/* 遷移 */

.contents {
  width: 640px;
  height: 80px;
  padding: 30px 30px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  margin: 20px auto 0;
  box-shadow: 0px 0px 2px #ababab;
}

.contents > img {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.contents_contents {
  width: 350px;
  margin: 10px 0;
}

.contents_contents > h5 {
  font-size: 16px;
  color: #273B4A;
  font-weight: bold;
  line-height: 30px;
}

.contents_contents > p {
  font-size: 14px;
  color: #222222;
  font-weight: bold;
  line-height: 30px;
}

.contents_arrow {
  width: 80px;
  display: flex;
  color: #AD0200;
  margin: 25px 0;
}

.contents_arrow > h5 {
  width: 50px;
  font-size: 16px;
  line-height: 30px;
  color: #AD0200;
}

.contents_arrow_icons {
  width: 20px;
  height: 20px;
  margin: 5px;
}

/* ボタン */

.button {
  width: 350px;
  display: flex;
  justify-content: space-between;
  margin: 70px auto 0;
  padding: 0 0 100px;
}

.button > button:nth-child(1),
.button > button:nth-child(2) {
  width: 150px;
  height: 45px;
  border-radius: 3px;
  border: 1px solid #EBEBEB;
  background-color: white;
}

.button > button:nth-child(3) {
  border: none;
  background-color: #AD0200;
  width: 150px;
  height: 45px;
  border-radius: 3px;
  color: white;
  font-weight: bold;
}

/* preview */

.prev {
  position: sticky;
  top: 100px;
  width: 300px;
  height: 610px;
}

.prev > p {
  width: 100%;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  color: #222222;
  margin-bottom: 5px;
}

.prev > div {
  position: relative;
  width: 300px;
  height: 610px;
}

.prev_contents {
  z-index: 2;
  position: absolute;
  top: 9px;
  left: 10px;
  width: 280px;
  height: 592px;
  border-radius: 33px;
  background-color: #fafafa;
  overflow: scroll;
}


.prev_contents_nav {
  height: 30px;
  background-color: #AD0200;
  display: flex;
  padding: 25px 10px 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.prev_contents_nav > img {
  width: 20px;
  height: 20px;
  margin: 10px 10px 10px 0;
}

.prev_contents_nav > h4 {
  font-size: 13px;
  color: white;
  line-height: 40px;
}


.prev_contents_name {
  display: flex;
  width: calc(100% - 20px);
  margin: 15px 10px 0px;
  justify-content: space-between;
}

.prev_contents_name > div {
  display: flex;
}

.prev_contents_name > div > img {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 100px;
  margin-right: 10px;
}

.prev_contents_name > div > h4 {
  width: 100px;
  font-size: 12px;
  color: #222222;
  line-height: 30px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.prev_contents_name > button {
  border: none;
  padding: 0 10px;
  height: 30px;
  background-color: #AD0200;
  border-radius: 3px;
  color: white;
  font-size: 10px;
  font-weight: bold;
}

.prev_contents_title {
  line-height: 35px;
  color: #222222;
  margin: 10px 10px 0;
  font-size: 14px;
  line-height: 25px;
}

.prev_contents_store_title {
  line-height: 35px;
  color: #787878;
  margin: 10px 10px 0;
  font-size: 14px;
  line-height: 25px;
}

.prev_contents_image {
  width: calc(100% - 20px);
  margin: 10px 10px 0;
  height: 100px;
  background-color: #cccccc;
  text-align: center;
  font-size: 13px;
  color: #222222;
  line-height: 100px;
}


.prev_contents_images {
  height: 60px;
  object-fit: cover;
  width: calc(100% - 20px);
  margin: 10px 10px 0px;
  display: flex;
  justify-content: space-between;
}

.prev_contents_images > div {
  width: 23%;
  height: 50px;
  background-color: #cccccc;
}



.prev_contents_philosophy {
  width: calc(100% - 20px);
  margin: 0px 10px 10px;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0px 0px 4px #EBEBEB;
}

.prev_contents_philosophy > h4 {
  color: #222222;
  font-size: 13px;
  line-height: 25px;
  padding: 10px;
}

.prev_contents_philosophy > h5 {
  color: #222222;
  font-size: 13px;
  line-height: 25px;
  padding: 10px 10px 0;
}

.prev_contents_philosophy > p {
  color: #222222;
  font-size: 11px;
  line-height: 22px;
  padding: 0 10px 10px;
}



.prev_contents_store {
  width: calc(100% - 20px);
  margin: 10px 10px;
  background-color: white;
  border-radius: 3px;
}

.prev_contents_store > h4 {
  font-size: 13px;
  line-height: 25px;
  padding: 5px 10px;
  color: #222222;
}

.prev_contents_store > p {
  color: #222222;
  font-size: 11px;
  line-height: 22px;
  padding: 0 10px 5px;
}



.prev_contents_range {
  width: calc(100% - 20px);
  margin: 10px 10px;
  background-color: white;
  border-radius: 2px;
}

.prev_contents_range > h4 {
  font-size: 13px;
  color: #222222;
  padding: 10px;
}

.prev_contents_range_bar {
  width: calc(100% - 20px);
  display: flex;
  justify-content: space-between;
  border-radius: 3px;
  padding: 10px;
}

.prev_contents_range_bar > p {
  color: #AD0200;
  font-size: 11px;
  font-weight: bold;
}

.prev_contents_range_bar_img {
  width: calc(100% - 20px);
  margin: 0px 10px;
  height: 40px;
  /* background-color: pink; */
  background-image: url(./../../../image/recruit/slider_bar.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.prev_contents_range_bar_img > input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  margin: -10px 0 0;
  background: #EBEBEB;
  height: 5px;
  border-radius: 8px;
  z-index: 5;
}
 
/* Thumb: Chrome, Safari, Opera, Edge Chromium */
.prev_contents_range_bar_img > input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 10px;
  width: 10px;
  background-color: #AD0200;
  border-radius: 50%;
}
 
/* Thumb: Firefox */
.prev_contents_range_bar_img > input[type="range"]::-moz-range-thumb {
  border: none;
  height: 10px;
  width: 10px;
  background-color: #AD0200;
  border-radius: 50%;
}

.prev_contents_hair {
  width: calc(100% - 40px);
  margin: 10px 10px;
  background-color: white;
  border-radius: 2px;
  font-size: 11px;
  color: #222222;
  padding: 10px;
}

.prev_contents_image_items {
  height: 200px;
  object-fit: cover;
  width: calc(100% - 20px);
  margin: 10px 10px 0px;
}

.prev_contents_image_items_sub {
  display: flex;
  width: calc(100% - 20px);
  margin: 10px 10px 20px;
}

.prev_contents_image_items_sub > img {
  width: 23%;
  height: 50px;
  object-fit: cover;
  margin-right: 2%;
}

.prev_contents_store > table {
  width: 90%;
  border-collapse: collapse;
  font-size: 14px;
  line-height: 32px;
  margin: 0 5%;
}

.prev_contents_store > table > tr > th {
  width: 35%;
  border: .7px #373737 solid;
  padding: 5px;
  color: #222222;
  text-align: left;
  font-family: 'Noto Sans Japanese, NotoSansjp';
  font-size: 12px;
  line-height: 20px;
}

.prev_contents_store > table > tr > td {
  width: 65%;
  border: .7px #373737 solid;
  padding: 5px;
  color: #222222;
  text-align: left;
  font-family: 'Noto Sans Japanese, NotoSansjp';
  font-size: 12px;
  line-height: 20px;
}

.prev_contents_hair {
  width: calc(100% - 40px);
  margin: 10px 10px;
  background-color: white;
  border-radius: 2px;
  font-size: 11px;
  color: #222222;
  padding: 10px;
}

.prev_contents_hash_tags {
  width: calc(100% - 40px);
  margin: 10px 10px;
  background-color: white;
  border-radius: 2px;
  padding: 10px;
}

.prev_contents_hash_tags > h4 {
  font-size: 13px;
  color: #222222;
  margin-bottom: 5px;
}

.prev_contents_hash_tags > div {
  display: flex;
  flex-wrap: wrap;
}

.prev_contents_hash_tags > div > p {
  margin: 0 5px 5px 0;
  padding: 3px 5px;
  background-color: #db6172;
  font-size: 11px;
  text-align: center;
  color: white;
  border-radius: 3px;
  font-weight: bold;
  line-height: 20px;
}

/* progress_bar */

.progress_bar {
  width: 100%;
  margin: 50px 0 50px;
}

.progress_bar > img {
  width: 60px;
  height: 60px;
  object-fit: contain;
  margin: 0 calc(11% / 2 - 30px);
}

.progress_bar > div {
  display: flex;
  justify-content: space-between;
}

.progress_bar > div > div {
  width: calc(9% - 4px);
  height: 5px;
  background-color: #AD0200;
  border-radius: 100px;
}


