/* ナビゲーション */

.progress {
  width: 100%;
  display: flex;
  border-bottom: .5px solid #EBEBEB;
  margin-bottom: 30px;
}

.progress > h4 {
  line-height: 40px;
  border-bottom: 3px solid #F8FBFF;
  margin-right: 30px;
  color: #CCCCCC;
  font-size: 14px;
}


/* サービス > 店舗情報/求人管理 > 店舗名 */

.recruit_title {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}

.recruit_title > div {
  display: flex;
}

.recruit_title > div > h4 {
  font-size: 15px;
  line-height: 35px;
  color: #192651;
  margin-bottom: 10px;
}

.recruit_title > button {
  border: none;
  width: 150px;
  height: 35px;
  line-height: 35px;
  background-color: #AD0200;
  color: white;
  border-radius: 3px;
  font-weight: bold;
  margin-left: auto;
}

/* サービス > 店舗情報/求人管理 > 店舗名：編集 */

.works_service_recruit_title > div > input {
  width: 200px;
  border: none;
  border-bottom: .5px solid #EBEBEB;
  font-size: 14px;
  line-height: 35px;
  color: #222222;
  margin-bottom: 10px;
  text-indent: 1em;
}

.works_service_recruit_title > div > button {
  border: none;
  border-bottom: .5px solid #EBEBEB;
  background-color: #AD0200;
  font-size: 15px;
  width: 80px;
  color: white;
  line-height: 35px;
  border-radius: 3px;
  font-weight: bold;
  text-align: center;
  margin: 0 10px 10px 10px;
}


/* サービス > 店舗情報/求人管理 > 求人 */

.recruit_table {
  width: 100%;
  background-color: white;
  box-shadow: 0px 0px 2px #ababab;
  border-radius: 5px;
  margin-bottom: 5px;
  padding-bottom: 5px;
}

/* サービス > 店舗情報/求人管理 > 求人：ナビゲーション */

.recruit_table_nav {
  width: calc(100% - 50px);
  display: flex;
  margin: 20px 0 20px;
  padding-bottom: 5px;
  border-bottom: 1px solid #EBEBEB;
  background-color: #FAFAFA;
  padding: 10px 25px;
  border-radius: 5px 5px 0 0;
}

.recruit_table_nav > h2 {
  color: #222222;
  font-size: 12px;
  line-height: 25px;
  padding: 2.5px 0px;
  border-radius: 3px;
  font-weight: 400;
}

.recruit_table_nav > h3 {
  width: calc(100% - 700px);
  color: #222222;
  font-size: 12px;
  line-height: 30px;
  font-weight: 400;
}

.recruit_table_nav > h4 {
  width: 150px;
  color: #222222;
  font-size: 12px;
  line-height: 30px;
  font-weight: 400;
}

.recruit_table_nav_1 {
  width: 220px;
}

.recruit_table_nav_2 {
  width: calc(100% - 700px);
}

.recruit_table_nav_3 {
  width: 150px;
}

.recruit_table_nav_4 {
  width: 250px;
  display: flex;
  justify-content: space-between;
  margin: 0 30px;
}

.recruit_table_nav_4 > p {
  width: 60px;
  font-size: 12px;
  line-height: 30px;
}

/* サービス > 店舗情報/求人管理 > 店舗：削除 */

.recruit_folder_delete {
  width: 200px;
  text-align: right;
  float: right;
  font-size: 12px;
  color: #222222;
  line-height: 30px;
  margin-bottom: 40px;
}

/* サービス > 店舗情報/求人管理 > エリア：新規店舗追加 */

.recruit_folder_guide {
  display: flex;
  margin-top: 40px;
  width: 250px;
}

.recruit_folder_guide_icon {
  width: 20px;
  height: 20px;
  color: #222222;
  margin: 5px 10px 5px 0;
}

.recruit_folder_guide > p {
  font-size: 13px;
  line-height: 30px;
}










/* .memorii_recruit_body_page_contents_icons {
  color: #4B4B4B;
  width: 25px;
  height: 25px;
  margin: 2.5px 10px 2.5px 0;
}

.works_service_recruit_store_contents > h3 {
  color: white;
  font-size: 13px;
  width: 40px;
  line-height: 25px;
  padding: 2.5px 10px;
  border-radius: 3px;
} 

.memorii_recruit_body_page_contents_title {
  font-size: 15px;
  line-height: 30px;
  color: #192651;
} 

.memorii_recruit_body_page_navBar_count_icons {
  color: #222222;
  width: 18px;
  height: 18px;
  margin: 7px 15px 7px 0;
}

.memorii_recruit_body_page_navBar_icons {
  color: #4B4B4B;
  width: 25px;
  height: 25px;
  margin: 2.5px 10px 2.5px 0;
}


 */


/* 採用ページ */

/* .memorii_recruit_body_page_recruit_contents {
  width: calc(100% - 40px);
  padding: 20px;
  background-color: white;
  margin: 30px 0 30px;
  border-radius: 5px;
}

.memorii_recruit_body_page_recruit_contents_title {
  display: flex;
  justify-content: space-between;
}

.memorii_recruit_body_page_recruit_contents_title > p {
  font-size: 15px;
  color: #222222;
  line-height: 40px;
  font-weight: bold;
}

.memorii_recruit_body_page_recruit_contents_title > div {
  width: 20px;
}

.memorii_recruit_body_page_recruit_contents > hr {
  border: none;
  width: 100%;
  height: 1px;
  background-color: #EBEBEB;
}

.memorii_recruit_body_page_recruit_contents_items {
  display: flex;
  justify-content: space-between;
  margin: 30px 0 10px;
}

.memorii_recruit_body_page_recruit_contents_items > p {
  font-size: 15px;
  color: #222222;
  font-weight: bold;
}

.memorii_recruit_body_page_recruit_contents_items_icon {
  width: 20px;
  height: 20px;
  color: #222222;
}

.memorii_recruit_body_page_company_contents_items_drop {
  position: relative;
  width: 20px;
  height: 20px;
  z-index: 10;
}

.memorii_recruit_body_page_recruit_contents_items_icon_drop {
  position: absolute;
  width: 20px;
  height: 20px;
  color: #222222;
}

.memorii_recruit_body_page_company_contents_items_drop > div {
  position: absolute;
  width: 100px;
  height: 123px;
  top: 30px;
  background-color: white;
  border: 1px solid #EBEBEB;
  left: -70px;
  text-align: center;
}

.memorii_recruit_body_page_company_contents_items_drop > div > p {
  width: 100%;
  height: 30px;
  line-height: 30px;
  color: #222222;
  font-size: 14px;
}

.memorii_recruit_body_page_company_contents_items_drop > div > p:nth-child(1),
.memorii_recruit_body_page_company_contents_items_drop > div > p:nth-child(2),
  .memorii_recruit_body_page_company_contents_items_drop > div > p:nth-child(3) {
  border-bottom: 1px solid #EBEBEB;
}

.memorii_recruit_body_page_recruit_contents_title > p:nth-child(1),
.memorii_recruit_body_page_recruit_contents_items > p:nth-child(1) {
  width: 100px;
}

.memorii_recruit_body_page_recruit_contents_title > p:nth-child(2),
.memorii_recruit_body_page_recruit_contents_items > p:nth-child(2) {
  width: 550px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.memorii_recruit_body_page_recruit_contents_title > p:nth-child(3),
.memorii_recruit_body_page_recruit_contents_items > p:nth-child(3),
.memorii_recruit_body_page_recruit_contents_title > p:nth-child(4),
.memorii_recruit_body_page_recruit_contents_items > p:nth-child(4),
.memorii_recruit_body_page_recruit_contents_title > p:nth-child(5),
.memorii_recruit_body_page_recruit_contents_items > p:nth-child(5) {
  width: 50px;
}

.memorii_recruit_body_page_recruit_contents_items > p:nth-child(3),
.memorii_recruit_body_page_recruit_contents_items > p:nth-child(4),
.memorii_recruit_body_page_recruit_contents_items > p:nth-child(5) {
  text-align: center;
} */










