/* 右 */

.header {
  position: fixed;
  right: 0;
  width: calc(100% - 60px);
  height: 50px;
  display: flex;
  padding: 10px 30px;
  justify-content: space-between;
  border-bottom: .5px solid #EBEBEB;
  background-color: white;
  z-index: 1000;
}

@media (max-width: 700px) and (min-width: 0px) {
  .header {
    width: 90%;
    padding: 10px 5% 10px;
  }
}

/* ロゴ */

.header_logo {
  height: 40px;
  display: flex;
  margin-bottom: 30px;
  padding: 5px 10px;
}

.header_logo > img {
  width: 25px;
  height: 25px;
  object-fit: contain;
  margin: 7.5px 0;
}

.header_logo > h1 {
  line-height: 40px;
  font-size: 16px;
  font-weight: bold;
  padding-left: 20px;
  color: #273B4A;
}

/* 右側：リンク */

.header_right {
  display: flex;
  justify-content: space-between;
}

.header_right > div {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 700px) and (min-width: 500px) {
  .header_right > div:nth-child(1) {
    display: none;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .header_right > div:nth-child(1),
  .header_right > div:nth-child(2) {
    display: none;
  }
}

.header_right_icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin: 15px 0;
}

.header_right > div > p {
  line-height: 40px;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 50px 5px 10px;
}

.header_right_account {
  position: relative;
  width: 40px;
}

.header_right_account_info {
  position: absolute;
  display: flex;
  justify-content: space-between;
}

.header_right_account_info > img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 100px;
  margin: 5px 0;
}

.header_right_account_info > div {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 100px;
  margin: 5px 0;
  line-height: 40px;
  font-size: 12px;
  color: #222222;
  font-weight: bold;
}

.header_right_image {
  width: 150px;
  height: 150px;
  background-color: #EBEBEB;
  margin: 0 calc((100% - 151px) / 2) 20px;
  font-size: 55px;
  text-align: center;
  line-height: 100px;
  font-weight: bold;
  color: #222222;
  border: .5px solid #EBEBEB;
  object-fit: cover;
}

/* ナビゲーションポップ */

.header_right_account_nav {
  position: absolute;
  width: 150px;
  height: 75px;
  top: 60px;
  background-color: white;
  border: 1px solid #EBEBEB;
  left: -110px;
  text-align: center;
  border-radius: 3px;
}

.header_right_account_nav > div {
  width: 130px;
  display: flex;
  padding: 3px 10px;
}

.header_right_account_nav_icon {
  width: 25px;
  height: 25px;
  margin: 2.5px;
}

.header_right_account_nav > div > p {
  width: 100%;
  height: 30px;
  line-height: 30px;
  color: #222222;
  font-size: 14px;
}

.header_right_account_nav > div:nth-child(1) {
  border-bottom: 1px solid #EBEBEB;
}
