
.works_service_manage_contents_examination_table_score {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 10px;
}

.works_service_manage_contents_examination_table_score_desc {
  font-size: 14px;
  color: #222222;
  line-height: 30px;
  margin: 10px 0 0px;
}

.works_service_manage_contents_examination_table_score_results {
  width: 180px;
  display: flex;
  justify-content: space-between;
}

.works_service_manage_contents_examination_table_score_results > p {
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: bold;
}

.works_service_manage_contents_examination_table_score_right {
  width: 100px;
  display: flex;
  justify-content: space-between;
}

.works_service_manage_contents_examination_table_score_right > p {
  font-size: 14px;
  font-weight: bold;
  text-align: right;
  color: #AD0200;
  line-height: 40px;
}

.works_service_manage_contents_examination_table_score_right > select {
  width: 100px;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
  text-align: right;
  color: #222222;
  text-align: left;
}