
.box {
  width: 100%;
  display: flex;
  margin: 20px 0 0;
  justify-content: space-between;
}

.box > img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 100px;
  margin-right: 10px;
}

.box > p {
  font-size: 14px;
  width: 50px;
  text-align: center;
  font-weight: bold;
  line-height: 40px;
}
