
.modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100000000000;
}

.modal > div {
  position: relative;
}

.modal_black {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #222222;
  opacity: .3;
}

.modal_contents {
  top: 0;
  position: absolute;
  width: 650px;
  height: 340px;
  padding: 25px;
  background-color: white;
  margin: calc((100vh - 300px) / 2) calc((100vw - 700px) / 2);
  border-radius: 5px;
}


.modal_contents_title {
  display: flex;
  justify-content: space-between;
}

.modal_contents_title > h4 {
  font-size: 16px;
  color: #222222;
  line-height: 41px;
  font-weight: bold;
}

.modal_contents_title > button {
  border: none;
  width: 80px;
  height: 35px;
  line-height: 35px;
  background-color: #AD0200;
  border-radius: 3px;
  color: white;
  font-weight: bold;
}

.desc {
  font-size: 12px;
  line-height: 25px;
  color: #222222;
  margin-bottom: 10px;
}


/* 画像添付前 */

.drop {
  background-size: 20px 1px;
  margin-top: 30px;
  background-image: url(./../../../image/photo/drop.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 1em;
  resize: none;
  width: 100%;
  height: 222px;
  line-height: 46px;
  border: none;
  padding: 0px 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  color: #333333;
}

.drop > input[type="file"] {
  position: absolute;
  opacity: 0.0;
  width: 100%;
  height: 222px;
  z-index: 3;
}

.drop > h5 {
  position: absolute;
  font-size: 18px;
  line-height: 40px;
  padding-top: 40px;
  text-align: center;
  color: #AD0200;
  z-index: 1;
  top: 0px;
  width: 100%;
}

.drop > p {
  position: absolute;
  text-align: center;
  font-size: 14px;
  color: #222222;
  padding-top: 95px;
  line-height: 30px;
  z-index: 1;
  width: 100%;
}

/* 画像添付後 */

.drop_area {
  width: 100%;
  height: 222px;
  background-size: 20px 1px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.drop_area > img {
  width: 222px;
  height: 222px;
  border: .5px solid #222222;
  object-fit: contain;
}

.drop_area_select {
  width: 350px;
  height: 222px;
}

.drop_area_select > h5 {
  height: 40px;
  width: 100%;
  border: none;
  border: 1px solid #d1d1d1;
  font-size: 14px;
  line-height: 40px;
  text-indent: 1em;
  border-radius: 2px;
  color: #222222;
}

.drop_area_select > p {
  width: 100%;
  font-size: 12px;
  line-height: 30px;
  border-radius: 2px;
  color: #222222;
}

.select {
  height: 40px;
  width: 100%;
  border: none;
  border: 1px solid #d1d1d1;
  font-size: 14px;
  line-height: 35px;
  text-indent: 1em;
  border-radius: 2px;
  color: #222222;
  background-color: white;
}



