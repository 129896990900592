.box {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100000000000;
}

.box > div {
  position: relative;
}

.box_black {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #222222;
  opacity: .3;
}

.box_contents {
  position: absolute;
  width: 350px;
  padding: 20px 25px;
  height: 450px;
  background-color: white;
  border-radius: 5px;
  margin: calc((100vh - 450px) / 2) calc((100vw - 400px) / 2);
}

/* タイトル */

.title {
  display: flex;
  justify-content: space-between;
}

.title > h4 {
  font-size: 16px;
  color: #0F1213;
  line-height: 41px;
  font-weight: bold;
}

.title > button {
  border: none;
  width: 80px;
  height: 35px;
  line-height: 35px;
  background-color: #AD0200;
  border-radius: 3px;
  color: white;
  font-weight: bold;
}


.desc {
  font-size: 12px;
  line-height: 30px;
  color: #787878;
  margin-top: 5px;
}

.desc > span {
  color: #AD0200;
}


.make_image {
  position: relative;
  width: 150px;
  height: 150px;
  background-color: #EBEBEB;
  margin: 0 calc((100% - 151px) / 2) 20px;
  font-size: 55px;
  text-align: center;
  line-height: 100px;
  font-weight: bold;
  color: #222222;
  border: .5px solid #EBEBEB;
  object-fit: cover;
}

.make_image_pen {
  position: absolute;
  width: 15px;
  height: 15px;
  top: 10px;
  right: 10px;
  z-index: 1;
  color: #222222;
}

.make_image > input {
  position: absolute;
  width: 150px;
  height: 150px;
  opacity: 0;
  z-index: 1;
  left: 0;
}

.make_image_file {
  position: absolute;
  width: 150px;
  height: 150px;
  background-color: #EBEBEB;
  font-size: 55px;
  color: #222222;
  border: .5px solid #EBEBEB;
  object-fit: cover;
  left: 0;
}




/* input */

/* タイトル */

.title {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.title > div {
  display: flex;
}

.title > div > p {
  margin-right: 10px;
  font-size: 15px;
  color: #0F1213;
  line-height: 40px;
  font-weight: bold;
}

.title > p {
  margin-right: 10px;
  font-size: 12px;
  color: #787878;
  line-height: 40px;
}

/* yes no */

.title_yes {
  margin: 10px 30px 10px 0;
  height: 20px;
  color: #AD0200;
  font-weight: bold;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  border-radius: 2px;
}

.input {
  height: 40px;
  width: calc(100% - 2px);
  border: none;
  border: 1px solid #cccccc;
  font-size: 14px;
  line-height: 35px;
  text-indent: 1em;
  border-radius: 2px;
}

.desc {
  width: 100%;
  font-size: 12px;
  color: #373737;
  line-height: 30px;
  margin: 5px 0 20px 0;
  text-align: left;
}
