.box {
  width: 900px;
  min-height: 100vh;
  padding: 100px calc((100% - 900px)/ 2) 50px;
  background-color: #F9FBFF;
  display: flex;
  justify-content: space-between;
}

.box_inline {
  width: 550px;
  margin: 0 auto;
}

.desc {
  font-size: 12px;
  line-height: 25px;
  color: #222222;
  margin-bottom: 10px;
}



.contents {
  width: calc(100% - 100px);
  padding: 50px 50px 40px;
  background-color: white;
  margin: 0px 0 30px;
  border-radius: 5px; 
  box-shadow: 0px 0px 2px #c7c7c7;
}

.contents > img {
  width: calc(100% - 100px);
  padding: 50px 50px 40px;
  height: 330px;
  object-fit: cover;
}

.title {
  width: calc(100% - 5px);
  background-color: #FAFAFA;
  border-left: 5px solid #AD0200;
  line-height: 40px;
  font-size: 15px;
  text-indent: 1em;
  margin-bottom: 30px;
}



.overtime {
  width: 400px;
  display: flex;
  justify-content: space-between;
}

.overtime > div {
  width: 180px;
  display: flex;
}

.overtime > div > input {
  line-height: 40px;
}

.overtime > div > p {
  line-height: 40px;
  font-size: 14px;
  margin-left: 10px;
}


.save_box {
  width: 500px;
  margin: 50px auto 0;
  display: flex;
  justify-content: space-evenly;
}

.save_box_now {
  border: none;
  width: 150px;
  height: 45px;
  line-height: 45px;
  border: .5px solid #AD0200;
  border-radius: 3px;
  font-size: 15px;
  color: #AD0200;
  font-weight: bold;
  background-color: white;
}

.save_box_save {
  border: none;
  width: 150px;
  height: 45px;
  line-height: 45px; 
  background-color: #AD0200;
  border-radius: 3px;
  font-size: 15px;
  color: white;
  font-weight: bold;
  box-shadow: 0px 0px 2px #787878;
}


.check {
  width: 100%;
  display: flex;
}

.check > div {
  display: flex;
  width: 200px;
}

.check > div > input {
  accent-color: #AD0200;
  width: 15px;
  height: 15px;
  margin: 12.5px 0;
}

.check > div > p {
  line-height: 40px;
  padding-left: 10px;
  color: #222222;
  font-size: 14px;
}



/* ======================= */







.memorii_recruit_body_page_company_textarea {
  height: 150px;
  padding: 5px 15px;
  width: calc(100% - 30px);
  border: none;
  border: 1px solid #EBEBEB;
  font-size: 14px;
  line-height: 35px;
  border-radius: 2px;
  color: #222222;
  resize: vertical;
}

.memorii_recruit_body_page_company_edit_input_area_form_two_input {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.memorii_recruit_body_page_company_edit_input_area_form_two_input > p {
  line-height: 40px;
}

.memorii_recruit_body_page_company_edit_input_area_form_two_input > div {
  width: 200px;
  display: flex;
  justify-content: space-between;
}

.memorii_recruit_body_page_company_edit_input_area_form_two_input > div > p {
  line-height: 40px;
  font-size: 14px;
}

.memorii_recruit_body_page_company_edit_input_area_form_two_input > div > input {
  width: 150px;
  height: 40px;
  border: none;
  border: 1px solid #d1d1d1;
  font-size: 14px;
  line-height: 35px;
  text-indent: 1em;
  border-radius: 2px;
}

.memorii_recruit_body_page_company_edit_input_area_form_two_input > input {
  width: 200px;
  height: 40px;
  border: none;
  border: 1px solid #d1d1d1;
  font-size: 14px;
  line-height: 35px;
  text-indent: 1em;
  border-radius: 2px;
}

.memorii_recruit_body_page_company_edit_input_area_form_two_input > select {
  width: 200px;
  height: 40px;
  border: none;
  border: 1px solid #d1d1d1;
  font-size: 14px;
  line-height: 35px;
  text-indent: 1em;
  border-radius: 2px;
}



/* .salon_content_single_text {
  width: 100%;
  margin-top: 20px;
}

.salon_content_single_text > div {
  width: 100%;
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
}

.salon_content_single_text > div > h5 {
  font-size: 15px;
  color: #333333;
  line-height: 40px;
  text-align: left;
  text-shadow: 0px 0px 2px 2px #f1f1f1;
}

.salon_content_single_text > div > p {
  text-align: left;
  font-size: 12px;
  color: #777777;
  line-height: 40px;
  text-shadow: 0px 0px 2px 2px #f1f1f1;
}

.salon_content_single_text > textarea {
  height: 1em;
  resize: none;
  width: calc(100% - 30px);
  height: 300px;
  line-height: 30px;
  border: none;
  background-color: white;
  box-shadow: 0px 0px 2px 2px #f1f1f1;
  padding: 3px 15px;
} */

/* QA */

.salon_content_single_QA {
  width: 100%;
  margin-top: 20px;
}

.salon_content_single_QA > div {
  width: 100%;
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
}

.salon_content_single_QA > div > h5 {
  font-size: 15px;
  color: #333333;
  line-height: 40px;
  text-align: left;
  text-shadow: 0px 0px 2px 2px #f1f1f1;
}

.salon_content_single_QA > div > p {
  text-align: left;
  font-size: 12px;
  color: #777777;
  line-height: 40px;
  text-shadow: 0px 0px 2px 2px #f1f1f1;
}

.salon_content_single_QA > textarea {
  height: 1em;
  resize: none;
  width: calc(100% - 30px);
  height: 60px;
  line-height: 30px;
  border: none;
  background-color: white;
  box-shadow: 0px 0px 2px 2px #f1f1f1;
  padding: 3px 15px;
}

/* photos */

.salon_content_photos {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  flex-wrap: wrap;
  position: relative;
  z-index: 0;
}

.salon_content_photos  > div {
  width: 120px;
  height: 120px;
  background: white;
  margin: 5px;
  border-radius: 3px;
  box-shadow: 0px 0px 2px 2px #f1f1f1;
}

.image_box > img {
  border-radius: 3px;
}

.image_box_icon {
  width: 50px;
  height: 50px;
  color: #FF8D89;
  margin: 25px 35px 0;
}

.post-file-items {
  object-fit: cover;
}

.salon_content_photos  > div > p {
  font-size: 12px;
  font-weight: bold;
  color: #555555;
}

.post-file-items {
  position: absolute;
  left: 0;
  top: 0;
  width: 120px;
  height: 120px;
  object-fit: cover;
  margin: 5px;
}

.image_box > div {
  position: absolute;
  left: 0;
  top: 0;
  padding: 3px;
  height: 20px;
  object-fit: cover;
  margin: 105px 5px 5px 5px;
  z-index: 1;
  font-size: 10px;
  line-height: 20px;
  color: #222222;
  text-align: left;
  background-color: white;
}

.image_box > input[type="file"] {
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 120px;
  height: 120px;
  overflow-wrap: normal;
  margin: 5px;
  z-index: 10;
}


/* preview */

/* .salon_preview_text {
  position: absolute;
  overflow: scroll;
  width: 273px;
  height: 582px;
  z-index: 1;
  border-radius: 32px;
  margin: 9px 0 0 34px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.salon_preview_text::-webkit-scrollbar {  
  display: none;
}

.salon_preview_text_top_photo {
  width: 273px;
  height: 180px;
  background-color: #777777;
  margin-bottom: 10px;
  background-image: url(https://www.nihon-trim.co.jp/media/wp-content/uploads/2017/02/iStock-666526812.jpg);
  background-size: cover;
}

.salon_preview_text_img_top {
  position: absolute;
  left: 20px;
  height: 600px;
  margin: 0 auto;
  object-fit: contain;
}

.salon_preview_text_img_1 {
  position: absolute;
  width: 160px;
  z-index: 2;
  left: 0;
  margin: 8px 0 0 90px;
}

.salon_preview_text > h4 {
  font-size: 15px;
  width: 253px;
  margin: 5px 10px 10px;
  text-align: left;
  color: #222222;
}

.salon_preview_text_1 {
  font-size: 13px;
  text-align: left;
  width: 253px;
  margin: 15px 10px 0;
}

.salon_preview_text_2 {
  width: 253px;
  margin: 15px 10px 0;
  text-align: left;
  font-size: 10px;
  color: #464646;
  margin-top: 7px;
}

.salon_preview_text_table {
  border-collapse: collapse;
  width: 253px;
  margin: 15px 10px 0;
  background-color: #fcfcfc;
}

.salon_preview_text_td_1 {
  border: 1px solid #888888;
  text-align: left;
  font-size: 9px;
  width: 80px;
  padding: 0 0 0 5px;
  color: #555555;
}

.salon_preview_text_td_2 {
  border: 1px solid #888888;
  text-align: left;
  font-size: 9px;
  padding: 5px 0 5px 5px;
  color: #555555;
}

.salon_preview_text_photos {
  width: 253px;
  margin: 15px 10px 0;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
}

.salon_preview_text_photos > img {
  width: 82px;
  height: 82px;
  object-fit: cover;
  border-radius: 0px;
  margin-bottom: 5px;
}

.salon_preview_text_Q {
  width: 253px;
  margin: 15px 10px 0;
  text-align: left;
  font-size: 9px;
  color: #FF8D89;
  font-weight: bold;
}

.salon_preview_text_A {
  width: 253px;
  margin: 10px 10px 10px;
  text-align: left;
  font-size: 9px;
  color: #555555;
}

.salon_preview_text_img_3 {
  position: absolute;
  left: 20px;
  width: 160px;
  margin: 530px 0 0 75px;
  object-fit: contain;
  z-index: 2;
}

.salon_preview_text_speace {
  height: 80px;
}

.memorii_recruit_body_page_company_edit_input_area_form_two_input {
  width: 400px;
  display: flex;
  justify-content: space-between;
}

.memorii_recruit_body_page_company_edit_input_area_form_two_input > p {
  line-height: 40px;
}

.memorii_recruit_body_page_company_edit_input_area_form_two_input > input {
  width: 170px;
  height: 40px;
  border: none;
  border: 1px solid #EBEBEB;
  font-size: 14px;
  line-height: 35px;
  text-indent: 1em;
  border-radius: 2px;
}

.memorii_recruit_body_page_company_edit_input_area_form_two_input > select {
  width: 170px;
  height: 40px;
  border: none;
  border: 1px solid #EBEBEB;
  font-size: 14px;
  line-height: 35px;
  text-indent: 1em;
  border-radius: 2px;
}

 */







.memorii_recruit_recruit_prev_contents_title {
  width: calc(100% - 20px);
  margin: 15px 10px 0;
  line-height: 25px;
  font-size: 14px;
  color: #222222;
}





/* text */

.salon_content_single_text {
  width: 100%;
  margin-top: 20px;
}

.salon_content_single_text > div {
  width: 100%;
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
}

.salon_content_single_text > div > h5 {
  font-size: 15px;
  color: #333333;
  line-height: 40px;
  text-align: left;
  text-shadow: 0px 0px 2px 2px #f1f1f1;
}

.salon_content_single_text > div > p {
  text-align: left;
  font-size: 12px;
  color: #777777;
  line-height: 40px;
  text-shadow: 0px 0px 2px 2px #f1f1f1;
}

.salon_content_single_text > textarea {
  height: 1em;
  resize: none;
  width: calc(100% - 30px);
  height: 300px;
  line-height: 30px;
  border: none;
  background-color: white;
  box-shadow: 0px 0px 2px 2px #f1f1f1;
  padding: 3px 15px;
}

/* QA */

.salon_content_single_QA {
  width: 100%;
  margin-top: 20px;
}

.salon_content_single_QA > div {
  width: 100%;
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
}

.salon_content_single_QA > div > h5 {
  font-size: 15px;
  color: #333333;
  line-height: 40px;
  text-align: left;
  text-shadow: 0px 0px 2px 2px #f1f1f1;
}

.salon_content_single_QA > div > p {
  text-align: left;
  font-size: 12px;
  color: #777777;
  line-height: 40px;
  text-shadow: 0px 0px 2px 2px #f1f1f1;
}

.salon_content_single_QA > textarea {
  height: 1em;
  resize: none;
  width: calc(100% - 30px);
  height: 60px;
  line-height: 30px;
  border: none;
  background-color: white;
  box-shadow: 0px 0px 2px 2px #f1f1f1;
  padding: 3px 15px;
}

/* photos */

.salon_content_photos {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  flex-wrap: wrap;
  position: relative;
  z-index: 0;
}

.salon_content_photos  > div {
  width: 120px;
  height: 120px;
  background: white;
  margin: 5px;
  border-radius: 3px;
  box-shadow: 0px 0px 2px 2px #f1f1f1;
}

.image_box > img {
  border-radius: 3px;
}

.image_box_icon {
  width: 50px;
  height: 50px;
  color: #FF8D89;
  margin: 25px 35px 0;
}

.post-file-items {
  object-fit: cover;
}

.salon_content_photos  > div > p {
  font-size: 12px;
  font-weight: bold;
  color: #555555;
}

.post-file-items {
  position: absolute;
  left: 0;
  top: 0;
  width: 120px;
  height: 120px;
  object-fit: cover;
  margin: 5px;
}

.image_box > div {
  position: absolute;
  left: 0;
  top: 0;
  padding: 3px;
  height: 20px;
  object-fit: cover;
  margin: 105px 5px 5px 5px;
  z-index: 1;
  font-size: 10px;
  line-height: 20px;
  color: #222222;
  text-align: left;
  background-color: white;
}

.image_box > input[type="file"] {
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 120px;
  height: 120px;
  overflow-wrap: normal;
  margin: 5px;
  z-index: 10;
}


/* preview */

.salon_preview_text {
  position: absolute;
  overflow: scroll;
  width: 273px;
  height: 582px;
  z-index: 1;
  border-radius: 32px;
  margin: 9px 0 0 34px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.salon_preview_text::-webkit-scrollbar {  
  display: none;
}

.salon_preview_text_top_photo {
  width: 273px;
  height: 180px;
  background-color: #777777;
  margin-bottom: 10px;
  background-image: url(https://www.nihon-trim.co.jp/media/wp-content/uploads/2017/02/iStock-666526812.jpg);
  background-size: cover;
}

.salon_preview_text_img_top {
  position: absolute;
  left: 20px;
  height: 600px;
  margin: 0 auto;
  object-fit: contain;
}

.salon_preview_text_img_1 {
  position: absolute;
  width: 160px;
  z-index: 2;
  left: 0;
  margin: 8px 0 0 90px;
}

.salon_preview_text > h4 {
  font-size: 15px;
  width: 253px;
  margin: 5px 10px 10px;
  text-align: left;
  color: #222222;
}

.salon_preview_text_1 {
  font-size: 13px;
  text-align: left;
  width: 253px;
  margin: 15px 10px 0;
}

.salon_preview_text_2 {
  width: 253px;
  margin: 15px 10px 0;
  text-align: left;
  font-size: 10px;
  color: #464646;
  margin-top: 7px;
}

.salon_preview_text_table {
  border-collapse: collapse;
  width: 253px;
  margin: 15px 10px 0;
  background-color: #fcfcfc;
}

.salon_preview_text_td_1 {
  border: 1px solid #888888;
  text-align: left;
  font-size: 9px;
  width: 80px;
  padding: 0 0 0 5px;
  color: #555555;
}

.salon_preview_text_td_2 {
  border: 1px solid #888888;
  text-align: left;
  font-size: 9px;
  padding: 5px 0 5px 5px;
  color: #555555;
}

.salon_preview_text_photos {
  width: 253px;
  margin: 15px 10px 0;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
}

.salon_preview_text_photos > img {
  width: 82px;
  height: 82px;
  object-fit: cover;
  border-radius: 0px;
  margin-bottom: 5px;
}

.salon_preview_text_Q {
  width: 253px;
  margin: 15px 10px 0;
  text-align: left;
  font-size: 9px;
  color: #FF8D89;
  font-weight: bold;
}

.salon_preview_text_A {
  width: 253px;
  margin: 10px 10px 10px;
  text-align: left;
  font-size: 9px;
  color: #555555;
}

.salon_preview_text_img_3 {
  position: absolute;
  left: 20px;
  width: 160px;
  margin: 530px 0 0 75px;
  object-fit: contain;
  z-index: 2;
}

.salon_preview_text_speace {
  height: 80px;
}

.memorii_recruit_body_page_company_edit_input_area_form_two_input {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.memorii_recruit_body_page_company_edit_input_area_form_two_input > p {
  line-height: 40px;
}

.memorii_recruit_body_page_company_edit_input_area_form_two_input > input {
  width: 200px;
  height: 40px;
  border: none;
  border: 1px solid #d1d1d1;
  font-size: 14px;
  line-height: 35px;
  text-indent: 1em;
  border-radius: 2px;
}

.memorii_recruit_body_page_company_edit_input_area_form_two_input > select {
  width: 200px;
  height: 40px;
  border: none;
  border: 1px solid #d1d1d1;
  font-size: 14px;
  line-height: 35px;
  text-indent: 1em;
  border-radius: 2px;
}

.memorii_recruit_body_page_company_edit_input_area_form_check {
  width: 400px;
}

.memorii_recruit_body_page_company_edit_input_area_form_check > div {
  display: flex;
}

.memorii_recruit_body_page_company_edit_input_area_form_check > div > div {
  display: flex;
  width: 200px;
}

.memorii_recruit_body_page_company_edit_input_area_form_check > div > div > input {
  accent-color: #AD0200;
  width: 15px;
  height: 15px;
  margin: 12.5px 0;
}

.memorii_recruit_body_page_company_edit_input_area_form_check > div > div > p {
  line-height: 40px;
  padding-left: 10px;
  color: #222222;
  font-size: 14px;
}







.memorii_recruit_body_edit_textarea {
  height: 150px;
  padding: 5px 15px;
  width: calc(100% - 30px);
  border: none;
  border: 1px solid #EBEBEB;
  font-size: 14px;
  line-height: 35px;
  border-radius: 2px;
  color: #222222;
  resize: vertical;
}


.numberTag {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0px 0;
}

.numberTag > div {
  margin-right: 20px;
  display: flex;
}

.numberTag > div > input {
  line-height: 40px;
  accent-color: #AD0200;
}

.numberTag > div > p {
  color: #222222;
  line-height: 40px;
  font-size: 14px;
  margin-left: 10px;
}



.radio_btn {
  width: 400px;
  display: flex;
  justify-content: space-between;
}

.radio_btn > div {
  width: 180px;
  display: flex;
}

.radio_btn > div > input {
  line-height: 40px;
}

.radio_btn > div > p {
  line-height: 40px;
  font-size: 14px;
  margin-left: 10px;
}