.app_download {
  width: calc(100% - 20px);
  padding: 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: .5px solid #EBEBEB;
}

@media (max-width: 10000px) and (min-width: 500px) {
  .app_download {
    display: none;
  }
}

.app_download > div {
  display: flex;
}

.app_download_icon {
  width: 15px;
  height: 15px;
  color: #535353;
  margin: 12.5px 10px 12.5px 0;
}

.app_download > div > img {
  width: 40px;
  height: 40px;
}

.app_download > div > p {
  font-size: 14px;
  line-height: 40px;
  font-weight: bold;
  margin-left: 10px;
}

.app_download > a > button {
  height: 30px;
  line-height: 30px;
  border: none;
  border-radius: 5px;
  background-color: #222222;
  padding: 0 10px;
  color: white;
  margin: 5px 0;
}


.header {
  width: 1350px;
  padding: 0 calc((100% - 1350px) / 2);
  height: 120px;
  border-bottom: .5px solid #EBEBEB;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1350px) and (min-width: 500px) {
  .header {
    width: 90%;
    padding: 0 5%;
  }
}

@media (max-width: 500px) and (min-width: 0) {
  .header {
    width: calc(100vw - 30px);
    padding: 0 15px;
  }
}

.logo {
  width: 200px;
  display: flex;
  padding: 35px 0;
}

@media (max-width: 900px) and (min-width: 0) {
  .logo {
    display: none;
  }
}

.logo > img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.logo > h4 {
  font-size: 16px;
  color: #222222;
  margin: 10px 0 10px 20px;
  line-height: 30px;
}

.topSearch {
  display: flex;
  padding: 20px 0;
}

.topSearch_icon {
  width: 25px;
  height: 25px;
  color: #FF4949;
  padding: 27.5px 12.5px;
}

.topSearch_hidden {
  width: 390px;
  height: 80px;
  border-radius: 100px;
  overflow: hidden;
  position: relative;
}

@media (max-width: 500px) and (min-width: 0) {
  .topSearch_hidden {
    width: calc(100vw - 30px);
  }
}

.topSearch_box_true {
  position: absolute;
  width: 370px;
  height: 80px;
  border-radius: 100px;
  background-color: #CDB51A;
  display: flex;
  animation: falldown .5s linear forwards;
  justify-content: space-between;
  padding: 0 10px;
}

.topSearch_box_false {
  position: absolute;
  width: 370px;
  height: 80px;
  border-radius: 100px;
  background-color: #CDB51A;
  display: flex;
  animation: fallup .5s linear forwards;
  justify-content: space-between;
  padding: 0 10px;
}

@media (max-width: 500px) and (min-width: 0) {
  .topSearch_box_true, .topSearch_box_false {
    width: calc(100vw - 50px);
  }
}

@keyframes falldown {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 1;
    transform: translateY(-80px);
  }
}

@keyframes fallup {
  0% {
    opacity: 1;
    transform: translateY(-80px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.topSearch_box_true > p, .topSearch_box_false > p {
  color: white;
  padding: 15px 0 15px 30px;
}

.topSearch_contents {
  width: 250px;
  display: flex;
}

@media (max-width: 900px) and (min-width: 500px) {
  .topSearch_contents {
    width: 220px;
  }
}

@media (max-width: 500px) and (min-width: 0) {
  .topSearch_contents {
    width: 60%;
  }
}

.topSearch_charisma {
  width: 60px;
  height: 60px;
  margin: 10px 0;
}

.topSearch_contents > div {
  margin: 15px 0;
}

.topSearch_contents > div > p {
  font-size: 12px;
  color: white;
  line-height: 20px;
}

.topSearch_contents > div > h4 {
  font-size: 20px;
  color: white;
  line-height: 30px;
}

.topSearch_arrow {
  width: 40px;
  height: 40px;
  background-color: white;
  border: none;
  border-radius: 100px;
  margin: 20px 5px 20px;
}

.topSearch_arrow_icon {
  width: 20px;
  height: 20px;
  margin: 10px;
  color: #CDB51A;
}

.links {
  display: flex;
  padding: 35px 0;
  justify-content: space-between;
}

@media (max-width: 900px) and (min-width: 00px) {
  .links {
    display: none;
  }
}

.links > h4 {
  font-size: 14px;
  color: #222222;
  margin: 0px 30px 0px 0;
  font-weight: 400;
  line-height: 50px;
  padding: 0 20px;
  border-radius: 100px;
}

.links > h4:hover {
  background-color: #fafafa;
  border: .5px solid #EBEBEB;
  line-height: 49px;
  padding: 0 19.5px;
}


.links > div {
  width: 50px;
  height: 50px;
  background-color: #222222;
  border-radius: 100px;
}

.links_icon {
  width: 20px;
  height: 20px;
  padding: 15px;
  color: white;
}


.phone_filter {
  width: 48px;
  height: 48px;
  border: 1px solid #EBEBEB;
  border-radius: 100px;
  margin: 35px 0;
}

@media (max-width: 500px) and (min-width: 0) {
  .phone_filter {
    display: none;
  }
}

.phone_filter_icon {
  width: 20px;
  height: 20px;
  color: #535353;
  padding: 14px;
}

@media (max-width: 10000px) and (min-width: 900px) {
  .phone_filter {
    display: none;
  }
}

/* .topSearch_flip_true {
  width: 450px;
  height: 80px;
  border-radius: 100px;
  transform-style: preserve-3d;
  animation: flipFront .7s ease-in-out;
}

.topSearch_flip_false {
  width: 450px;
  height: 80px;
  border-radius: 100px;
  transform-style: preserve-3d;
  animation: flipBack .7s ease-in-out;
}

@keyframes flipBack {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(360deg);
  }
}

@keyframes flipFront {
  0% {
    transform: rotateY(360deg);
  }
  100% {
    transform: rotateY(0);
  }
} */