.progress {
  width: 100%;
  display: flex;
  border-bottom: .5px solid #EBEBEB;
  margin-bottom: 30px;
}

.progress > h4 {
  line-height: 40px;
  border-bottom: 3px solid #F8FBFF;
  margin-right: 30px;
  color: #CCCCCC;
  font-size: 14px;
}

.contact {
  width: 100%;
  padding: 0px;
  background-color: white;
  border-radius: 5px;
  height: 80vh;
  box-shadow: 0px 0px 2px #d1d1d1;
}

.contact_nav {
  width: calc(100% - 30px);
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 10px 10px;
  background-color: #FAFAFA;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
}

.contact_nav > select {
  border: none;
  width: 30%;
  height: 42px;
  color: #222222;
  text-indent: 1em;
  border-radius: 3px;
  border: 1px solid #EBEBEB;
}

.contact_nav > div {
  border: none;
  width: 68%;
  height: 40px;
  color: #222222;
  text-indent: 1em;
  border-radius: 3px;
  border: 1px solid #EBEBEB;
  display: flex;
  justify-content: space-between;
  background-color: white;
}

.contact_nav > div > input {
  border: none;
  width: 95%;
  line-height: 40px;
  height: 40px;
  background-color: white;
  font-size: 14px;
  color: #222222;
}

.contact_nav_icon {
  width: 20px;
  height: 40px;
  margin: 0 10px;
  color: #EBEBEB;
}

.contact_box {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.contact_box_list {
  height: calc(80vh - 63px);
  border-right: 2px solid #F8F6F7;
  width: 250px;
  overflow-y: scroll;
}

.contact_box_list_bar {
  display: flex;
  justify-content: space-evenly;
  padding: 7px 0;
  border-bottom: .5px solid #EBEBEB;
}

.contact_box_list_bar > p {
  width: 30%;
  font-size: 12px;
  border: .5px solid #EBEBEB;
  text-align: center;
  line-height: 28px;
  border-radius: 3px;
  color: #222222;
}

.contact_box_chat {
  width: calc(100% - 605px);
  height: calc(80vh - 73px);
}

.contact_box_info {
  width: 310px;
  padding: 20px 20px;
  height: calc(80vh - 103px);
  background-color: white;
  border-radius: 0 5px 5px 0;
  border-left: 2px solid #F8F6F7;
  overflow-y: scroll;
}
