
.manage {
  width: 100%;
  padding: 0 0 5px;
  margin: 30px 0 0;
  border-radius: 5px;
  box-shadow: 0px 0px 2px #D1D1D1;
  background-color: white;
}

.manage_header {
  width: calc(100% - 50px);
  margin: 20px 0 0px;
  padding-bottom: 5px;
  border-bottom: 1px solid #EBEBEB;
  background-color: #FAFAFA;
  padding: 10px 25px;
  border-radius: 5px 5px 0 0;
}


.manage_header_word {
  width: 100%;
  display: flex;
  justify-content: space-between;
}


.manage_header_word > select {
  border: none;
  width: 30%;
  height: 42px;
  color: #222222;
  text-indent: 1em;
  border-radius: 3px;
  border: 1px solid #EBEBEB;
}

.manage_header_word > div {
  border: none;
  width: 68%;
  height: 40px;
  color: #222222;
  text-indent: 1em;
  border-radius: 3px;
  border: 1px solid #EBEBEB;
  display: flex;
  justify-content: space-between;
  background-color: white;
}

.manage_header_word_icon {
  width: 20px;
  height: 40px;
  margin: 0 10px;
  color: #EBEBEB;
}

.manage_header_word > div > input {
  border: none;
  width: 95%;
  line-height: 40px;
  height: 40px;
  background-color: white;
  font-size: 14px;
  color: #222222;
}



.manage_table {
  width: 100%;
  display: flex;
  padding: 18px 0 5px;
  border-radius: 5px 5px 0 0;
}

.manage_table > p {
  font-size: 13px;
  line-height: 30px;
  font-weight: bold;
  color: #222222;
}

.manage_table_1 {
  width: 220px;
  border: none;
}

.manage_table_2 {
  width: 100px;
}

.manage_table_3 {
  width: 150px;
}

.manage_table_4 {
  width: 330px;
  margin-right: auto;
}

.manage_table_5 {
  width: 150px;
}

.manage_table_6 {
  width: 40px;
  margin: 0 10px;
}

.manage_table_7 {
  width: 30px;
}

/* .works_service_manage_pc_contents_table {
  width: 100%;
  display: flex;
  padding: 18px 0 5px;
  border-radius: 5px 5px 0 0;
}

.works_service_manage_pc_contents_table > p {
  font-size: 13px;
  line-height: 30px;
  font-weight: bold;
  color: #222222;
}

.works_service_manage_pc_contents_table_1 {
  width: 220px;
  border: none;
}

.works_service_manage_pc_contents_table_2 {
  width: 100px;
}

.works_service_manage_pc_contents_table_3 {
  width: 150px;
}

.works_service_manage_pc_contents_table_4 {
  width: 300px;
  padding-right: 20px;
}

.works_service_manage_pc_contents_table_5 {
  width: 150px;
}

.works_service_manage_pc_contents_table_6 {
  width: 70px;
  margin-left: auto;
}

.works_service_manage_pc_contents_table_7 {
  width: 30px;
} */


/*  */

.works_service_manage_pc_examination {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.works_service_manage_pc_contents_examination > div {
  width: 100%;
  padding: 0 0 5px;
  margin: 30px 0 0;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 0px 2px #D1D1D1;
}

.works_service_manage_pc_contents_header_examination {
  width: calc(100% - 50px);
  margin: 0px 0 0px;
  padding-bottom: 5px;
  border-bottom: 1px solid #EBEBEB;
  background-color: #FAFAFA;
  padding: 10px 25px;
  border-radius: 5px 5px 0 0;
}


.works_service_manage_pc_contents_header_examination_word {
  width: 100%;
}


.works_service_manage_pc_contents_header_examination_word > select {
  border: none;
  width: 100%;
  height: 42px;
  color: #222222;
  text-indent: 1em;
  border-radius: 3px;
  border: 1px solid #EBEBEB;
  margin-bottom: 10px;
}

.works_service_manage_pc_contents_header_examination_word > div {
  border: none;
  width: calc(100% - 2px);
  height: 40px;
  color: #222222;
  text-indent: 1em;
  border-radius: 3px;
  border: 1px solid #EBEBEB;
  display: flex;
  justify-content: space-between;
  background-color: white;
}

.works_service_manage_pc_contents_header_examination_word_icon {
  width: 20px;
  height: 40px;
  margin: 0 10px;
  color: #EBEBEB;
}

.works_service_manage_pc_contents_header_examination_word > div > input {
  border: none;
  width: 95%;
  line-height: 40px;
  height: 40px;
  background-color: white;
  font-size: 14px;
  color: #222222;
}



/* summary */

.works_service_manage_pc_contents_examination_summary {
  width: calc(53% - 100px);
  padding: 50px 50px 20px;
  background-color: white;
  margin: 30px 0 30px;
  border-radius: 5px; 
  box-shadow: 0px 0px 2px #c7c7c7;
  color: #222222;
}


.works_service_manage_pc_contents_examination_table {
  width: 100%;
  padding-bottom: 40px;
}

.works_service_manage_pc_contents_examination_table_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: .5px solid #EBEBEB;
}

.works_service_manage_pc_contents_examination_table_header_1 {
  width: calc(100% - 400px);
  font-size: 14px;
  font-weight: bold;
}

.works_service_manage_pc_contents_examination_table_header_2 {
  font-size: 14px;
  width: 50px;
  text-align: center;
  font-weight: bold;
}

.works_service_manage_pc_contents_examination_table_contents {
  width: 100%;
  display: flex;
  margin: 20px 0 0;
  justify-content: space-between;
}

.works_service_manage_pc_contents_examination_table_contents > div {
  width: calc(100% - 400px);
  display: flex;
}

.works_service_manage_pc_contents_examination_table_contents > div > img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 100px;
}

.works_service_manage_pc_contents_examination_table_contents > div > p {
  line-height: 40px;
  font-size: 14px;
  margin-left: 15px;
}

.works_service_manage_pc_contents_examination_table_contents > p {
  font-size: 14px;
  width: 50px;
  text-align: center;
  font-weight: bold;
  line-height: 40px;
}

.works_service_manage_pc_contents_examination_table_contents > h4 {
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  line-height: 40px;
}



