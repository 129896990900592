.slideImg {
  display: block;
  width: 100%;
  height: 350px;
  object-fit: cover;
}

@media (max-width: 500px) and (min-width: 0px) {
  .slideImg {
    display: block;
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
}