
.content {
  width: calc(100% - 60px);
  padding: 30px 30px 15px;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 4px #dfdfdf;
  border-radius: 5px;
}

@media (max-width: 700px) and (min-width: 0px) {
  .content {
    width: 90%;
    padding: 30px 5% 15px;
  }
}


.content > h4 {
  width: 100%;
  font-size: 18px;
  color: #222222;
  margin: 0px 0 20px 0;
  text-align: center;
  background-color: #F5DDDD;
  padding: 5px 0;
  border-radius: 3px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  line-height: 32px;
}

.table > * > * > th,
.table > * > * > td {
  border: .7px #cccccc solid;
  padding: 15px;
  color: #222222;
  text-align: left;
  font-family: 'Noto Sans Japanese, NotoSansjp';
}

@media (max-width: 700px) and (min-width: 0px) {
  .table > *  > * > th {
    width: 40%;
  }

  .table > *  > * > td {
    width: 60%;
  }
}

.table > * > * > td > p {
  white-space: pre-wrap;
}

.table > * > * > td > p > a {
  text-decoration: none;
  color: #222222;
}