.title {
  width: 400px;
  display: flex;
  justify-content: space-between;
}

.title > p {
  color: #AD0200;
  font-size: 13px;
  font-weight: bold;
}

.bar {
  width: 400px;
  height: 40px;
  background-image: url(./../../../image/recruit/slider.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.bar > input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  width: 400px;
  background: #EBEBEB;
  height: 5px;
  border-radius: 8px;
  margin-top: 0px;
  z-index: 5;
}
 
/* Thumb: Chrome, Safari, Opera, Edge Chromium */
.bar > input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 10px;
  width: 10px;
  background-color: #AD0200;
  border-radius: 50%;
}
 
/* Thumb: Firefox */
.memorii_recruit_body_page_company_edit_input_area_sider_range_img > input[type="range"]::-moz-range-thumb {
  border: none;
  height: 10px;
  width: 10px;
  background-color: #AD0200;
  border-radius: 50%;
}