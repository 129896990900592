.modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100000000000;
}

.modal > div {
  position: relative;
}

.modal_black {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #222222;
  opacity: .3;
}

.modal_contents {
  position: absolute;
  width: 400px;
  padding: 20px 25px;
  height: 560px;
  background-color: white;
  border-radius: 5px;
  margin: calc((100vh - 600px) / 2) calc((100vw - 450px) / 2);
}

/* タイトル */

.title {
  display: flex;
  justify-content: space-between;
}

.title > h5 {
  font-size: 15px;
  line-height: 35px;
}

.title > div {
  display: flex;
}

.title > div > button:nth-child(1) {
  width: 100px;
  height: 35px;
  line-height: 35px;
  border: none;
  color: #AD0200;
  font-weight: bold;
  border-radius: 3px;
  background-color: white;
}

.title > div > button:nth-child(2) {
  width: 100px;
  height: 35px;
  line-height: 35px;
  border: none;
  background-color: #AD0200;
  color: white;
  font-weight: bold;
  border-radius: 3px;
}

.desc {
  width: 100%;
  font-size: 12px;
  color: #373737;
  line-height: 30px;
  margin: 5px 0 0 0;
}





.memorii_recruit_image_style_contents > select {
  width: 100%;
  margin-top: 20px;
  background-color: #fafafa;
  border: .5px solid #EBEBEB;
  height: 40px;
}

.memorii_recruit_image_style_contents > p {
  font-size: 13px;
  line-height: 30px;
  margin: 15px 0 10px;
}

/* タグ選択 */

/* .memorii_recruit_image_style_contents_radio {
  width: 100%;
  height: 400px;
  overflow-y: scroll;
} */

.select {
  width: 100%;
  height: 500px;
  overflow-y: scroll;
}

.select > div {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.select > div > div {
  width: 50%;
  height: 30px;
  display: flex;
  margin-bottom: 5px;
}

.select > div > div > input {
  width: 15px;
  height: 15px;
  margin: 7.5px 10px 7.5px 0;
  accent-color: #AD0200;
}

.select > div > div > p {
  font-size: 13px;
  color: #222222;
  line-height: 30px;
}
