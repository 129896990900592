.works_service_mobile {
  width: 95%;
  /* min-height: 100vh; */
  padding: 0 2.5%;
  background-color: #F9FBFF;
}

.box {
  width: 100%;
  padding: 0 0 5px;
  border-radius: 5px;
  box-shadow: 0px 0px 2px #D1D1D1;
  background-color: white;
}

.box_header {
  width: 95%;
  padding-bottom: 5px;
  border-bottom: 1px solid #EBEBEB;
  background-color: #FAFAFA;
  padding: 10px 2.5%;
  border-radius: 5px 5px 0 0;
}

.box_header_search {
  width: 100%;
}

.box_select_recruit {
  border: none;
  width: 100%;
  height: 42px;
  color: #222222;
  text-indent: 1em;
  border-radius: 3px;
  border: 1px solid #EBEBEB;
  margin-bottom: 10px;
}

.box_select_store {
  border: none;
  width: 100%;
  height: 42px;
  color: #222222;
  text-indent: 1em;
  border-radius: 3px;
  border: 1px solid #EBEBEB;
  margin: 10px 0;
}

.box_header_search > div {
  border: none;
  width: calc(100% - 2px);
  height: 42px;
  color: #222222;
  text-indent: 1em;
  border-radius: 3px;
  border: 1px solid #EBEBEB;
  display: flex;
  justify-content: space-between;
  background-color: white;
}

.box_header_search > div > input {
  border: none;
  width: 95%;
  line-height: 42px;
  height: 42px;
  background-color: white;
  font-size: 14px;
  color: #222222;
}

.box_header_search_icon {
  width: 20px;
  height: 40px;
  margin: 0 10px;
  color: #EBEBEB;
}



.table_header {
  width: 100%;
  display: flex;
  padding: 18px 0 5px;
  border-radius: 5px 5px 0 0;
  justify-content: space-between;
}

.table_header > p {
  font-size: 13px;
  line-height: 30px;
  font-weight: bold;
  color: #222222;
}

.table_header_1 {
  width: 200px;
  border: none;
}

.table_header_2 {
  width: 50px;
}

.table_header_3 {
  width: 50px;
}

.table_header_4 {
  width: 50px;
}


.contact_box_chat {
  width: calc(100%);
  height: calc(80vh - 73px);
  overflow-x: scroll;
}

/* .contact_box_info {
  width: 310px;
  padding: 20px 20px;
  height: calc(80vh - 103px);
  background-color: white;
  border-radius: 0 5px 5px 0;
  border-left: 2px solid #F8F6F7;
  overflow-y: scroll;
} */



.contact_box_list_bar {
  display: flex;
  justify-content: space-between;
  padding: 7px 0;
  /* border-bottom: .5px solid #EBEBEB; */
}

.contact_box_list_bar > p {
  width: 30%;
  font-size: 12px;
  border: .5px solid #EBEBEB;
  text-align: center;
  line-height: 28px;
  border-radius: 3px;
  color: #222222;
}