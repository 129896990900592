.modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100000000000;
}

.box > div {
  position: relative;
}

.modal_black {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #222222;
  opacity: .3;
}

.modal_contents {
  position: absolute;
  width: 650px;
  height: 400px;
  padding: 25px;
  background-color: white;
  margin: calc((100vh - 400px) / 2) calc((100vw - 700px) / 2);
  border-radius: 5px;
}



.modal_contents_title {
  display: flex;
  justify-content: space-between;
}

.modal_contents_title > h4 {
  font-size: 16px;
  color: #0F1213;
  line-height: 41px;
  font-weight: bold;
}

.modal_contents_title > button {
  border: none;
  width: 80px;
  height: 35px;
  line-height: 35px;
  background-color: #AD0200;
  border-radius: 3px;
  color: white;
  font-weight: bold;
}

.modal_contents > p {
  font-size: 12px;
  line-height: 30px;
  color: #787878;
  margin-top: -5px;
}

.modal_contents_select {
  display: flex;
  justify-content: space-between;
}

.modal_contents_select > div {
  width: 32%;
  height: 150px;
  border-radius: 5px;
  margin-top: 15px;
}

.modal_contents_select > div > img {
  width: 80px;
  height: 80px;
  object-fit: contain;
  margin: 17px calc(50% - 40px) 8px;
}

.modal_contents_select > div > h4 {
  font-size: 14px;
  text-align: center;
  color: white;
  font-weight: bold;
  text-shadow: 0px 0px 2px #222222;
}



.example {
  width: 100%;
  height: 210px;
  border: .5px solid #EBEBEB;
  margin-top: 0px;
  border-radius: 3px;
}

.example_title {
  width: calc(100% - 40px);
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  background-color: #192651;
  color: white;
  font-weight: bold;
  border-radius: 3px 3px 0 0;
  padding: 0 20px;
}

.example_contents {
  overflow-y: scroll;
  width: 100%;
  height: 170px;
}

.example_contents > div {
  display: flex;
  justify-content: space-between;
  margin: 10px 20px 0;
  border-bottom: .5px solid #EBEBEB;
}

.example_contents > div > p {
  font-size: 14px;
  color: #222222;
  line-height: 35px;
}

.example_contents > div > button {
  line-height: 35px;
  border: none;
  font-size: 12px;
  color: #192651;
  background-color: white;
  font-weight: bold;
}



/* .memorii_recruit_make_content > h5 {
  font-size: 15px;
  margin: 16px 0 8px;
  line-height: 30px;
}

.memorii_recruit_make_content > div > p {
  margin-top: 15px;
  font-size: 14px;
  color: #787878;
  height: 40px;
  line-height: 40px;
  font-weight: bold;
}

.memorii_recruit_make_content > p > span {
  color: #EF4444;
  font-weight: bold;
}

.memorii_recruit_store_make_content > p > span {
  color: #EF4444;
  font-weight: bold;
}

.memorii_recruit_make_content > input {
  height: 40px;
  width: calc(100% - 2px);
  border: none;
  border: 1px solid #EBEBEB;
  font-size: 14px;
  line-height: 35px;
  text-indent: 1em;
  border-radius: 2px;
  margin-top: 15px;
  color: #222222;
}

.memorii_recruit_store_make_content > input {
  height: 40px;
  width: calc(100% - 2px);
  border: none;
  border: 1px solid #EBEBEB;
  font-size: 14px;
  line-height: 35px;
  text-indent: 1em;
  border-radius: 2px;
  margin-top: 15px;
  color: #222222;
} */
