/* タイトル */

.title {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.title > div {
  display: flex;
}

.title > div > p {
  margin-right: 10px;
  font-size: 15px;
  color: #0F1213;
  line-height: 40px;
  font-weight: bold;
}

.title > p {
  margin-right: 10px;
  font-size: 12px;
  color: #787878;
  line-height: 40px;
}

/* yes no */

.title_yes {
  margin: 10px 30px 10px 0;
  height: 20px;
  color: #AD0200;
  font-weight: bold;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  border-radius: 2px;
}

.title_no {
  margin: 10px 30px 10px 0;
  width: 34px;
  height: 20px;
  background-color: #b9b9b9;
  color: white;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  border-radius: 2px;
}

.desc {
  width: 100%;
  font-size: 12px;
  color: #373737;
  line-height: 30px;
  margin: 5px 0 0 0;
}

.input {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.input > p {
  line-height: 40px;
  font-size: 14px;
}

.input > input {
  width: calc(100% - 50px);
  height: 40px;
  border: none;
  border: 1px solid #d1d1d1;
  font-size: 14px;
  line-height: 35px;
  text-indent: 1em;
  border-radius: 2px;
}
