.box {
  width: 1000px;
  height: 400px;
  display: flex;
  justify-content: space-between;
  background-color: #AD0200;
  background-image: url(./../../image/auth/signin.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: calc((100vh - 400px) / 2) calc((100% - 1000px) / 2);
}

@media (max-width: 1000px) and (min-width: 500px) {
  .box {
    width: 410px;
    padding: calc((100vh - 400px) / 2) calc((100% - 410px) / 2);
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .box {
    width: 100%;
    padding: calc((100vh - 400px) / 2) 0;
  }
}

/* 左 */

.box_inline {
  width: 350px;
  text-align: center;
  background-color: white;
  padding: 30px;
  border-radius: 5px;
  height: 400px;
}

@media (max-width: 1000px) and (min-width: 500px) {
  .box_inline {
    width: 350px;
    margin: 30px auto;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .box_inline {
    width: 90%;
    padding: 30px 5%;
    margin: 0 5%;
  }
}

.box_inline > h2 {
  font-size: 22px;
  color: #222222;
  margin: 0 0 15px;
  color: #AD0200;
}

.input_title {
  font-size: 13px;
  color: #222222;
  text-align: left;
  margin-top: 20px;
  font-weight: bold;
}

.input_input {
  position: relative;
  width: 100%;
  height: 45px;
  margin: 10px 0;
}

.input_input > input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 45px;
  line-height: 45px;
  text-indent: 1em;
  border: 1px solid #EBEBEB;
  background-color: white;
  margin:  0;
  border-radius: 3px;
}

.input_icon {
  position: absolute;
  width: 15px;
  height: 15px;
  color: #222222;
  right: 10px;
  top: 15px;
}

.box_inline > button {
  border: none;
  width: 100%;
  margin: 30px 0 10px;
  height: 40px;
  line-height: 40px;
  text-indent: 1em;
  background-color: #AD0200;
  border-radius: 3px;
  color: white;
  font-weight: bold;
}

.send {
  width: calc(100% - 2px);
  color: #222222;
  font-size: 14px;
  border: 1px solid #192651;
  text-align: center;
  line-height: 30px;
  padding: 30px 0;
  border-radius: 3px;
  margin-top: 30px;
}

.desc {
  font-size: 13px;
  color: #222222;
  text-align: left;
  width: 100%;
  margin: 20px 0 0;
}

.border {
  width: 100%;
  height: 1px;
  background-color: #EBEBEB;
  margin: 30px 0 0;
}

.sns {
  width: 100%;
  margin: 30px 0 0;
  padding: 0 0 20px;
}

.link {
  width: 250px;
  font-size: 13px;
  line-height: 30px;
  border-radius: 3px;
  color: #AD0200;
  font-weight: bold;
  margin: 0 auto;
}

.input_error {
  width: 90%;
  padding: 0 5%;
  text-align: left;
  font-size: 12px;
  line-height: 20px;
  color: #AD0200;
}

.error {
  width: 100%;
  padding: 0 0%;
  text-align: left;
  font-size: 12px;
  line-height: 20px;
  color: #AD0200;
}

/* right */

.right {
  width: 50%;
}

.right > h4 {
  width: 100%;
  font-size: 22px;
  line-height: 50px;
  color: white;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
}

.right > h5 {
  font-size: 26px;
  line-height: 40px;
  color: white;
  font-weight: bold;
  text-align: center;
  width: 100%;
  margin: 10px 0 30px;
}

.right > div {
  width: 100%;
  margin: 0 0;
  height: 300px;
  background-image: url(./../../image/auth/image.png);
  background-repeat: no-repeat;
  background-size: contain;
}


@media (max-width: 1000px) and (min-width: 0px) {
  .right {
    display: none;
  }
}

/* input */


/* .input_title {
  font-size: 13px;
  color: #222222;
  text-align: left;
  margin-top: 30px;
}

.input_input {
  position: relative;
  width: 100%;
  height: 45px;
  margin: 10px 0;
}

.input_input > input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 45px;
  line-height: 45px;
  text-indent: 1em;
  border: 1px solid #EBEBEB;
  background-color: #fafafa;
  margin:  0;
}

.error {
  width: 100%;
  padding: 0 0%;
  text-align: left;
  font-size: 12px;
  line-height: 20px;
  color: #AD0200;
} */


