/* タイトル */

.memorii_recruit_body_edit_area_content_item_title {
  width: 100%;
  display: flex;
  margin-bottom: 10px;
}

.memorii_recruit_body_edit_area_content_item_title > p {
  margin-right: 10px;
  font-size: 15px;
  color: #0F1213;
  line-height: 40px;
  font-weight: bold;
}

/* yes no */

.memorii_recruit_body_edit_area_content_item_yes {
  margin: 10px 30px 10px 0;
  height: 20px;
  color: #AD0200;
  font-weight: bold;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  border-radius: 2px;
}

.memorii_recruit_body_edit_area_content_item_no {
  margin: 10px 30px 10px 0;
  width: 34px;
  height: 20px;
  background-color: #b9b9b9;
  color: white;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  border-radius: 2px;
}

