.works_landing_flow_management_title {
  width: 100%;
  padding: 10px 0 0;
  overflow-x: scroll;
}

.works_landing_flow_management_title > div {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

@media (max-width: 1000px) and (min-width: 0px) {
  .works_landing_flow_management_title > div {
    width: 850px;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .works_landing_flow_management_title > div {
    width: 850px;
  }
}

.works_landing_flow_management_title > div > h4 {
  width: 15%;
  line-height: 40px;
  border-radius: 3px;
  border: 1px solid #EBEBEB;
  text-align: center;
  height: 40px;
  color: #EBEBEB;
  font-size: 18px;
}

@media (max-width: 600px) and (min-width: 500px) {
  .works_landing_flow_management_title > div > h4 {
    font-size: 14px;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .works_landing_flow_management_title > div > h4 {
    width: 130px;
  }
}