

/*  */
.text {
  width: calc(100% - 60px);
  padding: 30px 30px 15px;
  background-color: white;
  margin-bottom: 30px;
  box-shadow: 0px 0px 4px #dfdfdf;
  border-radius: 5px;
}

@media (max-width: 700px) and (min-width: 0px) {
  .text {
    width: calc(100% - 40px);
    padding: 20px 20px 15px;
  }
}



.photos > h5 {
  font-size: 20px;
  color: #222222;
  margin: 0px 0 20px;
}

.photos > div {
  display: flex;
  flex-wrap: wrap;
}

.photos > div > img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin: 0 10px 10px 0;
}

