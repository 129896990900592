.header_title {
  width: 600px;
  padding: 10px 0;
}

@media (max-width: 700px) and (min-width: 0px) {
  .header_title {
    width: 90%;
    padding: 80px 5% 0;
  }
}

.header_title > img {
  width: 80px;
  height: 80px;
  border-radius: 100px;
  margin: 0 calc((100% - 80px) / 2);
  object-fit: cover;
}

.header_title > h2 {
  width: 100%;
  line-height: 40px;
  font-size: 16px;
  color: #222222;
  text-align: center;
  margin: 0 0 50px 0;
}

.header {
  width: 580px;
  padding: 10px 10px 20px;
  background-color: white;
  box-shadow: 0px 0px 4px #dfdfdf;
  border-radius: 5px;
}

@media (max-width: 700px) and (min-width: 0px) {
  .header {
    width: 90%;
    padding: 0px 5% 20px;
  }
}

.header > h1 {
  width: 100%;
  font-size: 18px;
  color: #222222;
  padding: 20px 0;
  text-align: center;
}

@media (max-width: 700px) and (min-width: 0px) {
  .header > h1 {
    text-align: left;
  }
}

/* 画像 */

.header_image {
  width: calc(100% - 20px);
  margin: 20px 10px 0;
}

@media (max-width: 700px) and (min-width: 0px) {
  .header_image {
    width: 100%;
    margin: 0px 0 0;
  }
}


.header_image > img {
  width: 100%;
  height: 350px;
  object-fit: cover;
}

@media (max-width: 700px) and (min-width: 0px) {
  .header_image > img {
    width: 100%;
    height: 250px;
  }
}


.header_image > div {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.header_image > div > img {
  width: calc((100% - 40px) / 4);
  height: 90px;
  margin: 15px 0 0;
  object-fit: cover;
}

@media (max-width: 700px) and (min-width: 0px) {
  .header_image > div > img {
    height: 50px;
  }
}

