
.box {
  position: relative;
  height: 40px;
  width: 100%;
}

.box > input {
  position: absolute;
  height: 40px;
  width: 100%;
  border: none;
  border: 1px solid #d1d1d1;
  font-size: 14px;
  line-height: 35px;
  text-indent: 1em;
  border-radius: 2px;
  z-index: 1;
}

.box > div {
  position: absolute;
  top: 80px;
  width: 100%;
  max-height: 350px;
  overflow-y: scroll;
  background-color: white;
  border: .5px solid #d1d1d1;
  z-index: 100;
}

.box > div > p {
  padding: 10px 10px;
  width: calc(100% - 10px);
  border: .5px solid #EBEBEB;
  color: #222222;
}