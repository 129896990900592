
/* サービス > 店舗情報/求人管理 > 求人：求人 */

.items_table {
  display: flex;
  margin: 20px 25px 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #EBEBEB;
}

.items_table > div {
  display: flex;
}

.items_table > div > h4 {
  margin-right: 20px;
  color: #222222;
  font-size: 15px;
  line-height: 40px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.items_table > p {
  color: #222222;
  font-size: 14px;
  line-height: 40px;
}

.items_table_box_1 {
  width: 220px;
}

.items_table_box_1_img {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  border: .5px solid #EBEBEB;
  object-fit: contain;
}

.items_table_box_post > h2 {
  width: 140px;
  color: #222222;
  font-size: 14px;
  line-height: 25px;
  margin-left: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.items_table_box_post > div {
  display: flex;
  margin-left: 15px;
}

.items_table_box_post > div > div {
  width: 5px;
  height: 5px;
  background-color: #F1AE54;
  border-radius: 100px;
  margin: 5px 0;
}

.items_table_box_post > div > p {
  line-height: 15px;
  color: #222222;
  font-size: 12px;
  margin-left: 5px;
}

.items_table_box_2 {
  width: calc(100% - 700px);
}

.items_table_box_3 {
  width: 150px;
}

.items_table_box_4 {
  width: 250px;
  display: flex;
  justify-content: space-between;
  margin: 0 30px;
}








.items_table_date {
  width: 250px;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
}

.items_table_date > div {
  display: flex;
  width: 60px;
}

.items_table_date > div > p {
  font-size: 15px;
  line-height: 30px;
  color: #222222;
}

.items_table_date_icon {
  color: #222222;
  width: 18px;
  height: 18px;
  margin: 7px 15px 7px 0;
}

/* リスト */

.items_table_list {
  position: relative;
  color: #222222;
  width: 20px;
  height: 20px;
  margin: 5px 0px 5px 0;
}

.items_table_list_icon {
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 0px 0px 0px 0;
}

.items_table_list > div {
  position: absolute;
  left: -70px;
  top: 20px;
  width: 100px;
  margin: 5px 10px 5px 0;
  background-color: white;
  text-align: center;
  border: 1px solid #CDCDCD;
  border-radius: 3px;
  z-index: 1000;
}

.items_table_list > div > p {
  border-bottom: .5px solid #EBEBEB;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  color: #222222;
}