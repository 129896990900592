.save_btn {
  width: 100px;
  height: 30px;
  background-color: #AD0200;
  font-size: 12px;
  color: white;
  border-radius: 3px;
  line-height: 30px;
  font-weight: bold;
  border: none;
  margin: 0 0 0 calc(100% - 100px);
}

.box {
  padding: 0 0 30px;
  width: 410px;
  background-color: white;
  height: 620px;
}

.grid_1 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 17fr 17fr 17fr 23fr 26fr;
  grid-template-rows: 5fr 3fr 8fr 5fr 4fr 5fr 5fr 5fr 3fr 4fr 5fr 5fr 5fr 5fr 5fr 5fr 3fr 4fr 5fr 5fr 5fr;
}

.grid_1_1 {
  grid-column: 1 / 3;
  font-size: 16px;
  font-weight: bold;
  line-height: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_2 {
  width: 100%;
  grid-column: 3 / 5;
  font-size: 12px;
  text-align: right;
  padding: 10px 0 0 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_3 {
  grid-column: 5 / 6;
  grid-row: 1 / 5;
  background-repeat: no-repeat;
  background-size: contain;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_3 > img {
  height: 110px;
  width: 85px;
  margin: calc((130px - 110px) / 2) calc((110px - 85px) / 2);
  object-fit: cover;
}

.grid_1_4 {
  grid-column: 1 / 4;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_5 {
  grid-column: 1 / 4;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  padding-left: 5px;
  font-size: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_6 {
  grid-column: 1 / 4;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_7 {
  grid-column: 4 / 5;
  grid-row: 2 / 3;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  font-size: 12px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_8 {
  grid-column: 4 / 5;
  grid-row: 3 / 4;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  text-align: center;
  font-size: 17px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_9 {
  grid-column: 4 / 5;
  grid-row: 4 / 5;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  font-size: 12px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_10 {
  grid-column: 1 / 6;
  grid-row: 5 / 6;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_11 {
  grid-column: 1 / 6;
  grid-row: 6 / 7;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_12 {
  grid-column: 1 / 6;
  grid-row: 7 / 8;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  font-size: 14px;
  font-size: 12px;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_13 {
  grid-column: 1 / 6;
  grid-row: 8 / 9;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  border-bottom: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_14 {
  grid-column: 1 / 2;
  grid-row: 10 / 11;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_15 {
  grid-column: 2 / 3;
  grid-row: 10 / 11;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_16 {
  grid-column: 3 / 6;
  grid-row: 10 / 11;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_17 {
  grid-column: 1 / 2;
  grid-row: 11 / 12;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_18 {
  grid-column: 2 / 3;
  grid-row: 11 / 12;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_19 {
  grid-column: 3 / 6;
  grid-row: 11 / 12;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  border-right: 1.5px solid #222222;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_20 {
  grid-column: 1 / 2;
  grid-row: 12 / 13;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_21 {
  grid-column: 2 / 3;
  grid-row: 12 / 13;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_22 {
  grid-column: 3 / 6;
  grid-row: 12 / 13;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  border-right: 1.5px solid #222222;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_23 {
  grid-column: 1 / 2;
  grid-row: 13 / 14;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_24 {
  grid-column: 2 / 3;
  grid-row: 13 / 14;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_25 {
  grid-column: 3 / 6;
  grid-row: 13 / 14;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  border-right: 1.5px solid #222222;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_26 {
  grid-column: 1 / 2;
  grid-row: 14 / 15;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_27 {
  grid-column: 2 / 3;
  grid-row: 14 / 15;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_28 {
  grid-column: 3 / 6;
  grid-row: 14 / 15;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  border-right: 1.5px solid #222222;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_29 {
  grid-column: 1 / 2;
  grid-row: 15 / 16;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_30 {
  grid-column: 2 / 3;
  grid-row: 15 / 16;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_31 {
  grid-column: 3 / 6;
  grid-row: 15 / 16;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  border-right: 1.5px solid #222222;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_32 {
  grid-column: 1 / 2;
  grid-row: 16 / 17;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_33 {
  grid-column: 2 / 3;
  grid-row: 16 / 17;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_34 {
  grid-column: 3 / 6;
  grid-row: 16 / 17;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  border-right: 1.5px solid #222222;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_35 {
  grid-column: 1 / 6;
  grid-row: 17 / 18;
  border-top: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_36 {
  grid-column: 1 / 2;
  grid-row: 18 / 19;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_37 {
  grid-column: 2 / 3;
  grid-row: 18 / 19;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_38 {
  grid-column: 3 / 6;
  grid-row: 18 / 19;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_39 {
  grid-column: 1 / 2;
  grid-row: 19 / 20;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_40 {
  grid-column: 2 / 3;
  grid-row: 19 / 20;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_41 {
  grid-column: 3 / 6;
  grid-row: 19 / 20;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_42 {
  grid-column: 1 / 2;
  grid-row: 20 / 21;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_43 {
  grid-column: 2 / 3;
  grid-row: 20 / 21;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_44 {
  grid-column: 3 / 6;
  grid-row: 20 / 21;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_45 {
  grid-column: 1 / 2;
  grid-row: 21 / 22;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-bottom: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_46 {
  grid-column: 2 / 3;
  grid-row: 21 / 22;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-bottom: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_1_47 {
  grid-column: 3 / 6;
  grid-row: 21 / 22;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  border-bottom: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}


.grid_2 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100px 200px 1fr;
  grid-template-rows: 23px 23px 1fr 23px 23px 23px 23px 1fr 23px 210px 23px 210px;
}

.grid_2_1 {
  grid-column: 1 / 4;
  grid-row: 1 / 2;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 20px;
}

.grid_2_2 {
  grid-column: 1 / 4;
  grid-row: 2 / 3;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  border-bottom: 1.5px solid #222222;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 20px;
}

.grid_2_3 {
  grid-column: 1 / 4;
  grid-row: 3 / 4;
  font-size: 12px;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 20px;
}

.grid_2_4 {
  grid-column: 1 / 2;
  grid-row: 4 / 5;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 20px;
}

.grid_2_5 {
  grid-column: 2 / 3;
  grid-row: 4 / 5;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 20px;
}

.grid_2_6 {
  grid-column: 3 / 4;
  grid-row: 4 / 5;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 20px;
}

.grid_2_7 {
  grid-column: 1 / 2;
  grid-row: 5 / 6;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 20px;
}

.grid_2_8 {
  grid-column: 2 / 3;
  grid-row: 5 / 6;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 20px;
}

.grid_2_9 {
  grid-column: 3 / 4;
  grid-row: 5 / 6;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 20px;

}
.grid_2_10 {
  grid-column: 1 / 2;
  grid-row: 6 / 7;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_2_11 {  grid-column: 2 / 3;
  grid-row: 6 / 7;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_2_12 {
  grid-column: 3 / 4;
  grid-row: 6 / 7;  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_2_13 {
  grid-column: 1 / 2;
  grid-row: 7 / 8;
  font-size: 12px;
  border-top: 1.5px solid #222222;  border-left: 1.5px solid #222222;
  border-bottom: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_2_14 {
  grid-column: 2 / 3;
  grid-row: 7 / 8;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-bottom: 1.5px solid #222222;  padding-left: 5px;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_2_15 {
  grid-column: 3 / 4;
  grid-row: 7 / 8;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  border-bottom: 1.5px solid #222222;
  padding-left: 5px;  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_2_16 {
  grid-column: 1 / 4;
  grid-row: 8 / 9;
  font-size: 12px;
  padding-left: 5px;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_2_17 {
  grid-column: 1 / 4;  
  grid-row: 9 / 10;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid_2_18 {  
  grid-column: 1 / 4;
  grid-row: 10 / 11;
  font-size: 11px;
  line-height: 17px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  padding-left: 5px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 12;
  overflow: hidden
}

.grid_2_19 {
  grid-column: 1 / 4;
  grid-row: 11 / 12;  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_2_20 {
  grid-column: 1 / 4;
  grid-row: 12 / 13;
  border-top: 1.5px solid #222222;  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  border-bottom: 1.5px solid #222222;
  padding-left: 5px;
  font-size: 11px;
  line-height: 17px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 12;
  overflow: hidden;
}


.grid_3 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 150px 1fr;  
  grid-template-rows: 30px 1fr 30px 1fr 30px 1fr;
}

.grid_3_1 {
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_3_1 > img {
  width: 100%;  height: 100%;
  object-fit: contain;
}

.grid_3_2 {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;

}

.grid_3_3 {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  padding-left: 5px;
  font-size: 11px;
  line-height: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 9;
  overflow: hidden;
}

.grid_3_4 {
  grid-column: 1 / 2;
  grid-row: 3 / 5;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;

}
.grid_3_4 > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.grid_3_5 {
  grid-column: 2 / 3;
  grid-row: 3 / 4;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  padding-left: 5px;  line-height: 20px;

}

.grid_3_6 {
  grid-column: 2 / 3;
  grid-row: 4 / 5;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  padding-left: 5px;
  font-size: 11px;
  line-height: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 9;
  overflow: hidden;
}

.grid_3_7 {
  grid-column: 1 / 2;
  grid-row: 5 / 7;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-bottom: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_3_7 > img {
  width: 100%;
  height: 100%;  object-fit: contain;
}

.grid_3_8 {
  grid-column: 2 / 3;
  grid-row: 5 / 6;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_3_9 {
  grid-column: 2 / 3;
  grid-row: 6 / 7;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  border-bottom: 1.5px solid #222222;
  padding-left: 5px;
  font-size: 11px;
  line-height: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 9;
  overflow: hidden;
}

.grid_4 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 22px 1fr 22px 1fr 22px 1fr;
}

.grid_4_1 {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_4_2 {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  padding-left: 5px;
  font-size: 10px;
  line-height: 18px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
  overflow: hidden;
}  

.grid_4_3 {
  grid-column: 1 / 2;
  grid-row: 3 / 4;
  border-top: 1.5px solid #222222;
  font-size: 12px;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_4_4 {
  grid-column: 1 / 2;
  grid-row: 4 / 5;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  padding-left: 5px;
  font-size: 10px;
  line-height: 18px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
  overflow: hidden;
}

.grid_4_5 {
  grid-column: 1 / 2;
  grid-row: 5 / 6;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_4_6 {
  grid-column: 1 / 2;
  grid-row: 6 / 7;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  border-bottom: 1.5px solid #222222;
  padding-left: 5px;
  font-size: 10px;
  line-height: 18px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
  overflow: hidden;
}

.grid_5 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 30px 30px 30px 30px 30px 30px 30px 30px 30px 30px;
}

.grid_5_1 {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_5_2 {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
  text-decoration: none;
  color: #222222;
}

.grid_5_3 {
  grid-column: 1 / 2;
  grid-row: 3 / 4;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_5_4 {
  grid-column: 1 / 2;
  grid-row: 4 / 5;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
  text-decoration: none;
  color: #222222;
}

.grid_5_5 {
  grid-column: 1 / 2;
  grid-row: 5 / 6;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_5_6 {
  grid-column: 1 / 2;
  grid-row: 6 / 7;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  border-bottom: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_5_2  > a,
.grid_5_4  > a,
.grid_5_6  > a {
  text-decoration: none;
  color: #AD0200;
}



















