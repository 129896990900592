/* タイトル */

.photo_title {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}

.photo_title > div {
  display: flex;
}

.photo_title > div > h4 {
  font-size: 15px;
  line-height: 35px;
  color: #192651;
  margin-bottom: 10px;
}

.photo_title > button {
  border: none;
  width: 130px;
  height: 35px;
  line-height: 35px;
  background-color: #AD0200;
  color: white;
  border-radius: 3px;
  font-weight: bold;
  margin-left: auto;
}

/* コンテンツ */

.photo_contents {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 50px);
  padding: 25px;
  background-color: white;
  border-radius: 5px;
  margin-bottom: 20px;
}

.photo_contents_box {
  width: 180px;
  height: 180px;
  margin: 0 20px 20px 0;
}

.photo_contents_box > div {
  position: relative;
  width: 180px;
  height: 180px;
  border-radius: 5px;
}

.works_photo_image_box_background {
  position: absolute;
  width: 180px;
  height: 180px;
  background-color: #000000;
  opacity: .2;
  z-index: 1;
  border-radius: 5px;
}

.photo_contents_box_image {
  position: absolute;
  width: 180px;
  height: 180px;
  object-fit: cover;
  border-radius: 5px;
}

.photo_contents_box_data {
  bottom: 10px;
  right: 10px;
  position: absolute;
  color: white;
  display: flex;
  z-index: 10;
}

.photo_contents_box_data_icon {
  width: 16px;
  height: 16px;
  padding: 2px;
  display: flex;
  margin-left: 15px;
}

.photo_contents_box_data > h4 {
  font-size: 13px;
  line-height: 20px;
  color: white;
  margin-left: 10px;
}

/* .works_photo_image_box_make {
  position: relative;
} */



