
.box {
  width: 100%;
  display: flex;
  border-bottom: .5px solid #EBEBEB;
  margin-bottom: 30px;
  justify-content: space-between;
}

.box > div {
  display: flex;
}

.box > div > h4 {
  line-height: 40px;
  border-bottom: 3px solid #F8FBFF;
  margin-right: 30px;
  color: #CCCCCC;
  font-size: 14px;
}

.box > select {
  width: 150px;
  height: 35px;
  font-size: 12px;
  border: 1px solid #EBEBEB;
  color: #222222;
  text-indent: 1em;
}