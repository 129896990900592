
.box {
  width: 95%;
  display: flex;
  margin: 20px 2.5% 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #EBEBEB;
  background-color: white;
  justify-content: space-between;
}

.box > p {
  font-size: 14px;
  line-height: 40px;
}

.box_item_1 {
  display: flex;
  width: 200px;
  height: 40px;
  line-height: 40px;
}

.box_item_1 > img {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  object-fit: cover;
  border: .5px solid #EBEBEB;
}


.box_1_post > h2 {
  width: 140px;
  color: #222222;
  font-size: 14px;
  line-height: 25px;
  margin-left: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.box_1_post > div {
  display: flex;
  margin-left: 15px;
}

.box_1_post > div > div {
  width: 5px;
  height: 5px;
  background-color: #F1AE54;
  border-radius: 100px;
  margin: 5px 0;
}

.box_1_post > div > p {
  line-height: 15px;
  color: #222222;
  font-size: 12px;
  margin-left: 5px;
}

.box_item_2 {
  width: 50px;
  line-height: 40px;
}

.box_item_3 {
  width: 20px;
  height: 20px;
  margin: 0 20px 0 10px;
  padding: 10px 0;
}

.box_item_4 {
  width: 30px;
  height: 20px;
  margin: 0 20px 0 10px;
  padding: 10px 0;
}


/* .works_service_manage_mobile_contents_table_1 {
  width: 220px;
  border: none;
}

.works_service_manage_mobile_contents_table_2 {
  width: 100px;
}

.works_service_manage_mobile_contents_table_6 {
  width: 30px;
  margin-left: auto;
} */




.modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100000000000;
}

.modal > div {
  position: relative;
}

.modal_black {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #222222;
  opacity: .3;
}

.modal_contents {
  position: absolute;
  width: calc(100% - 30px - 50px);
  height: 230px;
  padding: 15px;
  background-color: white;
  margin: calc((100vh - 280px) / 2) calc((100vw - (100% - 50px)) / 2);
  border-radius: 5px;
}

.modal_contents > h4 {
  width: 100%;
  font-size: 16px;
  color: #0F1213;
  line-height: 41px;
  font-weight: bold;
  text-align: center;
}

.modal_contents > p {
  width: 100%;
  margin: 20px auto 0;
  text-align: left;
  font-size: 12px;
  color: #0F1213;
  line-height: 35px;
}

.modal_contents > div {
  display: flex;
  justify-content: space-evenly;
  margin: 25px calc((100% - 240px) / 2) 0;
}

.modal_contents > div > button {
  border: none;
  width: 100px;
  height: 35px;
  line-height: 35px;
  border-radius: 3px;
  border: 1px solid #AD0200;
  margin: 0 10px;
  font-weight: bold;
}
