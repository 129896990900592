
.message_title {
  width: calc(100% - 40px);
  display: flex;
  padding: 10px 20px;
  justify-content: space-between;
}

.message_title > div {
  display: flex;
}

.message_title > div > img {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  object-fit: cover;
}

.message_title > div > h5 {
  font-size: 14px;
  color: #222222;
  line-height: 22px;
  margin-left: 10px;
  line-height: 40px;
}

.message_title > h4 {
  font-size: 14px;
  color: #AD0200;
  line-height: 40px;
}



.message_message {
  width: calc(100% - 40px);
  height: calc(100% - 130px);
  background-color: #fafafa;
  padding: 10px 20px;
  overflow-y: scroll;
}

.message_message_sender {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}

.message_message_sender > p {
  font-size: 10px;
  color: #222222;
  display: flex;
  align-items: flex-end;
  margin-right: 5px;
}

.message_message_sender > h4 {
  padding: 10px 10px;
  max-width: 350px;
  color: #222222;
  background-color: #FFD4D4;
  font-size: 12px;
  font-weight: normal;
  line-height: 22px;
  border-radius: 3px;
}

.message_message_received {
  display: flex;
  margin-bottom: 15px;
}

.message_message_received > img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 100px;
}

.message_message_received > h4 {
  padding: 10px 10px;
  max-width: 350px;
  background-color: white;
  margin: 0 0 0 10px;
  font-size: 12px;
  color: #222222;
  border-radius: 3px;
  font-weight: normal;
  line-height: 22px;
  font-weight: normal;
}

.message_message_received > p {
  font-size: 10px;
  color: #222222;
  display: flex;
  align-items: flex-end;
  margin-left: 5px;
}


.message_input {
  position: relative;
  bottom: 0;
  width: 100%;
  background-color: white;
}

.message_input > div {
  width: calc(100% - 40px);
  position: absolute;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  margin-bottom: -60px;
  background-color: white;
  padding: 10px 20px;
}

.message_input > div > textarea {
  width: calc(100% - 120px);
  border: 1px solid #EBEBEB;
  padding: 5px 10px 0;
  max-height: 200px;
  line-height: 25px;
  height: 35px;
  resize: none;
  border-radius: 3px;
}

.message_input > div > button {
  border: none;
  width: 80px;
  background-color: #AD0200;
  border-radius: 3px;
  color: white;
  font-weight: bold;
}

