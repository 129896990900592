.block {
  width: 100%;
}

.block > h4 {
  font-size: 18px;
  text-align: center;
  background-color: #F5DDDD;
  padding: 5px 0;
  border-radius: 3px;
  margin: 0 0 20px 0;
}

.interview {
  width: 100%;
  /* height: 470px; */
}

@media (max-width: 700px) and (min-width: 0px) {
  .interview {
    width: 100%;
    /* height: 530px; */
  }
}


.slideImg {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.interview > h4 {
  width: 100%;
  font-size: 16px;
  color: #222222;
  line-height: 30px;
  margin: 10px 0 0 0;
}

.interview > h5 {
  font-size: 12px;
  color: #A2A2A2;
  line-height: 20px;
}

.interview > h6 {
  font-size: 16px;
  color: #222222;
  margin: 20px 0 0 0;
}

.interview > p {
  font-size: 13px;
  color: #444444;
  line-height: 20px;
}