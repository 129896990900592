.store {
  width: 900px;
  min-height: 100vh;
  padding: 100px calc((100% - 900px)/ 2) 50px;
  background-color: #F9FBFF;
  display: flex;
  justify-content: space-between;
}

.store_box {
  width: 550px;
  margin: 0 auto;
}

.store_contents {
  width: calc(100% - 100px);
  padding: 50px 50px 20px;
  background-color: white;
  margin: 0px 0 30px;
  border-radius: 5px;
  box-shadow: 0px 0px 2px #c7c7c7;
}

.store_title {
  width: calc(100% - 5px);
  background-color: #FAFAFA;
  border-left: 5px solid #AD0200;
  line-height: 40px;
  font-size: 15px;
  text-indent: 1em;
  margin-bottom: 30px;
}


.store_input_photo {
  display: flex;
  justify-content: space-between;
}

.store_input_photo_title {
  width: 100%;
  display: flex;
  margin-bottom: 10px;
}

.store_input_photo_title > p {
  margin-right: 10px;
  font-size: 15px;
  color: #0F1213;
  line-height: 40px;
  font-weight: bold;
}

.store_input_photo_yes {
  margin: 10px 30px 10px 0;
  height: 20px;
  color: #AD0200;
  font-weight: bold;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  border-radius: 2px;
}

.store_input_btn label{
  display: inline-block;
  border: none;
  width: 100px;
  height: 30px;
  line-height: 30px;
  background-color: #AD0200;
  color: white;
  font-weight: bold;
  font-size: 13px;
  border-radius: 2px;
  text-align: center;
}

.store_input_btn label:hover{
  cursor: pointer;
}

.store_input_btn input[type="file"]{
  display: none;
}

.image_prev {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.desc {
  font-size: 12px;
  line-height: 25px;
  color: #222222;
  margin-bottom: 10px;
}

.image_prev > div {
  position: relative;
  width: 130px;
  height: 130px;
  margin-bottom: 10px;
  margin-right: 20px;
}

.image_prev > div > img {
  position: absolute;
  width: 130px;
  height: 130px;
  object-fit: cover;
}

.image_prev_icon {
  position: absolute;
  background-color: #222222;
  color: white;
  padding: 5px;
  width: 10px;
  height: 10px;
  border-radius: 100px;
  left: 120px;
  top: -10px;
}

.save_box {
  width: 500px;
  margin: 50px auto 0;
  display: flex;
  justify-content: space-evenly;
}

.save_box_now {
  border: none;
  width: 150px;
  height: 45px;
  line-height: 45px;
  border: .5px solid #AD0200;
  border-radius: 3px;
  font-size: 15px;
  color: #AD0200;
  font-weight: bold;
  background-color: white;
}

.save_box_save {
  border: none;
  width: 150px;
  height: 45px;
  line-height: 45px; 
  background-color: #AD0200;
  border-radius: 3px;
  font-size: 15px;
  color: white;
  font-weight: bold;
  box-shadow: 0px 0px 2px #787878;
}





.numberTag {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 50px 0;
}

.numberTag > div {
  margin-right: 20px;
  display: flex;
}

.numberTag > div > input {
  line-height: 40px;
  accent-color: #AD0200;
}

.numberTag > div > p {
  color: #222222;
  line-height: 40px;
  font-size: 14px;
  margin-left: 10px;
}