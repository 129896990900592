
.chat {
  border-bottom: .5px solid #F8F6F7;
  width: 230px;
  display: flex;
  justify-content: space-between;
  padding: 12px 10px;
  height: 45px;
}

.chat > img {
  width: 45px;
  height: 45px;
  border-radius: 100px;
  object-fit: cover;
}

.chat_name {
  width: 165px;
}

.chat_name > h5 {
  width: 165px;
  text-align: left;
  font-size: 14px;
  color: #222222;
  line-height: 22px;
}

.chat_name > p {
  width: 165px;
  text-align: left;
  font-size: 13px;
  color: #222222;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 23px;
}

.chat_badge {
  width: 7px;
  height: 7px;
  background-color: #AD0200;
  margin: 19px 0;
  border-radius: 100px;
}