/* 全体 */

.header {
  /* position: fixed; */
  width: 95%;
  height: 50px;
  display: flex;
  padding: 5px 2.5%;
  justify-content: space-between;
  border-bottom: .5px solid #EBEBEB;
  background-color: white;
  z-index: 100;
}

/* 左側 */

.header_nav {
  display: flex;
}

.header_nav > div {
  width: 40px;
  height: 40px;
  object-fit: contain;
  margin: 5px 10px 5px 0px;
  color: #192651;
  line-height: 40px;
  text-align: center;
  background-color: white;
  font-weight: bold;
}

.header_nav > img {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin: 15px 15px 15px 0;
  color: #192651;
}

.header_nav > h4 {
  font-size: 16px;
  color: #192651;
  line-height: 40px;
  margin: 5px 0;
}

.header_nav_icon {
  width: 15px;
  height: 15px;
  margin: 17.5px;
}

/* 右側 */

.header_link  {
  display: flex;
}

.header_link > div {
  display: flex;
  justify-content: space-between;
}

.works_header_mobile_img {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin: 15px 0;
}

.header_link > div > p {
  line-height: 40px;
  font-size: 16px;
  font-weight: bold;
  padding: 5px 30px 5px 10px;
}

.header_link_account {
  position: relative;
  width: 40px;
}

.header_link_account_info {
  position: absolute;
  display: flex;
  justify-content: space-between;
}

.header_link_account_info > img {
  width: 40px;
  height: 40px;
  object-fit: contain;
  border-radius: 100px;
  margin: 5px 0;
}

.header_link_account_info > p {
  line-height: 40px;
  font-size: 16px;
  font-weight: bold;
  padding: 0 30px 0 10px;
}

/* ナビゲーションポップ */

.header_link_accoun_nav {
  position: absolute;
  width: 120px;
  top: 60px;
  background-color: white;
  border: 1px solid #EBEBEB;
  left: -80px;
  text-align: center;
  border-radius: 3px;
}

.header_link_accoun_nav > div {
  width: 100px;
  display: flex;
  padding: 5px 10px;
}

.header_link_accoun_nav_icon {
  width: 20px;
  height: 20px;
  margin: 2.5px 10px 2.5px 0;
}

.works_header_mobile_link_notice {
  width: 20px;
  height: 20px;
  margin: 15px 20px 15px 0;
  color: #222222;
}

.header_link_accoun_nav > div > p {
  text-align: left;
  width: 100%;
  height: 25px;
  line-height: 25px;
  color: #222222;
  font-size: 12px;
}

.header_link_accoun_nav > div:nth-child(1),
.header_link_accoun_nav > div:nth-child(2),
.header_link_accoun_nav > div:nth-child(3) {
  border-bottom: 1px solid #EBEBEB;
}

