.box {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100000000000;
}

.box > div {
  position: relative;
}

.box_black {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #222222;
  opacity: .3;
}

.box_error {
  position: absolute;
  width: 450px;
  height: 230px;
  padding: 25px;
  background-color: white;
  margin: calc((100vh - 280px) / 2) calc((100vw - 500px) / 2);
  border-radius: 5px;
}

@media (max-width: 500px) and (min-width: 0) {
  .box_error {
    position: absolute;
    width: calc(90vw - 30px);
    height: 230px;
    padding: 25px 15px;
    background-color: white;
    margin: calc((100vh - 280px) / 2) 5vw;
    border-radius: 5px;
  }
}

.box_error > h4 {
  width: 100%;
  font-size: 16px;
  color: #222222;
  line-height: 41px;
  font-weight: bold;
  text-align: center;
}

.box_error > p {
  width: 100%;
  margin: 20px auto 0;
  text-align: left;
  font-size: 12px;
  color: #222222;
  line-height: 35px;
}

.box_error > div {
  display: flex;
  justify-content: space-evenly;
  margin: 25px calc((100% - 240px) / 2) 0;
}

.box_error > div > button {
  border: none;
  width: 100px;
  height: 35px;
  line-height: 35px;
  border-radius: 3px;
  border: 1px solid #AD0200;
  margin: 0 10px;
  font-weight: bold;
  background-color: white;
  color: #AD0200;

}

