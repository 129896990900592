

.bussiness {
  width: 85%;
  padding: 170px 7.5% 100px;
  display: flex;
  justify-content: space-between;
  background-color: #fafafa;
}

.bussiness > * {
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

@media (max-width: 1300px) and (min-width: 1000px) {
  .bussiness {
    width: 90%;
    padding: 170px 5% 100px;
  }
}

@media (max-width: 1100px) and (min-width: 450px) {
  .bussiness {
    width: 90%;
    padding: 170px 5% 100px;
    display: block;
  }
}

@media (max-width: 450px) and (min-width: 0px) {
  .bussiness {
    width: 100%;
    padding: 100px 0 100px;
    display: block;
  }
}

.bussiness_contents {
  position: sticky;
  top: 150px;
  top: 170px;
  height: calc(100vh - 270px);
  background-color: white;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

@media (max-width: 1100px) and (min-width: 0px) {
  .bussiness_contents {
    display: none;
  }
}

.bussiness_contents::-webkit-scrollbar {  
  display: none;
}

.bussiness_contents {
  padding-bottom: 50px;
}

.bussiness_contents > div > h2 {
  width: 250px;
  color: #234566;
  margin: 30px 50px 0;
  font-size: 18px;
  text-align: left;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.bussiness_contents > div > a > h3 {
  width: 240px;
  color: #464646;
  margin: 20px 50px 0;
  font-size: 15px;
  text-align: left;
  padding-left: 10px;
  font-weight: 600;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.bussiness_items {
  width: 720px;
}

.bussiness_items_title > h2 {
  width: calc(100% - 30px);
  height: 55px;
  background-color: #234566;
  color: white;
  text-align: left;
  font-size: 15px;
  line-height: 55px;
  padding-left: 30px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.bussiness_items_title_speace {
  margin-top: 30px;
}

.bussiness_items_item {
  width: calc(100% - 60px);
  display: flex;
  background-color: white;
  margin-top: 40px;
  padding: 20px 30px;
  text-align: left;
}

.bussiness_items_item > img {
  width: 220px;
  height: 130px;
  object-fit: cover;
}

.bussiness_items_item > div > h3 {
  width: calc(720px - 290px);
  font-size: 15px;
  padding: 10px 0 0 20px;
  line-height: 40px;
  color: #464646;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.bussiness_items_item > div > p {
  width: calc(720px - 290px);
  width: auto;
  padding-left: 20px;
  font-size: 15px;
  line-height: 38px;
  color: #464646;
  text-align: left;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}


@media (max-width: 1350px) and (min-width: 1200px) {
  .bussiness_items {
    width: 650px;
  }

  .bussiness_items_item > div > h3 {
    width: calc(650px - 290px);
  }
  
  .bussiness_items_item > div > p {
    width: calc(650px - 290px);
  }
}

@media (max-width: 1200px) and (min-width: 1150px) {
  .bussiness_items {
    width: 650px;
  }

  .bussiness_items_item > div > h3 {
    width: calc(650px - 290px);
  }
  
  .bussiness_items_item > div > p {
    width: calc(650px - 290px);
  }
}

@media (max-width: 1150px) and (min-width: 1100px) {
  .bussiness_items {
    width: 600px;
  }

  .bussiness_items_item > div > h3 {
    width: calc(600px - 290px);
  }
  
  .bussiness_items_item > div > p {
    width: calc(600px - 290px);
  }
}

@media (max-width: 1100px) and (min-width: 700px) {
  .bussiness_items {
    width: 100%;
  }

  .bussiness_items_item > div > h3 {
    width: calc(90vw - 290px);
  }
  
  .bussiness_items_item > div > p {
    width: calc(90vw - 290px);
  }
}

@media (max-width: 700px) and (min-width: 450px) {
  .bussiness_items {
    width: 100%;
  }

  .bussiness_items_item {
    display: block;
  }

  .bussiness_items_item > img {
    width: calc(90vw - 60px);
    height: 280px;
    object-fit: cover;
  }

  .bussiness_items_item > div > h3 {
    width: calc(90vw - 60px);
    padding: 10px 0 0 0px;
  }
  
  .bussiness_items_item > div > p {
    width: calc(90vw - 60px);
    padding: 5px 0 0 0px;
  }
}

@media (max-width: 450px) and (min-width: 0px) {
  .bussiness_items {
    width: 100%;
  }

  .bussiness_items_item {
    width: calc(100vw - 60px);
    display: block;
  }

  .bussiness_items_item > img {
    width: calc(100vw - 60px);
    height: 180px;
    object-fit: cover;
  }

  .bussiness_items_item > div > h3 {
    padding: 10px 0 0 0px;
    width: calc(100vw - 60px);
  }
  
  .bussiness_items_item > div > p {
    padding: 5px 0 0 0px;
    width: calc(100vw - 60px);
  }
}

.business_media_speace {
  height: 200px;
}

.business_media_title {
  width: 1100px;
  height: 50px;
  line-height: 50px;
  margin: 0 calc((100vw - 1100px ) /  2 );
  display: flex;
  border-bottom: 1px solid #222222;
}

@media (max-width: 1100px) and (min-width: 0px) {
  .business_media_title {
    width: 90%;
    height: 50px;
    line-height: 50px;
    margin: 0 5%;
    display: flex;
    border-bottom: 1px solid #222222;
  }
}

.business_media_title > div {
  width: 5px;
  height: 30px;
  background-color: #222222;
  margin: 0 20px 20px 0;
}

.business_media_title > h3 {
  line-height: 30px;
  margin-bottom: 20px;
  color: #222222;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.business_media_box {
  justify-content: space-between;
  flex-wrap: wrap;
  display: flex;
  width: 1100px;
  margin: 100px calc((100vw - 1100px ) /  2 );
}

@media (max-width: 1100px) and (min-width: 700px) {
  .business_media_box {
    width: 700px;
    margin: 100px calc((100vw - 700px) / 2);
  }
}

@media (max-width: 700px) and (min-width: 0px) {
  .business_media_box {
    width: 330px;
    margin: 100px calc((100vw - 330px) / 2);
  }
}

.business_media_box > a {
  width: 330px;
  height: 380px;
}

.business_media_box > a > img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid #222222;
  /* background-image: url(./../../image/media/1.png); */
  background-size: cover;
}

.business_media_box > a > div > p {
  width: 100px;
  height: 20px;
  font-size: 10px;
  background-color: #EE7F54;
  border-radius: 2px;
  text-align: center;
  font-weight: bold;
  line-height: 20px;
  color: white;
  margin: 7px  0 0 223px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.business_media_box > a > h3 {
  width: 300px;
  color: #222222;
  font-size: 17px;
  margin: 15px 15px 0;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.business_media_box > a > h4 {
  width: 300px;
  color: #555555;
  font-size: 12px;
  margin: 5px 15px;
  font-weight: 400;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.business_media_box > a > p {
  width: 300px;
  color: #555555;
  font-size: 12px;
  margin: 10px 15px 0;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}


/* media */

.media {
  width: 85%;
  padding: 170px 7.5% 100px;
  display: flex;
  justify-content: space-between;
  background-color: #EEF2F6;
}

.media > * > p,
.media > * > p > strong,
.media > * > h1,
.media > * > h2,
.media > * > h3,
.media > * > h4,
.media > * > h5,
.media > * > h6,
.media > * > .media_contents_title,
.media > * > a {
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

@media (max-width: 1300px) and (min-width: 1000px) {
  .media {
    width: 90%;
    padding: 170px 5% 100px;
  }
}

@media (max-width: 1000px) and (min-width: 450px) {
  .media {
    width: 90%;
    padding: 170px 5% 100px;
    display: block;
  }
}

@media (max-width: 450px) and (min-width: 0px) {
  .media {
    width: 100%;
    padding: 100px 0 100px;
    display: block;
  }
}

/* 記事トップ */

.media_article {
  width: 750px;
  padding-bottom: 150px;
  background-color: white;
}

@media (max-width: 1350px) and (min-width: 1299px) {
  .media_article {
    width: 650px;
  }
}

@media (max-width: 1200px) and (min-width: 1150px) {
  .media_article {
    width: 650px;
  }
}

@media (max-width: 1150px) and (min-width: 1100px) {
  .media_article {
    width: 600px;
  }
}

@media (max-width: 1100px) and (min-width: 1000px) {
  .media_article {
    width: 550px;
  }
}

@media (max-width: 1000px) and (min-width: 0px) {
  .media_article {
    width: 100%;
  }
}

.media_date {
  padding: 50px 5% 0;
  width: 90%;
  color: #666666;
  font-size: 13px;
  text-align: left;
  font-weight: bold;
}

.media_title {
  text-align: left;
  padding: 20px 0 15px;
  width: 90%;
  color: #444444;
  font-size: 20px;
  border-bottom: 3px solid #006f9e;
  margin: 0px 5% 50px;
}

.media_img {
  width: 100%;
}

.media_title_img {
  width: 90%;
  margin: 0 5%;
}

/* 導入 */

.media_introduction {
  margin: 50px 5%;
  width: 80%;
  padding: 3% calc(5% - 6px);
  text-align: left;
  border: 3px solid #E9973E;
  background-color: #FEF9E7;
  border-radius: 5px;
}

@media (max-width: 450px) and (min-width: 0px) {
  .media_introduction {
    margin: 80px 5% 0;
    width: 80%;
    padding: 3%  calc(5% - 6px);
    text-align: left;
    border: 3px solid #E9973E;
    background-color: #FEF9E7;
    border-radius: 5px;
  }

  .media_introduction > ul {
    text-indent: -1em;
    padding-left: 1em;
    margin-left: 20px;
  }
}

.media_introduction > div {
  display: flex;
}

.media_introduction_icon {
  width: 30px;
  height: 30px;
  color: #E9973E;
}

.media_introduction > div > h3 {
  width: 400px;
  font-size: 18px;
  color: #E9973E;
  line-height: 30px;
  margin-left: 10px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.media_introduction > hr {
  width: 100%;
  margin-top: 20px;
  border-top: 1px dashed #E9973E;
}

.media_introduction > ul {
  width: 90%;
  color: #E9973E;
  list-style-position: inside;
  padding: 20px 0 0 0;
}

.media_introduction > ul > li > span {
  width: 90%;
  padding: 20px 0 0 0;
  color: #444444;
  font-weight: bold;
  line-height: 37px;
  text-indent: -10px;
}

/* 目次 */

.media_contents {
  margin: 80px 5% 0;
  width: 90%;
  text-align: left;
  border: 1px solid #006f9e;
  background-color: #fcfcfc;
}

@media (max-width: 450px) and (min-width: 0px) {
  .media_contents {
    margin: 80px 5% 0;
    width: 90%;
  }
}

.media_contents_title {
  width: 95%;
  padding: 0 0 0 5%;
  height: 60px;
  background-color: #006f9e;
  color: white;
  line-height: 60px;
  font-weight: bold;
}

.media_contents_contents {
  padding: 30px 5%;
}

.media_contents_contents > ol {
  font-size: 15px;
  line-height: 40px;
  color: #464646;
  padding-left: 18px;
}

.media_contents_contents > ol > ul {
  font-size: 15px;
  line-height: 35px;
  color: #464646;
  padding-left: 30px;
}

.media_contents_contents > ol > li > a {
  text-decoration: none;
  color: #464646;
}

.media_contents_contents > ol > ul > li > a {
  text-decoration: none;
  color: #464646;
}

/* タイトル */

.media_text_title > h2 {
  margin: 80px 5% 0;
  width: 84%;
  padding: 0 3%;
  text-align: left;
  height: 60px;
  line-height: 60px;
  background-color: #1086c1;
  color: white;
  font-weight: bold;
  font-size: 16px;
}

@media (max-width: 450px) and (min-width: 0px) {
  .media_text_title > h2 {
    margin: 80px 5% 0;
    width: 84%;
    padding: 0 3%;
  }
}

/* 文章 */

.media_text_text {
  margin: 0px 5% 0;
  width: 90%;
  font-size: 15px;
  line-height: 38px;
  color: #222222;
  text-align: left;
  word-break: break-all;
  /* font-family: 'Noto Sans Japanese'; */
}

.media_text_text > span {
  color: #444444;
  font-size: 15px;
  text-align: left;
  line-height: 23px;
  font-weight: bold;
  padding: 0 3px;
  background: linear-gradient(transparent 60%, #ffdfbb 60%);
}


@media (max-width: 450px) and (min-width: 0px) {
  .media_text_text {
    margin: 0px 5% 0;
    width: 90%;
  }
}

.media_text_text > a {
  text-decoration: none;
  background-color: white;
  margin: 10px 0;
  color: #347abb;
  font-size: 15px;
  line-height: 23px;
  font-weight: bold;
  border-bottom: 1px solid #347abb;
}

.media_text_text_title {    
  margin: 50px 5% 10px;
  width: 85%;
  padding: 5px 2.5% 5px calc(2.5% - 5px);
  font-size: 15px;
  line-height: 30px;
  color: #464646;
  text-align: left;
  border-left: 5px solid #1086c1;
  padding-left: 10px;
  line-height: 40px;
  background-color: #fafafa;
}

@media (max-width: 450px) and (min-width: 0px) {
  .media_text_text_title {
    margin: 0px 5% 0;
    width: 85%;
    padding: 5px 2.5% 5px calc(2.5% - 5px);
    font-size: 15px;
    line-height: 30px;
    color: #464646;
    text-align: left;
    border-left: 5px solid #1086c1;
    padding-left: 10px;
    line-height: 40px;
    background-color: #fafafa;
  }
}

.media_text_text_box {
  margin: 30px 5% 0;
  width: 80%;
  border: .5px solid #666666;
  font-size: 15px;
  line-height: 32px;
  color: #464646;
  text-align: left;
  background-color: #fafafa;
  padding: 15px 5% 15px;
}

@media (max-width: 450px) and (min-width: 0px) {
  .media_text_text_box {
    margin: 30px 5% 0;
    width: 80%;
  }
}

.media_text_text_box_title {
  color: #444444;
  font-size: 15px;
  text-align: left;
  line-height: 23px;
  font-weight: bold;
  padding: 0 3px;
  background: linear-gradient(transparent 60%, #abc0d6 60%);
}

.media_text_text_box_site {
  color: #1086c1;
  font-size: 15px;
  line-height: 23px;
  font-weight: bold;
  float: right;
}

/* 表 */

.media_table {
  margin: 30px 5% 0;
  width: 90%;
  border-collapse: collapse;
  text-align: left;
}

@media (max-width: 450px) and (min-width: 0px) {
  .media_table {
    margin: 30px 5% 0;
    width: 90%;
    border-collapse: collapse;
    text-align: left;
  }
}

th {
  color: white;
  background-color: #056595;
  border: 1.5px solid rgb(24, 66, 109);
}

th {
  border: 1.5px solid rgb(24, 66, 109);
  font-size: 15px;
  font-size: 15px;
  line-height: 35px;
  margin-left: 18px;
  padding: 0 2%;
  color: white;
}

td {
  border: 1.5px solid rgb(24, 66, 109);
  font-size: 15px;
  font-size: 15px;
  margin-left: 18px;
  padding: 3% 10px;
  color: #464646;
}

.media_table_0001_1_1 {
  width: 30%;
  color: white;
}

.media_table_0001_1_2 {
  width: 20%;
  color: white;
}

.media_table_0001_1_3 {
  width: 20%;
  color: white;
}

.media_table_0001_2_1 {
  width: 15%;
}

.media_table_0001_2_2 {
  width: 15%;
}

.media_table_0001_2_3 {
  width: 35%;
}

.media_table_0001_3_1 {
  width: 30%;
}

.media_table_0001_3_2 {
  width: 70%;
}

.media_table_0001_4_1 {
  width: 21%;
}

.media_table_0001_4_2 {
  width: 11%;
}

.media_table_0001_5_1 {
  width: 10%;
}

.media_table_4_o {
  text-align: center;
}

.media_table_4_x {
  text-align: center;
  background-color: #eaeaea;
}

.media_table_box {
  margin: 30px 0 0;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.media_table_0009_5_1 {
  width: 16%;
}

.media_table_0009_5_2 {
  width: 14%;
}


.tooltip {
  position: relative;
  cursor: pointer;
  padding: 0 3px;
  border: 1px solid #c4c4c4;
  color: #1086c1;
  border-radius: 2px;
  margin: 0 3px;
}

.tooltip:hover .tooltip_text {
  opacity: 1;
  visibility: visible;
}

.tooltip_text {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 0%;
  bottom: -35px;
  display: inline-block;
  padding: 5px 10px;
  white-space: nowrap;
  font-size: 10.5px;
  line-height: 2;
  background: #1086c1;
  color: #fff;
  border-radius: 3px;
  transition: 0.3s ease-in;
  box-shadow: 0 1px 2px #d5d5d5;
}

.tooltip_text:before {
  content: '';
  position: absolute;
  top: -13px;
  left: 10%;
  margin-left: -7px;
  border: 7px solid transparent;
  border-bottom: 7px solid #1086c1;
}

@media (max-width: 1000px) and (min-width: 0px) {
  .tooltip_text {
    display: none;
  }
}

.media_topic {
  width: 350px;

}

.media_topic_title {
  width: 86%;
  padding: 15px 7%;
  background-color: #234565;
  color: white;
  font-weight: bold;
  margin-bottom: 30px;
  text-align: left;
}

.media_topic_writer {
  width: 85%;
  padding:  0 7.5% 0px;
  background-color: white;
  margin-bottom: 30px;
}

.media_topic_writer > div {
  width: 100%;
  padding: 30px 0 0;
  background-color: white;
  display: flex;
  margin-bottom: 10px;
}

.media_topic_writer > div > img {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.media_topic_writer > div > h5 {
  line-height: 30px;
  margin-left: 20px;
  font-size: 13px;
  font-weight: 600;
  color: #222222;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.media_topic_writer > h5 {
  padding: 30px 0 0;
  line-height: 30px;
  margin-left: 0px;
  font-size: 13px;
  font-weight: 600;
  color: #222222;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.media_topic_writer > hr {
  width: 100%;
  height: .5px;
  background-color: #666666;
  border: none;
  color: #666666;
}

.media_topic_writer > p  {
  width: 100%;
  color: white;
  padding: 30px 0;
  color: #222222;
  line-height: 25px;
  font-size: 15px;
  text-align: left;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.media_topic_content {
  width: 86%;
  height: 80px;
  padding: 20px 7%;
  background-color: white;
  margin-bottom: 20px;
  display: flex;
}

.media_topic_content > img {
  width: 130px;
  height: 80px;
  object-fit: cover;
}

.media_topic_content > h5 {
  font-size: 15px;
  color: #222222;
  margin-left: 13px;
  text-align: left;
  line-height: 25px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

@media (max-width: 1000px) and (min-width: 0px) {
  .media_topic {
    width: 100%;
    margin-top: 50px;
  }
}

.media_topic_table {
  padding: 30px 5%;
  background-color: white;
  max-height: calc(100vh - 380px);
  overflow-y: scroll;
}

.media_topic_table > ol {
  font-size: 15px;
  line-height: 40px;
  color: #464646;
  padding-left: 18px;
}

.media_topic_table > ol > ul {
  font-size: 15px;
  line-height: 35px;
  color: #464646;
  padding-left: 30px;
}

.media_topic_table > ol > li > a {
  text-decoration: none;
  color: #464646;
}

.media_topic_table > ol > ul > li > a {
  text-decoration: none;
  color: #464646;
}

/* 問い合わせ */

.media_topic_sticky {
  position: sticky;
  top: 150px;
}

.media_topic_contact {
  position: sticky;
  top: 230px;
  width: 100%;
  background-color: white;
  padding: 10px 0 30px;
}

.media_topic_name {
  width: 85%;
  margin: 0 7.5%;
}

.media_topic_name > p {
  width: 100%;
  text-align: left;
  font-weight: bold;
  font-size: 15px;
  color: #222222;
  margin: 20px 0 10px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.media_topic_name > input {
  width: calc(100% - 2px);
  border: none;
  border: 1px solid #DDDDDD;
  background-color: white;
  height: 45px;
  line-height: 45px;
  text-indent: 1em;
}

.media_topic_name > textarea {
  width: calc(100% - 2px - 20px);
  border: none;
  border: 1px solid #DDDDDD;
  background-color: white;
  resize: none;
  height: 100px;
  line-height: 30px;
  padding: 10px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.media_topic_contact > div > button {
  width: calc(85% - 2px);
  height: 40px;
  line-height: 40px;
  border-radius: 2px;
  font-size: 15px;
  background-color: #FEF9E7;
  color: #222222;
  border: none;
  margin-top: 20px;
  font-weight: bold;
  border: 1px solid #CF8329;
}

.media_topic_contact > div > img {
  width: 200px;
  margin-top: 30px;
}

.media_topic_contact > div > h6 {
  text-align: center;
  font-size: 17px;
  margin-top: 30px;
  color: #222222;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

@media (max-width: 1000px) and (min-width: 0px) {
  .media_topic_sticky {
    position: initial;
  }
  
  .media_topic_contact > button {
    position: initial;
  }
}


.media_nav {
  position: fixed;
  width: 80vw;
  height: 85px;
  padding: 0 10vw;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0 2px #d5d5d5;
  background-color: white;
}

.media_nav_icon {
  margin: 10px 0;
  display: flex;
}

.media_nav_icon > img {
  height: 25px;
  margin: 17.5px 0;
}

.media_nav_icon > h2 {
  line-height: 60px;
  color: #222222;
  font-size: 16px;
  font-weight: bold;
  margin-left: 15px;
  white-space: nowrap;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.media_nav_contact {
  border: none;
  font-size: 15px;
  color: #222222;
  font-weight: bold;
  line-height: 60px;
  margin: 10px 0px;
}

.media_nav_contact > p {
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

@media (max-width: 1300px) and (min-width: 1000px) {
  .media_nav {
    width: calc(90% - 100px);
    margin: 30px 5% 0;
  }
}

@media (max-width: 1000px) and (min-width: 450px) {
  .media_nav {
    width: 90%;
    margin: 0;
    padding: 0 5%;
  }
}

@media (max-width: 450px)  {
  .media_nav {
    width: 90%;
    margin: 0px 0% 0;
    padding: 0 5%;
  }
}
