.all {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: .5px solid #EBEBEB;
}

.all_header {
  width: 50px;
  font-size: 14px;
  font-weight: bold;
}

.select {
  height: 40px;
  width: 100%;
  border: none;
  border: 1px solid #d1d1d1;
  font-size: 14px;
  line-height: 35px;
  text-indent: 1em;
  border-radius: 2px;
  color: #222222;
  background-color: white;
  margin-bottom: 20px;
}

.title {
  width: calc(100% - 5px);
  background-color: #FAFAFA;
  border-left: 5px solid #AD0200;
  line-height: 40px;
  font-size: 15px;
  text-indent: 1em;
  margin-bottom: 30px;
}

.table {
  width: 100%;
  padding-bottom: 40px;
}


.items {
  width: 100%;
  display: flex;
  margin: 20px 0 0;
}

.items > img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 100px;
}

.items > p {
  line-height: 40px;
  font-size: 14px;
  margin-left: 15px;
}

.border {
  width: 100%;
  height: 1px;
  background-color: #EBEBEB;
  margin-bottom: 30px;
}

.history {
  border: none;
  background-color: white;
  border-radius: 3px;
  color: #AD0200;
  font-weight: bold;
}





.setting_desc {
  font-size: 14px;
  color: #222222;
  line-height: 30px;
}

.setting_btn {
  border: none;
  width: 150px;
  line-height: 35px;
  background-color: #AD0200;
  color: white;
  font-weight: bold;
  border-radius: 3px;
  margin: 10px 0 20px;
}

.setting_attention {
  font-size: 12px;
  color: #222222;
  line-height: 25px;
}

