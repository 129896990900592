.works_landing_header {
  width: 100vw;
  height: 600px;
  padding: 150px 0 50px;
  background-color: #192651;
  background-image: url(./../../../image/landing/header.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.works_landing_header_badge {
  width: 230px;
  height: 40px;
  border: 1px solid white;
  margin: 0 calc((100% - 230px) / 2);
}

.works_landing_header_badge > p {
  font-size: 14px;
  line-height: 40px;
  font-weight: bold;
  color: white;
  text-align: center;
}

.works_landing_header > h4 {
  font-size: 40px;
  color: white;
  text-align: center;
  margin: 20px 0 0;
}

@media (max-width: 600px) and (min-width: 500px) {
  .works_landing_header > h4 {
    font-size: 33px;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .works_landing_header > h4 {
    font-size: 24px;
  }
}

.works_landing_header_btn {
  width: 400px;
  display: flex;
  justify-content: space-between;
  margin: 30px calc((100% - 400px) / 2) 0;
}

@media (max-width: 500px) and (min-width: 0px) {
  .works_landing_header_btn {
    width: 95%;
    margin: 30px 2.5% 0;
  }
}

.works_landing_header_btn > button:nth-child(1) {
  border: none;
  width: 180px;
  background-color: #AD0200;
  border-radius: 3px;
  color: white;
  line-height: 40px;
  height: 40px;
  font-weight: bold;
}

.works_landing_header_btn > button:nth-child(2) {
  border: none;
  width: 180px;
  background-color: white;
  border-radius: 3px;
  color: #192651;
  line-height: 40px;
  height: 40px;
  font-weight: bold;
}

@media (max-width: 500px) and (min-width: 0px) {
  .works_landing_header_btn > button {
    width: 45%;
  }
}

.works_landing_header_screen {
  margin: 30px calc((100% - 550px) / 2) 0;
  width: 550px;
  height: 300px;
  background-image: url(./../../../image/landing/header_screen.png);
  background-repeat: no-repeat;
  background-size: contain;
}

@media (max-width: 600px) and (min-width: 0px) {
  .works_landing_header_screen {
    width: 95%;
    margin: 30px 2.5% 0;
  }
}




