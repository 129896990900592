.box {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100000000000;
}

.box_black {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #222222;
  opacity: .3;
}

/* .works_modal > div {
  position: relative;
} */

.box_contents {
  position: absolute;
  width: 400px;
  padding: 20px 25px;
  height: 360px;
  background-color: white;
  border-radius: 5px;
  margin: calc((100vh - 400px) / 2) calc((100vw - 450px) / 2);
}

.box_contents > h4 {
  width: 100%;
  text-align: center;
  font-size: 16px;
  color: #0F1213;
  line-height: 41px;
  font-weight: bold;
  margin-bottom: 20px;
}

.box_contents > textarea {
  width: calc(100% - 20px);
  height: 200px;
  padding: 10px;
  background-color: #FAFAFA;
  border: .5px solid #EBEBEB;
  font-size: 14px;
  line-height: 25px;
  color: #222222;
}

.box_contents > div {
  display: flex;
  justify-content: space-evenly;
  margin: 25px calc((100% - 240px) / 2) 0;
}

.box_contents > div > button {
  border: none;
  width: 100px;
  height: 35px;
  line-height: 35px;
  border-radius: 3px;
  border: 1px solid #AD0200;
  margin: 0 10px;
  font-weight: bold;
}
