.store {
  width: 560px;
  padding: 0 20px 30px;
  margin: 0 0 20px;
  background-color: white;
  border-radius: 10px;
}

@media (max-width: 700px) and (min-width: 0px) {
  .store {
    width: 90%;
    padding: 0 5% 30px;
  }  
}

.store > img {
  width: 560px;
  height: 300px;
  margin: 20px 0 0;
  object-fit: cover;
  border-radius: 10px;
}

@media (max-width: 700px) and (min-width: 0px) {
  .store > img {
    width: 100%;
    height: 250px;
    margin: 20px 0 0;
  }
}

.store > h4 {
  font-size: 18px;
  color: #222222;
  line-height: 35px;
  margin: 20px 0 0;
}

.store > h5 {
  font-size: 12px;
  color: #222222;
  line-height: 25px;
}

.store > h6 {
  font-size: 12px;
  color: #222222;
  line-height: 25px;
}

.title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: white;
  margin: 40px 0 0;
}

.title_bar {
  width: 48%;
  height: 45px;
  line-height: 45px;
  text-align: center;
  font-size: 14px;
  border: 1px solid white;
  border-bottom: 1px solid #AD0200;
  color: white;
  background-color: #AD0200;
}
