
.text_pentagon {
  /* width: calc(100% - 60px); */
  background-color: white;
  margin-bottom: 30px;
  margin: 10px calc((100% - 280px) / 2) 0;
}

@media (max-width: 700px) and (min-width: 0px) {
  .text_pentagon {
    width: calc(100% - 40px);
    padding: 20px 20px 15px;
    flex-flow: column;
  }
}

.store_info_right {
  width: 45%;
}

@media (max-width: 700px) and (min-width: 0px) {
  .store_info_right {
    width: 280px;
    margin: 0 calc((100% - 280px) / 2);
  }
}

.text_pentagon > h4 {
  font-size: 14px;
  line-height: 30px;
  color: #222222;
  text-align: center;
  background-color: #F5DDDD;
  padding: 5px 0;
  border-radius: 3px;
  margin: 30px 0 0;
}


.pentagon_back {
  width: 200px;
  height: 200px;
  background-image: url(./../../../../image/recruit/pentagonBack_treat.png);
  background-repeat: no-repeat;
  background-size: contain;
  padding: 40px;
} 

.pentagon_treat_back {
  width: 200px;
  height: 200px;
  background-image: url(./../../../../image/recruit/pentagonBack_style.png);
  background-repeat: no-repeat;
  background-size: contain;
  padding: 40px;
}

.pentagon {
  width: 200px;
  height: 200px;
  margin: 0;
  background-image: url(./../../../../image/recruit/pentagon.png);
  background-repeat: no-repeat;
  background-size: contain;
}

.triangle {
  position: relative;
  width: 50%;
  height: 50%;
  left: 100px;
  margin-bottom: 200px;
}

.triangle > h4 {
  top: -30px;
  left: -50px;
  position: absolute;
  width: 100px;
  text-align: center;
  font-size: 12px;
}

.triangle1 {
  top: 0;
  left: 0;
  position: absolute;
  background: #E97D7D;
  height: 100%;
  width: 100%;
  transform-origin: left bottom;
  transform: rotate(0deg);
  opacity: .4;
}

.triangle2 {
  position: absolute;
  background: #E97D7D;
  height: 100%;
  width: 100%;
  /* clip-path: polygon(0 0, 0 100%, 95.1% 69.0%); */
  transform-origin: left bottom;
  transform: rotate(60deg);
  opacity: .4;
}

.triangle3 {
  position: absolute;
  background: #E97D7D;
  height: 100%;
  width: 100%;
  /* clip-path: polygon(0 0, 0 100%, 95.1% 69.0%); */
  transform-origin: left bottom;
  transform: rotate(120deg);
  opacity: .4;
}

.triangle4 {
  position: absolute;
  background: #E97D7D;
  height: 100%;
  width: 100%;
  /* clip-path: polygon(0 0, 0 100%, 95.1% 69.0%); */
  transform-origin: left bottom;
  transform: rotate(180deg);
  opacity: .4;
}

.triangle5 {
  position: absolute;
  background: #E97D7D;
  height: 100%;
  width: 100%;
  /* clip-path: polygon(0 0, 0 100%, 95.1% 69.0%); */
  transform-origin: left bottom;
  transform: rotate(240deg);
  opacity: .4;
}


.triangle6 {
  position: absolute;
  background: #E97D7D;
  height: 100%;
  width: 100%;
  /* clip-path: polygon(0 0, 0 100%, 95.1% 69.0%); */
  transform-origin: left bottom;
  transform: rotate(300deg);
  opacity: .4;
}


.triangleBorder1 {
  top: 0;
  left: 0;
  position: absolute;
  background: #E97D7D;
  height: 100%;
  width: 100%;
  transform-origin: left bottom;
  transform: rotate(0deg);
}

.triangleBorder2 {
  top: 0;
  left: 0;
  position: absolute;
  background: #E97D7D;
  height: 100%;
  width: 100%;
  transform-origin: left bottom;
  transform: rotate(60deg);
}

.triangleBorder3 {
  top: 0;
  left: 0;
  position: absolute;
  background: #E97D7D;
  height: 100%;
  width: 100%;
  transform-origin: left bottom;
  transform: rotate(120deg);
}

.triangleBorder4 {
  top: 0;
  left: 0;
  position: absolute;
  background: #E97D7D;
  height: 100%;
  width: 100%;
  transform-origin: left bottom;
  transform: rotate(180deg);
}

.triangleBorder5 {
  top: 0;
  left: 0;
  position: absolute;
  background: #E97D7D;
  height: 100%;
  width: 100%;
  transform-origin: left bottom;
  transform: rotate(240deg);
}

.triangleBorder6 {
  top: 0;
  left: 0;
  position: absolute;
  background: #E97D7D;
  height: 100%;
  width: 100%;
  transform-origin: left bottom;
  transform: rotate(300deg);
}

.triangleDot1 {
  position: absolute;
  height: 100%;
  width: 100%;
  transform-origin: left bottom;
  transform: rotate(0deg);
  z-index: 10;
}

.triangleDot1 > div {
  background: #c63737;
  height: 8px;
  width: 8px;
  border-radius: 100px;
}

.triangleDot2 {
  position: absolute;
  height: 100%;
  width: 100%;
  transform-origin: left bottom;
  transform: rotate(60deg);
  z-index: 10;
}

.triangleDot2 > div {
  background: #c63737;
  height: 8px;
  width: 8px;
  border-radius: 100px;
}

.triangleDot3 {
  position: absolute;
  height: 100%;
  width: 100%;
  transform-origin: left bottom;
  transform: rotate(120deg);
  z-index: 10;
}

.triangleDot3 > div {
  background: #c63737;
  height: 8px;
  width: 8px;
  border-radius: 100px;
}

.triangleDot4 {
  position: absolute;
  height: 100%;
  width: 100%;
  transform-origin: left bottom;
  transform: rotate(180deg);
  z-index: 10;
}

.triangleDot4 > div {
  background: #c63737;
  height: 8px;
  width: 8px;
  border-radius: 100px;
}

.triangleDot5 {
  position: absolute;
  height: 100%;
  width: 100%;
  transform-origin: left bottom;
  transform: rotate(240deg);
  z-index: 10;
}

.triangleDot5 > div {
  background: #c63737;
  height: 8px;
  width: 8px;
  border-radius: 100px;
}

.triangleDot6 {
  position: absolute;
  height: 100%;
  width: 100%;
  transform-origin: left bottom;
  transform: rotate(300deg);
  z-index: 10;
}

.triangleDot6 > div {
  background: #c63737;
  height: 8px;
  width: 8px;
  border-radius: 100px;
}
