.box {
  padding: 0 0 30px;
  width: 410px;
  height: calc((80vh - 2px) - 40px);
  background-color: white;
  height: 620px;
}

.grid_1 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 17fr 17fr 17fr 23fr 26fr;
  grid-template-rows: 5fr 3fr 8fr 5fr 4fr 5fr 5fr 5fr 3fr 4fr 5fr 5fr 5fr 5fr 5fr 5fr 3fr 4fr 5fr 5fr 5fr;
}

.grid_1_1 {
  grid-column: 1 / 3;
  font-size: 16px;
  font-weight: bold;
  line-height: 100%;
}

.grid_1_2 {
  width: 100%;
  grid-column: 3 / 5;
  font-size: 12px;
  text-align: right;
  padding: 10px 0 0 0;
}

.grid_1_3 {
  grid-column: 5 / 6;
  grid-row: 1 / 5;
  background-repeat: no-repeat;
  background-size: contain;
}

.grid_1_3 > img {
  height: 110px;
  width: 85px;
  margin: calc((130px - 110px) / 2) calc((110px - 85px) / 2);
  object-fit: cover;
}

.grid_1_4 {
  grid-column: 1 / 4;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  padding-left: 5px;
}

.grid_1_5 {
  grid-column: 1 / 4;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  padding-left: 5px;
  font-size: 15px;
}

.grid_1_6 {
  grid-column: 1 / 4;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
}

.grid_1_7 {
  grid-column: 4 / 5;
  grid-row: 2 / 3;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  font-size: 12px;
  text-align: center;
}

.grid_1_8 {
  grid-column: 4 / 5;
  grid-row: 3 / 4;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  text-align: center;
  font-size: 17px;
}

.grid_1_9 {
  grid-column: 4 / 5;
  grid-row: 4 / 5;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  font-size: 12px;
  text-align: center;
}

.grid_1_10 {
  grid-column: 1 / 6;
  grid-row: 5 / 6;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
}

.grid_1_11 {
  grid-column: 1 / 6;
  grid-row: 6 / 7;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
}

.grid_1_12 {
  grid-column: 1 / 6;
  grid-row: 7 / 8;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  font-size: 14px;
  font-size: 12px;
  padding-left: 5px;
}

.grid_1_13 {
  grid-column: 1 / 6;
  grid-row: 8 / 9;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  border-bottom: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
}

.grid_1_14 {
  grid-column: 1 / 2;
  grid-row: 10 / 11;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
}

.grid_1_15 {
  grid-column: 2 / 3;
  grid-row: 10 / 11;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
}

.grid_1_16 {
  grid-column: 3 / 6;
  grid-row: 10 / 11;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
}

.grid_1_17 {
  grid-column: 1 / 2;
  grid-row: 11 / 12;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
}

.grid_1_18 {
  grid-column: 2 / 3;
  grid-row: 11 / 12;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
}

.grid_1_19 {
  grid-column: 3 / 6;
  grid-row: 11 / 12;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  border-right: 1.5px solid #222222;
}

.grid_1_20 {
  grid-column: 1 / 2;
  grid-row: 12 / 13;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
}

.grid_1_21 {
  grid-column: 2 / 3;
  grid-row: 12 / 13;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
}

.grid_1_22 {
  grid-column: 3 / 6;
  grid-row: 12 / 13;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  border-right: 1.5px solid #222222;
}

.grid_1_23 {
  grid-column: 1 / 2;
  grid-row: 13 / 14;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
}

.grid_1_24 {
  grid-column: 2 / 3;
  grid-row: 13 / 14;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
}

.grid_1_25 {
  grid-column: 3 / 6;
  grid-row: 13 / 14;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  border-right: 1.5px solid #222222;
}

.grid_1_26 {
  grid-column: 1 / 2;
  grid-row: 14 / 15;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
}

.grid_1_27 {
  grid-column: 2 / 3;
  grid-row: 14 / 15;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
}

.grid_1_28 {
  grid-column: 3 / 6;
  grid-row: 14 / 15;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  border-right: 1.5px solid #222222;
}

.grid_1_29 {
  grid-column: 1 / 2;
  grid-row: 15 / 16;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
}

.grid_1_30 {
  grid-column: 2 / 3;
  grid-row: 15 / 16;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
}

.grid_1_31 {
  grid-column: 3 / 6;
  grid-row: 15 / 16;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  border-right: 1.5px solid #222222;
}

.grid_1_32 {
  grid-column: 1 / 2;
  grid-row: 16 / 17;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
}

.grid_1_33 {
  grid-column: 2 / 3;
  grid-row: 16 / 17;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
}

.grid_1_34 {
  grid-column: 3 / 6;
  grid-row: 16 / 17;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
  border-right: 1.5px solid #222222;
}

.grid_1_35 {
  grid-column: 1 / 6;
  grid-row: 17 / 18;
  border-top: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
}

.grid_1_36 {
  grid-column: 1 / 2;
  grid-row: 18 / 19;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
}

.grid_1_37 {
  grid-column: 2 / 3;
  grid-row: 18 / 19;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
}

.grid_1_38 {
  grid-column: 3 / 6;
  grid-row: 18 / 19;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
}

.grid_1_39 {
  grid-column: 1 / 2;
  grid-row: 19 / 20;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
}

.grid_1_40 {
  grid-column: 2 / 3;
  grid-row: 19 / 20;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
}

.grid_1_41 {
  grid-column: 3 / 6;
  grid-row: 19 / 20;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
}

.grid_1_42 {
  grid-column: 1 / 2;
  grid-row: 20 / 21;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
}

.grid_1_43 {
  grid-column: 2 / 3;
  grid-row: 20 / 21;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
}

.grid_1_44 {
  grid-column: 3 / 6;
  grid-row: 20 / 21;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
}

.grid_1_45 {
  grid-column: 1 / 2;
  grid-row: 21 / 22;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-bottom: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
}

.grid_1_46 {
  grid-column: 2 / 3;
  grid-row: 21 / 22;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-bottom: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
}

.grid_1_47 {
  grid-column: 3 / 6;
  grid-row: 21 / 22;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  border-bottom: 1.5px solid #222222;
  font-size: 12px;
  padding-left: 5px;
}

.memorii_recruit_manage_fied_flex {
  position: absolute;
  margin: 0 30px 0 calc((100% - 266px) - ((80vh - 2px) * 0.715 + 40px));
  width: calc((80vh * 0.715) - 2px);
  height: 80vh;
  padding: 10vh 20px;
  z-index: 1000;
  background-color: #FAFAFA;
  -webkit-box-shadow: 0 0 2px 0 #D1D1d1;
  box-shadow: 0 0 2px 0 #D1D1d1;
}

.grid_2 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100px 200px 1fr;
  grid-template-rows: 30px 30px 1fr 30px 30px 30px 30px 1fr 30px 180px 30px 180px;
}

.grid_2_1 {
  grid-column: 1 / 4;
  grid-row: 1 / 2;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_2_2 {
  grid-column: 1 / 4;
  grid-row: 2 / 3;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  border-bottom: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_2_3 {
  grid-column: 1 / 4;
  grid-row: 3 / 4;
  font-size: 12px;
  padding-left: 5px;
  line-height: 20px;
}

.grid_2_4 {
  grid-column: 1 / 2;
  grid-row: 4 / 5;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_2_5 {
  grid-column: 2 / 3;
  grid-row: 4 / 5;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_2_6 {
  grid-column: 3 / 4;
  grid-row: 4 / 5;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_2_7 {
  grid-column: 1 / 2;
  grid-row: 5 / 6;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_2_8 {
  grid-column: 2 / 3;
  grid-row: 5 / 6;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_2_9 {
  grid-column: 3 / 4;
  grid-row: 5 / 6;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_2_10 {
  grid-column: 1 / 2;
  grid-row: 6 / 7;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_2_11 {
  grid-column: 2 / 3;
  grid-row: 6 / 7;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_2_12 {
  grid-column: 3 / 4;
  grid-row: 6 / 7;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_2_13 {
  grid-column: 1 / 2;
  grid-row: 7 / 8;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-bottom: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_2_14 {
  grid-column: 2 / 3;
  grid-row: 7 / 8;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-bottom: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_2_15 {
  grid-column: 3 / 4;
  grid-row: 7 / 8;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  border-bottom: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_2_16 {
  grid-column: 1 / 4;
  grid-row: 8 / 9;
  font-size: 12px;
  padding-left: 5px;
  line-height: 20px;
}

.grid_2_17 {
  grid-column: 1 / 4;
  grid-row: 9 / 10;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_2_18 {
  grid-column: 1 / 4;
  grid-row: 10 / 11;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.grid_2_19 {
  grid-column: 1 / 4;
  grid-row: 11 / 12;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_2_20 {
  grid-column: 1 / 4;
  grid-row: 12 / 13;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  border-bottom: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}


.grid_3 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 150px 1fr;
  grid-template-rows: 30px 1fr 30px 1fr 30px 1fr;
}

.grid_3_1 {
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_3_1 > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.grid_3_2 {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_3_3 {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_3_4 {
  grid-column: 1 / 2;
  grid-row: 3 / 5;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_3_4 > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.grid_3_5 {
  grid-column: 2 / 3;
  grid-row: 3 / 4;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_3_6 {
  grid-column: 2 / 3;
  grid-row: 4 / 5;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_3_7 {
  grid-column: 1 / 2;
  grid-row: 5 / 7;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-bottom: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_3_7 > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.grid_3_8 {
  grid-column: 2 / 3;
  grid-row: 5 / 6;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_3_9 {
  grid-column: 2 / 3;
  grid-row: 6 / 7;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  border-bottom: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_4 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 30px 1fr 30px 1fr 30px 1fr 30px 1fr 30px 1fr;
}

.grid_4_1 {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_4_2 {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}  

.grid_4_3 {
  grid-column: 1 / 2;
  grid-row: 3 / 4;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_4_4 {
  grid-column: 1 / 2;
  grid-row: 4 / 5;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.grid_4_5 {
  grid-column: 1 / 2;
  grid-row: 5 / 6;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_4_6 {
  grid-column: 1 / 2;
  grid-row: 6 / 7;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.grid_4_7 {
  grid-column: 1 / 2;
  grid-row: 7 / 8;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_4_8 {
  grid-column: 1 / 2;
  grid-row: 8 / 9;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.grid_4_9 {
  grid-column: 1 / 2;
  grid-row: 9 / 10;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
}

.grid_4_10 {
  grid-column: 1 / 2;
  grid-row: 10 / 11;
  font-size: 12px;
  border-top: 1.5px solid #222222;
  border-left: 1.5px solid #222222;
  border-right: 1.5px solid #222222;
  border-bottom: 1.5px solid #222222;
  padding-left: 5px;
  line-height: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}