
.modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100000000000;
}

.modal > div {
  position: relative;
}

.modal_black {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #222222;
  opacity: .3;
}

.modal_contents {
  top: 0;
  position: absolute;
  width: 650px;
  height: 340px;
  padding: 25px;
  background-color: white;
  margin: calc((100vh - 300px) / 2) calc((100vw - 700px) / 2);
  border-radius: 5px;
}


.modal_contents_title {
  display: flex;
  justify-content: space-between;
}

.modal_contents_title > h4 {
  font-size: 16px;
  color: #222222;
  line-height: 41px;
  font-weight: bold;
}

.modal_contents_title > div > button {
  border: none;
  width: 80px;
  height: 35px;
  line-height: 35px;
  background-color: #AD0200;
  border-radius: 3px;
  color: white;
  font-weight: bold;
}

.modal_contents_title > div > button:nth-child(1) {
  border: none;
  width: 80px;
  height: 34px;
  line-height: 34px;
  background-color: white;
  border-radius: 3px;
  color: #AD0200;
  font-weight: bold;
  border: 1px solid #AD0200;
  margin-right: 10px;
}


.modal_contents_title {
  display: flex;
  justify-content: space-between;
}

.modal_contents_title > h4 {
  font-size: 16px;
  color: #222222;
  line-height: 41px;
  font-weight: bold;
}

.modal_contents_title > button {
  border: none;
  width: 80px;
  height: 35px;
  line-height: 35px;
  background-color: #AD0200;
  border-radius: 3px;
  color: white;
  font-weight: bold;
}

.desc {
  font-size: 12px;
  line-height: 25px;
  color: #222222;
  margin-bottom: 10px;
}


/* 画像添付後 */

.drop_area {
  width: 100%;
  height: 222px;
  background-size: 20px 1px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.drop_area > img {
  width: 222px;
  height: 222px;
  border: .5px solid #222222;
  object-fit: contain;
}

.drop_area_select {
  width: 350px;
  height: 222px;
}

.drop_area_select > h5 {
  height: 40px;
  width: 100%;
  border: none;
  border: 1px solid #d1d1d1;
  font-size: 14px;
  line-height: 40px;
  text-indent: 1em;
  border-radius: 2px;
  color: #222222;
}

.drop_area_select > p {
  width: 100%;
  font-size: 12px;
  line-height: 30px;
  border-radius: 2px;
  color: #222222;
}

.select {
  height: 40px;
  width: 100%;
  border: none;
  border: 1px solid #d1d1d1;
  font-size: 14px;
  line-height: 35px;
  text-indent: 1em;
  border-radius: 2px;
  color: #222222;
  background-color: white;
}



