
.point {
  margin: 50px 0;
  width: calc(100% - 60px);
  padding: 30px;
  text-align: left;
  border: 3px solid #AD0200;
  background-color: white;
  border-radius: 5px;
}

@media (max-width: 700px) and (min-width: 0px) {
  .point {
    width: calc(90% - 10px);
    padding: 20px 5%;
    text-align: left;
  }
}

.point > div {
  display: flex;
}

.point_icon {
  width: 30px;
  height: 30px;
  color: #AD0200;
}

.point > div > h3 {
  width: calc(100% - 40px);
  font-size: 18px;
  color: #AD0200;
  line-height: 30px;
  margin-left: 10px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.point > hr {
  width: 100%;
  margin-top: 20px;
  border-top: 1px dashed #AD0200;
}

.point > ul {
  width: 100%;
  color: #AD0200;
  list-style-position: inside;
  padding: 20px 0 0 0;
}

.point > ul > li > span {
  width: 90%;
  padding: 20px 0 0 0;
  color: #444444;
  font-weight: bold;
  line-height: 37px;
  text-indent: -10px;
}