/* ホーム：progress */

.home_progress {
  width: 100%;
  display: flex;
  border-bottom: .5px solid #EBEBEB;
  margin-bottom: 30px;
  justify-content: space-between;
}

.home_progress > div {
  display: flex;
}

.home_progress > div > h4 {
  line-height: 40px;
  border-bottom: 3px solid #F8FBFF;
  margin-right: 30px;
  color: #CCCCCC;
  font-size: 14px;
}

.home_progress > button {
  border: none;
  line-height: 30px;
  font-size: 14px;
  padding: 0 25px;
  background-color: #AD0200;
  margin: 5px 0;
  color: white;
  font-weight: bold;
  border-radius: 3px;
}

/* ホーム：アナリティクス */

.analytics {
  width: 100%;
  padding-bottom: 50px;
}

/* ホーム：アナリティクス：タイトル */

.analytics_title {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.analytics_title > h4 {
  font-size: 16px;
  color: #222222;
  line-height: 35px;
}

.analytics_title > div {
  width: 270px;
  display: flex;
  justify-content: space-between;
}

.analytics_tabs {
  display: flex;
}

.analytics_tabs > div {
  width: 8px;
  height: 8px;
  margin: calc(13.5px);
  border-radius: 100px;
}

.analytics_tabs > p {
  width: 60px;
  font-size: 14px;
  color: #222222;
  line-height: 35px;
}

.analytics_date {
  position: relative;
  width: 100%;
  background-color: white;
  border: 1px solid #EBEBEB;
  border-radius: 5px;
  height: 380px;
}

/* ホーム：アナリティクス：単位 */

.analytics_date_unit {
  top: 0;
  height: 340px;
  margin: 0 0 0 15px;
  padding-top: 30px;
  width: 25px;
  text-align: right;
}

.analytics_date_unit > p {
  color: #222222;
  font-size: 12px;
  font-weight: bold;
  margin-top: 8px;
}

.analytics_date_unit > div {
  height: 300px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-flow:  column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.analytics_date_unit > div > p {
  color: #222222;
  font-size: 14px;
  font-weight: bold;
}

/* ホーム：アナリティクス：グラフ */

.analytics_date_graph {
  position: absolute;
  top: 0;
  margin: 30px 0 0 70px;
  width: calc(100% - 100px);
}

.analytics_date_graph_year {
  width: calc(100% - 50px);
  height: 300px;
  border-left: .5px solid #EBEBEB;
  border-bottom: .5px solid #EBEBEB;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.analytics_date_graph_year > div {
  width: 15px;
  margin: 0 5px;
}

.analytics_date_graph_week {
  width: calc(100% - 50px);
  height: 300px;
  border-left: .5px solid #EBEBEB;
  border-bottom: .5px solid #EBEBEB;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.analytics_date_graph_week > div {
  width: 15px;
  margin: 0 5px;
}

/* ホーム：アナリティクス：メモリ */

.analytics_date_graph_year_bottom {
  width: calc(100% - 50px);
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  margin-left: .5px;
}

.analytics_date_graph_year_bottom > p {
  width: 25px;
  color: #222222;
  font-size: 10px;
  text-align: center;
  font-weight: bold;
}

.analytics_date_graph_week_bottom {
  width: calc(100% - 50px);
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  margin-left: .5px;
}

.analytics_date_graph_week_bottom > p {
  width: 25px;
  color: #222222;
  font-size: 10px;
  margin-top: 10px;
  text-align: center;
  font-weight: bold;
}

