.works_service_mobile {
  width: 95%;
  min-height: 100vh;
  padding: 0 2.5%;
  background-color: #F9FBFF;
}



.contents {
  background-color: white;
  box-shadow: 0px 0px 2px #d1d1d1;
  padding: 0 0 10px 0;
}

.contents_title {
  width: 90%;
  margin: 0px 0 0px;
  padding-bottom: 5px;
  border-bottom: 1px solid #EBEBEB;
  background-color: #FAFAFA;
  padding: 10px 5%;
  border-radius: 5px 5px 0 0;
  display: flex;
  justify-content: space-between;
}

.contents_title > h4 {
  font-size: 14px;
  line-height: 30px;
  color: #222222;
}

.contents_task {
  width: 100%;
  height: 100%;
  border-right: 1px solid #EBEBEB;
}

.contents_task > div > p {
  color: #222222;
  font-weight: bold;
  width: 90%;
  margin: 20px 5%;
  font-size: 14px;
  line-height: 30px;
}

.contents_task > div > h4 {
  color: #222222;
  font-weight: bold;
  width: calc(90% - 34px);
  margin: 20px 5%;
  border-left: 4px solid #D14F60;
  box-shadow: 0px 0px 2px #d1d1d1;
  border-radius: 3px;
  padding: 15px 15px;
  font-size: 14px;
}

