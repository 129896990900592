/* wide */

.works_nav_pc {
  position: fixed;
  top: 0px;
  height: 100vh;
  padding: 10px 10px;
  border-right: 1px solid #EBEBEB;
  z-index: 1000;
  background-color: white;
}

/* ロゴ */

.works_nav_pc_logo {
  width: calc(100% - 20px);
  height: 40px;
  display: flex;
  background-color: white;
  margin-bottom: 30px;
  padding: 5px 10px;
}

.works_nav_pc_logo > img {
  width: 25px;
  height: 25px;
  object-fit: contain;
  margin: 7.5px 2.5px 7.5px 2.5px;
}

.works_nav_pc_logo > h1 {
  line-height: 40px;
  font-size: 16px;
  font-weight: bold;
  padding-left: 20px;
  color: #273B4A;
}

/* ナビゲーション */

.works_nav_pc_item {
  display: flex;
  height: 40px;
  width: calc(100% - 10px);
  border-radius: 5px;
  margin: 15px 5px;
}

.works_nav_pc_item_icon {
  width: 23px;
  height: 23px;
  padding: 8.5px;
  color: #CCCCCC;
}

.works_nav_pc_item > p {
  width: 150px;
  text-align: left;
  line-height: 40px;
  font-size: 15px;
  color: #CCCCCC;
}

.works_nav_pc_toggle {
  position: absolute;
  bottom: 0;
  width: calc(100% - 20px);
  height: 40px;
  border-top: .5px solid #EBEBEB;
  margin: 0 0 40px 0;
  text-align: right;
}

.works_nav_pc_toggle_icon {
  width: 20px;
  height: 20px;
  padding: 15px;
  color: #222222;
}