/* 全体 */

.box {
  position: fixed;
  width: calc(100% - 60px);
  height: 50px;
  padding: 10px 30px 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: .5px solid #EBEBEB;
  background-color: white;
  z-index: 1;
}

@media (max-width: 500px) and (min-width: 0px) {
  .box {
    width: 90%;
    padding: 10px 5% 10px;
  }
}

/* 左側 */

.box_logo {
  display: flex;
  width: 300px;
}

.box_logo > img {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin: 15px 15px 15px 0;
  color: #192651;
}

.box_logo > h4 {
  font-size: 16px;
  color: #222222;
  line-height: 50px;
}

@media (max-width: 850px) and (min-width: 0px) {
  .box_logo {
    width: 200px;
  }
}


/* 右側 */

.box_link  {
  display: flex;
  width: 300px;
  text-align: right;
}

@media (max-width: 850px) and (min-width: 0px) {
  .box_link {
    display: none;
  }
}


.box_link > div {
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
  margin-left: auto;
}

.box_link > div > p {
  line-height: 40px;
  font-size: 16px;
  font-weight: bold;
  padding: 5px 0px;
}

.box_link_img {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin: 15px 10px 15px 0;
}

.box > h4 {
  line-height: 50px;
  color: #192651;
}