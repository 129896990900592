.footer {
  height: 30px;
  color: #222222;
  font-weight: bold;
  text-align: center;
  width: 100%;
  padding: 40px 0;
  border-top: 1px solid rgb(240, 240, 240);
}

.footer > a {
  color: #222222;
}
