.box {
  width: 100%;
  padding-bottom: 10px;
  margin: 0px auto 10px;
}




.save {
  border: none;
  width: 150px;
  height: 40px;
  line-height: 40px;
  background-color: #AD0200;
  border-radius: 3px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  margin: 30px calc(50% - 75px) 0;
}



.banner {
  top: 0;
  left: 0;
  position: fixed;
  width: 200px;
  height: 40px;
  margin: 20px calc((100% - 202px) / 2) 0;
  background-color: white;
  border-radius: 3px;
  z-index: 1000000;
  border: 1px solid #AD0200;
  text-align: center;
  line-height: 40px;
  font-size: 12px;
  color: #AD0200;
  font-weight: bold;
}

.works_service_info_status_title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 40px;
  margin-bottom: 10px;
}


