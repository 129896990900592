/* box */

.box {
  padding: 70px calc((100% - 600px) / 2) 0;
  width: 600px;
  min-height: 100vh;
  background-color: #F9FBFF;
}

/* title */

.store_title {
  text-align: center;
  margin: 50px 0 50px;
  color: #222222;
  font-size: 20px;
}

.store_list {
  width: 100%;
  margin-bottom: 20px;
}

.store_list_items {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 30px);
  padding: 20px 20px;
  background-color: white;
  border: 1px solid #EBEBEB;
  border-radius: 5px;
  justify-content: space-between;
  margin-bottom: 30px;
}

.store_list_items > img {
  width: 120px;
  height: 80px;
  object-fit: cover;
  border-radius: 3px;
}

.store_list_items_right {
  width: calc(100% - 150px);
}

.store_list_items_right_top {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.store_list_items_right_top > h4 {
  line-height: 20px;
  padding: 10px 0 15px;
  text-align: left;
  color: #222222;
  font-size: 20px;
}

.store_dots {
  width: 20px;
  height: 20px;
  margin: 10px 10px;
}

.store_list_items_right_bottom {
  display: flex;
}

.store_list_items_right_bottom > div {
  display: flex;
  justify-content: space-evenly;
  border: 1px solid #AD0200;
  margin-right: 15px;
  padding: 0 20px;
  border-radius: 3px;
  box-shadow: 0px 0px 2px #DDDDDD;
}

.store_list_items_right_bottom > div > p {
  line-height: 33px;
  font-size: 12px;
  padding-right: 10px;
  color: #AD0200;
  font-weight: bold;
}

.store_items_icons {
  width: 13px;
  height: 13px;
  margin: 10px 0px;
  color: #AD0200;
}

.store_items_icons_red {
  width: 13px;
  height: 13px;
  margin: 10px 0px;
  color: #222222;
}



/* ボタン */

.button {
  width: 350px;
  display: flex;
  justify-content: space-between;
  margin: 70px auto 0;
  padding: 0 0 100px;
}

.button > button:nth-child(1),
.button > button:nth-child(2) {
  width: 150px;
  height: 45px;
  border-radius: 3px;
  border: 1px solid #EBEBEB;
  background-color: white;
}

.button > button:nth-child(3) {
  border: none;
  background-color: #AD0200;
  width: 150px;
  height: 45px;
  border-radius: 3px;
  color: white;
  font-weight: bold;
}

.store_list_items_right_top_details {
  position: relative;
}

.store_list_items_right_top_details > div {
  position: absolute;
  left: -70px;
  top: 20px;
  width: 100px;
  margin: 5px 10px 5px 0;
  background-color: white;
  text-align: center;
  border: 1px solid #CDCDCD;
  border-radius: 3px;
  z-index: 1000;
}

.store_list_items_right_top_details > div > p {
  border-bottom: .5px solid #EBEBEB;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  color: #222222;
}