
.box {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100000000000;
}

.box_black {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #222222;
  opacity: .3;
}

.box_contents {
  position: absolute;
  width: 400px;
  padding: 20px 25px;
  height: calc(80vh - 40px);
  background-color: white;
  border-radius: 5px;
  margin: 10vh calc((100% - 400px) / 2);
  overflow-y: scroll;
}

.title {
  width: calc(100% - 5px);
  background-color: #FAFAFA;
  border-left: 5px solid #AD0200;
  line-height: 40px;
  font-size: 14px;
  text-indent: 1em;
  margin-bottom: 30px;
  color: #222222;
}


.button {
  display: flex;
  justify-content: space-evenly;
  margin: 25px calc((100% - 240px) / 2) 0;
}

.button > button {
  border: none;
  width: 100px;
  height: 35px;
  line-height: 35px;
  border-radius: 3px;
  border: 1px solid #AD0200;
  margin: 0 10px;
  font-weight: bold;
}



.works_service_info_evaluation {
  width: 100%;
}





.error {
  width: 100%;
  padding: 0px 0px;
  background-color: white;
  margin: 0px 0 30px;
  border-radius: 5px;
  display: flex;
}

.error > h4 {
  font-size: 16px;
  color: #AD0200;
  padding-left: 10px;
  line-height: 40px;
}

.error_icon {
  color: #AD0200;
  width: 20px;
  height: 20px;
  margin: 10px 0;
}



.setting_desc {
  font-size: 14px;
  color: #222222;
  line-height: 30px;
}

.setting_btn {
  border: none;
  width: 150px;
  line-height: 35px;
  background-color: #AD0200;
  color: white;
  font-weight: bold;
  border-radius: 3px;
  margin: 10px 0 20px;
}

.setting_attention {
  font-size: 12px;
  color: #222222;
  line-height: 25px;
}