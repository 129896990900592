.works_landing_flow {
  width: 1000px;
  padding: 100px calc((100% - 1000px) / 2);
  background-color: #F9FBFF;
}

@media (max-width: 1000px) and (min-width: 800px) {
  .works_landing_flow {
    width: 800px;
    padding: 100px calc((100% - 800px) / 2);
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .works_landing_flow {
    width: 600px;
    padding: 100px calc((100% - 600px) / 2);
  }
}

@media (max-width: 600px) and (min-width: 500px) {
  .works_landing_flow {
    width: 500px;
    padding: 100px calc((100% - 500px) / 2);
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .works_landing_flow {
    width: 95%;
    padding: 100px 2.5% 0;
  }
}

.works_landing_flow > p {
  font-size: 16px;
  color: #222222;
  line-height: 30px;
  margin-top: 20px;
}

.works_landing_flow_title {
  width: 100%;
  display: flex;
}

.works_landing_flow_title > div {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #AD0200;
  margin: 10px 0;
}

.works_landing_flow_title > h4 {
  font-size: 30px;
  color: #222222;
  line-height: 30px;
  padding-left: 20px;
}

@media (max-width: 600px) and (min-width: 500px) {
  .works_landing_flow_title > h4 {
    font-size: 26px;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .works_landing_flow_title > h4 {
    font-size: 22px;
  }
}

.works_landing_flow_screen {
  width: 100%;
  height: 600px;
  background-color: white;
  border-radius: 10px;
  margin: 30px 0 0;
  border: 1px solid #EBEBEB;
}

@media (max-width: 600px) and (min-width: 500px) {
  .works_landing_flow_screen {
    height: 400px;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .works_landing_flow_screen {
    height: 300px;
  }
}

.works_landing_flow_recruit_title {
  width: 100%;
  padding: 10px 0 0;
  overflow-x: scroll;
}

.works_landing_flow_recruit_title > div {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

@media (max-width: 500px) and (min-width: 0px) {
  .works_landing_flow_recruit_title > div {
    width: 750px;
  }
}

.works_landing_flow_recruit_title > div > h4 {
  width: 18%;
  line-height: 40px;
  border-radius: 3px;
  border: 1px solid #EBEBEB;
  text-align: center;
  height: 40px;
  color: #EBEBEB;
  font-size: 18px;
}

@media (max-width: 600px) and (min-width: 500px) {
  .works_landing_flow_recruit_title > div > h4 {
    font-size: 14px;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .works_landing_flow_recruit_title > div > h4 {
    width: 130px;
  }
}

.works_landing_flow_screen_screen {
  width: 97%;
  height: 500px;
  background-color: #fafafa;
  margin: 30px 1.5% 0;
}

@media (max-width: 600px) and (min-width: 500px) {
  .works_landing_flow_screen_screen {
    height: 300px;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .works_landing_flow_screen_screen {
    height: 200px;
  }
}

.works_landing_flow_screen_screen > img {
  width: 100%;
  height: 500px;
  object-fit: cover;
}

@media (max-width: 600px) and (min-width: 500px) {
  .works_landing_flow_screen_screen > img {
    height: 300px;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .works_landing_flow_screen_screen > img {
    height: 200px;
  }
}





