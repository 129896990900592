

/* お知らせ */

.home_info {
  width: calc(100% - 30px);
  display: flex;
  background-color: white;
  border-radius: 3px;
  border: 1px solid #EBEBEB;
  padding: 15px 20px;
  margin-bottom: 30px;
}

.home_info > h4 {
  font-size: 14px;
  color: #AD0200;
  line-height: 35px;
  padding-right: 30px;
}

.home_info_icon {
  width: 20px;
  height: 20px;
  padding: 7.5px 10px 7.5px 0;
  color: #222222;
}

.home_info > p {
  font-size: 14px;
  color: #222222;
  line-height: 35px;
}

/* ホーム：下部 */

.home_bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 50px;
}
