.box {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100000000000;
}

.box > div {
  position: relative;
}

.box_black {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #222222;
  opacity: .3;
}

.contents {
  position: absolute;
  width: 350px;
  padding: 20px 25px;
  height: 200px;
  background-color: white;
  border-radius: 5px;
  margin: calc((100vh - 240px) / 2) calc((100vw - 350px) / 2);
}




.title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.title > h4 {
  width: 60%;
  font-size: 14px;
  line-height: 45px;
  font-weight: bold;
}

.title > button {
  border: none;
  width: 80px;
  line-height: 35px;
  margin: 5px 0;
  background-color: #AD0200;
  color: white;
  font-size: 14px;
  border-radius: 3px;
}
