.background {
  width: 450px;
  min-height: calc(100vh - 200px);
  background-color: #F9FBFF;
  padding: 100px calc((100vw - 450px) / 2);
}

@media (max-width: 500px) and (min-width: 0px) {
  .background {
    width: 90%;
    background-color: white;
    padding: 100px 5%;
  }
}

.contents {
  padding: 30px 50px;
  width: 350px;
  margin: 0 auto;
  text-align: center;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #EBEBEB;
}

@media (max-width: 500px) and (min-width: 0px) {
  .contents {
    width: 100%;
    padding: 0 0;
    border: none;
  }
}

.contents_icon {
  display: flex;
  justify-content: space-between;
  width: 180px;
  margin: 0px auto 30px;
}

.contents_icon > img {
  width: 30px;
  height: 30px;
  object-fit: contain;
  margin-right: 10px;
}

.contents_icon > h1 {
  font-size: 16px;
  color: #222222;
  line-height: 30px;
}

.contents_title {
  font-size: 22px;
  color: #222222;
  margin: 0 0 15px;
  color: #AD0200;
}

.contents_btn {
  border: none;
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-indent: 1em;
  margin: 20px 0 10px;
  border-radius: 3px;
  color: white;
  background-color: #AD0200;
  font-weight: bold;
}

.contents_link {
  width: 250px;
  font-size: 13px;
  line-height: 30px;
  border-radius: 3px;
  color: #AD0200;
  font-weight: bold;
  margin: 0 auto;
}

.contents_desc {
  width: 100%;
  font-size: 13px;
  line-height: 30px;
  border-radius: 3px;
  color: #222222;
  margin: 20px auto 0;
  text-align: left;
}

.contents_message {
  font-size: 12px;
  line-height: 20px;
  color: #222222;
  margin-top: 20px;
}

.contents_connect {
  width: 100%;
  margin: 20px 0 0;
}


