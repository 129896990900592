/* 全体 */

.box {
  position: fixed;
  width: 1200px;
  height: 50px;
  display: flex;
  padding: 10px calc((100% - 1200px) / 2 - 35px) 0 calc((100% - 1200px) / 2 + 35px);
  justify-content: space-between;
  border-bottom: .5px solid #EBEBEB;
  background-color: #F9FBFF;
  z-index: 100;
}

@media (max-width: 1400px) and (min-width: 1000px) {
  .box {
    width: 1000px;
    padding: 10px calc((100% - 1000px) / 2 - 35px) 0 calc((100% - 1000px) / 2 + 35px);
  }
}

/* 左側 */

.nav {
  position: relative;
  display: flex;
  width: 400px;
}

.nav > img {
  width: 150px;
  height: 150px;
  background-color: #EBEBEB;
  margin: 0 calc((100% - 151px) / 2) 20px;
  font-size: 55px;
  text-align: center;
  line-height: 100px;
  font-weight: bold;
  color: #222222;
  border: .5px solid #EBEBEB;
  object-fit: cover;
}

.nav > img {
  width: 20px;
  height: 20px;
  object-fit: cover;
  margin: 15px 15px 15px 0;
  color: #192651;
}

.nav > h4 {
  font-size: 16px;
  color: #192651;
  line-height: 40px;
  margin: 5px 0;
}

.nav_icon {
  width: 15px;
  height: 15px;
  margin: 17.5px;
}


/* 右側 */

.link {
  display: flex;
}

.link_box {
  display: flex;
  justify-content: space-between;
}

.link_box > p {
  line-height: 40px;
  font-size: 16px;
  font-weight: bold;
  padding: 5px 30px 5px 10px;
  color: #222222;
}

.link_img {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin: 15px 0;
  color: #222222;
}

.link_notice {
  width: 20px;
  height: 20px;
  margin: 15px 20px 15px 0;
  color: #222222;
}

.works_header_mobile_account_nav > div > p {
  text-align: left;
  width: 100%;
  height: 25px;
  line-height: 25px;
  color: #222222;
  font-size: 12px;
}

.works_header_mobile_account_nav > div:nth-child(1),
.works_header_mobile_account_nav > div:nth-child(2),
.works_header_mobile_account_nav > div:nth-child(3) {
  border-bottom: 1px solid #EBEBEB;
}


.account {
  position: relative;
  width: 40px;
}

.account_info {
  position: absolute;
  display: flex;
  justify-content: space-between;
}

.account_info > img {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  margin: 5px 0;
  object-fit: cover;
}

.account_info > p {
  line-height: 40px;
  font-size: 16px;
  font-weight: bold;
  padding: 0 30px 0 10px;
}

/* ナビゲーションポップ */

.account_nav {
  position: absolute;
  width: 120px;
  top: 60px;
  background-color: white;
  border: 1px solid #EBEBEB;
  left: -80px;
  text-align: center;
  border-radius: 3px;
}

.account_nav > div {
  width: 100px;
  display: flex;
  padding: 5px 10px;
}

.account_icons {
  width: 20px;
  height: 20px;
  margin: 2.5px 10px 2.5px 0;
}


.account_nav > div > p {
  text-align: left;
  width: 100%;
  height: 25px;
  line-height: 25px;
  color: #222222;
  font-size: 12px;
}

.account_nav > div:nth-child(1) {
  border-bottom: 1px solid #EBEBEB;
}
